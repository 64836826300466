import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { __core_private_testing_placeholder__ } from '@angular/core/testing';
import { ToastrService } from 'ngx-toastr';
import { DriverService } from 'src/app/services/driver/driver.service';
import { FleetService } from 'src/app/services/fleet/fleet.service';
declare var $;

@Component({
  selector: 'app-assign-vehicle-modal',
  templateUrl: './assign-vehicle-modal.component.html',
  styleUrls: ['./assign-vehicle-modal.component.css']
})
export class AssignVehicleModalComponent implements OnInit {
  @Input() driver: any;
  @Output() vehicleAssigned = new EventEmitter<string>();
  fleets;
  selectedFleet;

  constructor(
    private driverService: DriverService,
    private fleetService: FleetService,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
    console.log('Driver: ', this.driver);
    if (this.driver.isFleet) {
      this.driverService.getDriverFleet(this.driver._id)
        .subscribe((data) => {
          console.log('Fleets: ', data);
          this.fleets = data;
        });
    } else {
      this.fleetService.getFleets("active")
        .subscribe(data => {
          console.log('Fleets: ', data);
          this.fleets = data;
        });
    }
  }

  assignVehicle(fleet) {
    fleet.fleetId = fleet._id;
    fleet.isFleet = true;
    delete fleet._id;
    delete fleet.__v;

    this.driverService.assignFleet(fleet, this.driver._id)
      .subscribe(data => {
        console.log('Assign Vehicle Response ', data);
        this.toastr.success('Vehicle Assigned Successfully','Alert');
        this.modalClose();
      });
  }

  unassignVehicle(fleet) {
    this.driverService.unassignFleet(this.driver._id, fleet._id)
      .subscribe(data => {
        console.log('Unassign Vehicle Response ', data);
        this.toastr.success('Vehicle Unassigned Successfully','Alert');
        this.modalClose();
      });
  }

  modalClose() {
    $("#assignVehicleModal .close").click();
    this.vehicleAssigned.emit(null);
  }

}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable } from 'rxjs';
import { urls } from "src/app/utils/url-utils";

@Injectable({
  providedIn: 'root'
})
export class OfferedFaresService {
  url = urls.BASE_URL;

  constructor(private http: HttpClient) { }

  getSignupRequestFareList(signuprequestId): Observable<any> {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("token")
    });
    let options = { headers: authHeader };
    return this.http.get<any>(this.url + "signuprequests/" + signuprequestId, options);
  }

  getOfferedFaresList(signuprequestId): Observable<any> {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("token")
    });
    let options = { headers: authHeader };
    return this.http.get<any>(this.url + "offeredfares?company=" + signuprequestId, options);
  }

  getOfferedFareByID(fareId): Observable<any> {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("token")
    });
    let options = { headers: authHeader };
    return this.http.get<any>(this.url + "offeredfares/" + fareId, options);
  }

  editOfferedFareByID(fareId, fare) {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("token")
    });
    let options = { headers: authHeader };
    return this.http.put(this.url + "offeredfares/" + fareId, fare, options);
  }

  createOfferedFare(fare) {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("token")
    });
    let options = { headers: authHeader };
    return this.http.post(this.url + 'offeredfares', fare, options);
  }
}

import { Component, OnInit, Renderer2 } from '@angular/core';
import { AuthService } from './services/auth/auth.service';
import { SharedDataService } from './services/shared/shared-data.service';
import { NavigationStart, Router } from '@angular/router';
import { MarketplaceNotificationsService } from './services/marketplaceNotifications/marketplace-notifications.service';
import { SocketService } from './services/socket/socket.service';
import { urls } from "src/app/utils/url-utils";

declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'Banking panel - NEMT';
  isLoggedIn = false;
  user: any;
  url = urls.BASE_URL;

  loaderFilter = [
    this.url + 'signuprequests/dashboard',
    this.url + 'facilitytrips/dashboard',
    this.url + 'assigns/insurancepanelstats',
    this.url + 'notifications',
    this.url + 'assigns/operations'
  ]

  constructor(private auth: AuthService,
    private renderer: Renderer2,
    private sharedData: SharedDataService,
    private router: Router) {

    this.sharedData.getLoginStatus().subscribe(status => {
      this.isLoggedIn = status;
     });

    if (!localStorage.getItem('token')) {
      this.sharedData.updateLoginStatus(false);
      this.router.navigate(['/login']);
    } else {
      this.sharedData.updateLoginStatus(true);
      this.auth.me();
    }
    this.routerListener();
  }

  ngOnInit() {
    document.body.style.zoom = '90%';

  }

  // addJsToElement(src: string): HTMLScriptElement {
  //   const script = document.createElement("script");
  //   script.type = "text/javascript";
  //   script.src = src;
  //   this.renderer.appendChild(document.body, script);
  //   return script;
  // }


  routerListener() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        if (event.url === '/login' || event.url === '/reset/password') {
          if (localStorage.getItem('token') && this.isLoggedIn) {
            console.log(this.router.url);
            this.router.navigateByUrl('/');
            this.router.navigated = false;
          }
        }
      }
    });
  }

}

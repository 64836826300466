import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
declare var $;

@Component({
  selector: 'app-cancel-trip-modal',
  templateUrl: './cancel-trip-modal.component.html',
  styleUrls: ['./cancel-trip-modal.component.css']
})
export class CancelTripModalComponent implements OnInit {
  @Output() tripCancelReason = new EventEmitter<string>();
  reason = '';
  submitted = false;

  constructor(private fb: FormBuilder) { }

  ngOnInit() {
  }

  submitCancelForm() {
    this.submitted = true;
    if(!this.reason) {
      return;
    }
    this.tripCancelReason.emit(this.reason);
    this.modalClose();
  }
  modalClose() {
    $("#cancelReasonModal .close").click();
  }
}

import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
// import { ResponseContentType } from "@angular/http";
import * as moment from "moment-timezone";
import { Observable } from "rxjs";
import { urls } from "src/app/utils/url-utils";

@Injectable({
  providedIn: 'root'
})
export class MarketplaceServiceService {
  url = urls.BASE_URL;
  scheduleTime: any;
  constructor(private http: HttpClient) {
  }
  postScrap(data, companyType, parserType) {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("token")
    });
    let options = { headers: authHeader };
    const formData = new FormData();
    formData.append('uploadExcel', data);
    formData.append('companyType', companyType);
    if (parserType === 'marketplace') {
      return this.http.post(this.url + 'scrap/marketplaces', formData, options);
    } else {
      return this.http.post(this.url + 'scrap/marketplaceOrignal', formData, options);
    }

  }
  getMarketPlaceHistory(id) {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("token")
    });
    let options = { headers: authHeader };
    return this.http.get(this.url + 'marketplacehistory/' + id, options);
  }
  getMarketPlaceAssigned() {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("token")
    });
    let options = { headers: authHeader };
    return this.http.get(this.url + 'assign/marketplaces', options);

  }
  getMarketPlace(isMarketPlaceViewer, manifesttype?: string, scheduleTime?: string) {
    let params = { isMarketPlaceViewer: isMarketPlaceViewer, manifesttype: manifesttype, scheduleTime: scheduleTime };

    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("token")
    });
    let options = { headers: authHeader, params: params };
    return this.http.get(this.url + 'unassigned/marketplaces', options);

  }
  getMarketPlaceStats(startTime = '', endTime = '') {
    console.log(startTime, endTime);
    let range = {
      startTime: startTime,
      endTime: endTime
    };
    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("token")
    })
    let options = {
      headers: authHeader,
      params: range
    };
    return this.http.get(this.url + "marketplace/statistics", options);
  }
  deleteMarketPlace(id) {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("token")
    })
    let options = { headers: authHeader };
    return this.http.get(this.url + "removemarketplace/" + id, options);
  }
  deleteAllMarketPlace(selectedtrips) {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("token")
    })
    let options = { headers: authHeader, selectedtrips: selectedtrips };
    return this.http.post(this.url + "unassigned/marketplaces", options);
  }
  calculateEstimatedFare(id, company) {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("token")
    })
    let params = {
      company: company
    }
    let options = { headers: authHeader, params: params };
    return this.http.get(this.url + "assigns/marketplacetempbrokerfare/" + id, options);
  }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CompanyService } from 'src/app/services/company/company.service';
import { FleetService } from 'src/app/services/fleet/fleet.service';
import { downloadBase64File } from 'src/app/utils/pdf-downloader';

@Component({
  selector: 'app-fleets',
  templateUrl: './fleets.component.html',
  styleUrls: ['./fleets.component.css']
})
export class FleetsComponent implements OnInit {
  searchFleet = '';
  fleets;
  companies;
  companyId;

  constructor(
    private companyService: CompanyService,
    private fleetService: FleetService,
    private router: Router
  ) { }

  ngOnInit() {
    this.companyService.getAllCompanies()
      .subscribe((data) => {
        this.companies = data;
      });
  }

  onCompanyChange(evt) {
    this.companyId = evt.target.value;
    if(this.companyId) {
      this.getCompanyFleets("active");
    }
  }

  getCompanyFleets(status) {
    this.fleetService.getCompanyFleets(status)
      .subscribe((data: any) => {
        console.log('Fleets: ', data)
        this.fleets = data;
      });
  }

  downloadQR(fleetId) {
    this.fleetService.downloadQR(fleetId)
      .subscribe((data: any) => {
        console.log(data);
        downloadBase64File(data.content, data.pdfName);
    });
  }

  downloadURI(name, data) {
    var binaryString = window.atob(data);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
      var ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    var blob = new Blob([bytes], { type: 'application/pdf' });
    var link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = name;
    link.click();
  }

}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { urls } from "src/app/utils/url-utils";

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  url = urls.BASE_URL;
  pdfUrl = urls.PDF_REPORT_URL;
  constructor(private http: HttpClient) { }

  getFacilityReport(data) {
    if (!data.providerStatus) {
      delete data.providerStatus
    }
    if (!data.endDate || !data.startDate) {
      delete data.endDate,
      delete data.startDate
    }
    delete data.dateRange;
    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("token")
    });
    let options = { headers: authHeader };
    return this.http.post(this.url + "facilitytrips/generatereport", data, options);
  }


  downloadFacilityReport(data, facility, startDate, endDate, jobFee, reportType) {
    return this.http.post(this.pdfUrl + "report/facilities", {reportType, facility,reportData: data, startDate, endDate, jobFee});
  }

  downloadMemberReport(data, facility, startDate, endDate, jobFee, reportType, totalJobs, completedJobs, cancelledJobs) {
    console.log(totalJobs)
    console.log(completedJobs)
    console.log(cancelledJobs)
    return this.http.post(this.pdfUrl + "report/member", {reportType, facility,reportData: data, startDate, endDate, jobFee, totalJobs, completedJobs, cancelledJobs});
  }
}

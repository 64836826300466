import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { urls } from 'src/app/utils/url-utils';

@Injectable({
  providedIn: 'root'
})
export class AlarmingTripsService {
  url = urls.BASE_URL;
  
  constructor(private http: HttpClient) { }

  getAlarmingTrips(companyIds) {
    let options = {};
    
    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("token")
    });
    
    let searchParam = { companyIds: companyIds }

    if (companyIds) {
      options = { 
        headers: authHeader,
        params: searchParam  
      };
    } else {
      options = { 
        headers: authHeader 
      };
    }

    return this.http.get(this.url + urls.GET_ALARMING_TRIPS, options);
  }
}

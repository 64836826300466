import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import * as moment from "moment-timezone";
import { urls } from "src/app/utils/url-utils";
import { catchError } from "rxjs/operators";
import { throwError } from "rxjs/internal/observable/throwError";
import { errorHandl } from "src/app/utils/network-utils";

@Injectable({
  providedIn: "root",
})
export class CompanyService {
  url = urls.BASE_URL;
  constructor(private http: HttpClient) { }
  getAllCompanies(accountStatus = "active", isRemoveIAAL = false) {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("token"),
    });
    let options = { headers: authHeader };
    // console.log(search)
    return this.http.get(
      this.url + `companies?accountStatus=${accountStatus}&isRemoveIAAL=${isRemoveIAAL}`,
      options
    );
  }
  getCompanyToEdit(companyId) {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("token"),
    });
    let options = { headers: authHeader };
    return this.http.get(this.url + "companies/" + companyId, options);
  }
  createNewCompany(company, file) {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("token"),
    });
    let options = { headers: authHeader };
    console.log(company, file);
    delete company.cpassword;
    const formData: FormData = new FormData();
    formData.append("newProfilePicture", file, file.name);

    // formData.append('company', JSON.parse(JSON.stringify(company)));
    formData.append("displayName", company.displayName);
    formData.append("email", company.email);
    formData.append("password", company.password);
    formData.append("latitude", company.latitude);
    formData.append("longitude", company.longitude);
    formData.append("address", company.address);
    formData.append("contactNumber", company.contactNumber);
    formData.append("timeZone", company.timeZone);
    formData.append("platform", company.platform);
    formData.append("accountStatus", company.accountStatus);

    formData.append("isTime", company.isTime);
    formData.append("timeinAt", company.timeinAt);
    formData.append("isCamera", company.isCamera);
    formData.append("isAttendee", company.isAttendee);
    formData.append("isTripAssistance", company.isTripAssistance);
    formData.append("isNearBy", company.isNearBy);
    formData.append("isSms", company.isSms);
    formData.append("isAlcTrip", company.isAlcTrip);
    formData.append("forcedAcceptTrips", company.forcedAcceptTrips);
    formData.append("iaal", company.iaal);
    formData.append("isMedicalType", company.isMedicalType);
    formData.append("stateCode", company.stateCode);
    formData.append("reviewURL", company.reviewURL);

    formData.append("calculateLocalMiles", company.calculateLocalMiles);
    formData.append("isRoute", company.isRoute);
    formData.append("isShowDeadMiles", company.isShowDeadMiles);
    formData.append("skipSignature", company.skipSignature);
    formData.append("extension", company.extension);
    formData.append("isListView", company.isListView);
    formData.append("isCheckListOdometer", company.isCheckListOdometer);
    formData.append("npi", company.npi);
    formData.append("taxId", company.taxId);
    formData.append("showCTCTrips", company.showCTCTrips);
    formData.append("isShowCMS1500", company.isShowCMS1500);
    formData.append("faxNumber", company.faxNumber);
    formData.append("focalPerson[displayName]", company.focalPerson.displayName);
    formData.append("focalPerson[contactNumber]", company.focalPerson.contactNumber);
    formData.append("applyCaTimeSheet", company.applyCaTimeSheet);
    formData.append("takeTripEndImage", company.takeTripEndImage);
    formData.append("note", company.note);
    formData.append("onboardStatus", company.onboardStatus);
    if (company.trialExpireDate) {
      formData.append("trialExpireDate", company.trialExpireDate);
    }
    formData.append("dateFormat", company.dateFormat);
    formData.append("timeFormat", company.timeFormat);
    formData.append("caTimeCaution", company.caTimeCaution);

    for (let item of company.companies) {
      formData.append("companies", item);
    }

    return this.http
      .post(this.url + "companies", formData, options)
      .pipe(catchError(errorHandl));
  }
  editCompany(companyId, company, file) {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("token"),
    });
    let options = { headers: authHeader };
    console.log(company, file);
    delete company.cpassword;
    const formData: FormData = new FormData();
    if (file) {
      formData.append("newProfilePicture", file, file.name);
    }
    // formData.append('company', JSON.parse(JSON.stringify(company)));
    formData.append("displayName", company.displayName);
    formData.append("email", company.email);
    //formData.append('password', company.password);
    if (company.password) {
      formData.append("password", company.password);
    }
    formData.append("address", company.address);
    formData.append("latitude", company.latitude);
    formData.append("longitude", company.longitude);
    formData.append("contactNumber", company.contactNumber);
    formData.append("timeZone", company.timeZone);
    formData.append("accountStatus", company.accountStatus);

    formData.append("isTime", company.isTime);
    formData.append("timeinAt", company.timeinAt);
    formData.append("isCamera", company.isCamera);
    formData.append("isAttendee", company.isAttendee);
    formData.append("isTripAssistance", company.isTripAssistance);
    formData.append("isNearBy", company.isNearBy);
    formData.append("isSms", company.isSms);
    formData.append("isAlcTrip", company.isAlcTrip);
    formData.append("forcedAcceptTrips", company.forcedAcceptTrips);
    formData.append("iaal", company.iaal);
    formData.append("isMedicalType", company.isMedicalType);
    formData.append("stateCode", company.stateCode);
    formData.append("reviewURL", company.reviewURL);

    formData.append("calculateLocalMiles", company.calculateLocalMiles);
    formData.append("isRoute", company.isRoute);
    formData.append("isShowDeadMiles", company.isShowDeadMiles);
    formData.append("skipSignature", company.skipSignature);
    formData.append("extension", company.extension);
    formData.append("isListView", company.isListView);
    formData.append("isCheckListOdometer", company.isCheckListOdometer);
    formData.append("npi", company.npi);
    formData.append("taxId", company.taxId);
    formData.append("showCTCTrips", company.showCTCTrips);
    formData.append("isShowCMS1500", company.isShowCMS1500);
    formData.append("isCorporateOdometer", company.isCorporateOdometer);
    formData.append("faxNumber", company.faxNumber);
    formData.append("focalPerson[displayName]", company.focalPerson.displayName);
    formData.append("focalPerson[contactNumber]", company.focalPerson.contactNumber);
    formData.append("applyCaTimeSheet", company.applyCaTimeSheet);
    formData.append("takeTripEndImage", company.takeTripEndImage);
    formData.append("note", company.note);
    formData.append("onboardStatus", company.onboardStatus);
    if (company.trialExpireDate) {
      formData.append("trialExpireDate", company.trialExpireDate);
    }
    formData.append("dateFormat", company.dateFormat);
    formData.append("timeFormat", company.timeFormat);
    formData.append("caTimeCaution", company.caTimeCaution);

    for (let item of company.companies) {
      formData.append("companies", item);
    }

    return this.http
      .put(this.url + "companies/" + companyId, formData, options)
      .pipe(catchError(errorHandl));
  }
  getGoogleTimeZone(params) {
    let options = { params: params };
    // console.log(search)
    return this.http.get(
      "https://maps.googleapis.com/maps/api/timezone/json",
      options
    );
  }

  getFetchETA(companyId) {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("token"),
    });
    let options = { headers: authHeader };
    return this.http.get(this.url + "assigns/singletrip/" + companyId, options);
  }

  getCompanyToken(companyId) {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("token"),
    });
    let options = { headers: authHeader };
    return this.http.get(this.url + "getCompanyToken/" + companyId, options);
  }
}

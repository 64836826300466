import { Injectable } from "@angular/core";
import { MainMenuModel } from "./menu-model";
import { SubMenuModel } from "./menu-model";

@Injectable({
  providedIn: "root",
})
export class DynamicMenuService {
  menu = {};
  roles = "*";
  platform = "*";
  company = "company";

  constructor() {}

  addMenuItem(menuObj: MainMenuModel): void {
    if ((menuObj.platform.includes(this.platform) &&  menuObj.roles.includes(this.roles)) || menuObj.roles.includes("*")) {
      menuObj.subMenu = [];
      this.menu[menuObj.state] = menuObj;
    }
  }

  addSubMenuItem(state: string, subMenuObj: SubMenuModel) {
    if (
      (this.menu[state] && subMenuObj.roles.includes(this.roles)) ||
      subMenuObj.roles.includes("*")
    ) {
      this.menu[state].subMenu.push(subMenuObj);
    }
  }

  getMenu(platform,role): object {
    this.menu = {};
    // console.log(role);
    this.generateMenu(role, platform);
    return this.menu;
  }

  generateMenu(role, platform) {
    this.roles = role;
    this.platform = platform;

    // <<<<< MainMenus >>>>>

    // Dashboard
    this.addMenuItem({
      url: "/",
      class: "fa fa-pie-chart",
      position: 1,
      title: "Dashboard",
      state: "dashboard",
      roles: ["admin", "authorizer", "dispatcher"],
      platform: ["medex", "nemt"],
      subMenu: [],
    });

    // facility-trips
    this.addMenuItem({
      url: "/facility-trips/dashboard",
      class: "fa fa-list-ul",
      position: 2,
      title: "Trips Distribution",
      // title: "Facility Trips",
      state: "facilitytrips",
      roles: ["admin", "authorizer", "dispatcher"],
      platform: ["medex"],
      subMenu: [],
    });

    // // // provider
    // this.addMenuItem({
    //   url: "/provider/dashboard",
    //   class: "fa fa-th-list",
    //   position: 2.1,
    //   title: "Provider",
    //   state: "provider",
    //   roles: ["admin", "authorizer", "dispatcher"],
    //   subMenu: [],
    // });

    // facilities
    this.addMenuItem({
      url: "/facilities",
      class: "fa fa-hospital-o",
      position: 3,
      title: "Enterprises",
      // title: "Facilities",
      state: "facilities",
      roles: ["admin","authorizer"],
      platform: ["medex"],
      subMenu: [],
    });

    // this.addSubMenuItem("facilities", {
    //   title: "Members Signups",
    //   url: "enterprises/members-signups",
    //   roles: ["admin","authorizer"],
    // });

    // this.addSubMenuItem("facilities", {
    //   title: "Members Database",
    //   url: "enterprises/members-database",
    //   roles: ["admin","authorizer"],
    // });

    // signupRequets
    // this.addMenuItem({
    //   url: '/SignupRequets/pending',
    //   class: 'fa fa-user-plus',
    //   position: 4,
    //   title: 'Signup Requests',
    //   state: 'signupRequets',
    //   roles: ['admin'],
    //   subMenu: [],
    // });
    this.addMenuItem({
      url: "/signup-requests/",
      class: "fa fa-user-plus",
      position: 4,
      title: "Signup Requests",
      state: "signupRequets",
      roles: ["admin","authorizer"],
      platform: ["medex", "nemt"],
      subMenu: [],
    });

    // onboarding
    this.addMenuItem({
      url: "/credentials",
      class: "fa fa-list-alt",
      position: 4.5,
      title: "Credentials",
      state: "credentials",
      roles: ["admin","authorizer"],
      platform: ["medex", "nemt"],
      subMenu: [],
    });

    // companies
    this.addMenuItem({
      url: "/companies",
      class: "fa fa-sitemap",
      position: 5,
      title: "Companies",
      state: "companies",
      roles: ["admin","authorizer"],
      platform: ["medex", "nemt"],
      subMenu: [],
    });

    this.addMenuItem({
      url: "/threshold",
      class: "fa fa-tachometer",
      position: 5.01,
      title: "Threshold",
      state: "threshold",
      roles: ["admin","authorizer"],
      platform: ["medex"],
      subMenu: [],
    });

    // this.addMenuItem({
    //   url: "/standing-order",
    //   class: "fa fa-tachometer",
    //   position: 5.02,
    //   title: "Standing Order",
    //   state: "StandingOrder",
    //   roles: ["admin","authorizer"],
    //   subMenu: [],
    // });

    // staff
    this.addMenuItem({
      url: "/staff",
      class: "fa fa-users",
      position: 5.1,
      title: "Staff",
      state: "staff",
      roles: ["admin","authorizer"],
      platform: ["medex", "nemt"],
      subMenu: [],
    });

    // staff
    this.addSubMenuItem("staff", {
      title: "List",
      url: "/staff",
      roles: ["admin","authorizer"],
    });

    this.addSubMenuItem("staff", {
      title: "Add",
      url: "/staff/add",
      roles: ["admin","authorizer"],
    });

    // reports
    this.addMenuItem({
      url: "/reports/facility",
      class: "fa fa-files-o",
      position: 6,
      // title: "Facility Reports",
      title: "Reports",
      state: "reports",
      roles: ["admin","authorizer"],
      platform: ["medex"],
      subMenu: [],
    });

    // map-view
    this.addMenuItem({
      url: "/map-view",
      class: "fa fa-map",
      position: 7,
      title: "Map View",
      state: "map-view",
      roles: ["admin", "authorizer", "dispatcher"],
      platform: ["medex", "nemt"],
      subMenu: [],
    });

    // statistics
    this.addMenuItem({
      url: "/statistics",
      class: "fa fa-area-chart",
      position: 8,
      title: "Statistics",
      state: "statistics",
      roles: ["admin","authorizer"],
      platform: ["medex", "nemt"],
      subMenu: [],
    });
    // NEMTStatistics
    this.addMenuItem({
      url: "/nemt-statistics",
      class: "fa fa-area-chart",
      position: 9,
      title: "NEMT Statistics",
      state: "nemt-statistics",
      roles: ["admin","authorizer","dispatcher"],
      platform: ["medex", "nemt"],
      subMenu: [],
    });

    // Operations
    this.addMenuItem({
      url: "/operations",
      class: "fa fa-tasks",
      position: 2.1,
      title: "Operations",
      state: "operations",
      roles: ["admin","authorizer","dispatcher"],
      platform: ["medex", "nemt"],
      subMenu: [],
    });

    // Alarming
    this.addMenuItem({
      url: "/alarming-trips",
      class: "fa fa-tasks",
      position: 2.12,
      title: "Alarming",
      state: "alarming",
      roles: ["admin","authorizer","dispatcher"],
      platform: ["medex", "nemt"],
      subMenu: [],
    });

    // Trip Purpose
    this.addMenuItem({
      url: "/nemt-codes",
      class: "fa fa-area-chart",
      position: 10,
      title: "Nemt Codes",
      state: "nemt-codes",
      roles: ["admin","authorizer","dispatcher"],
      platform: ["medex", "nemt"],
      subMenu: [],
    });

    // Contractors
    // this.addMenuItem({
    //   url: '/contractors',
    //   class: 'fa fa-cog',
    //   position: 10,
    //   title: 'Ind. Contractors',
    //   state: 'contractors',
    //   roles: ['admin'],
    //   subMenu: [],
    // });

    // Invites
    // this.addMenuItem({
    //   url: '/invites',
    //   class: 'fa fa-envelope',
    //   position: 9,
    //   title: 'Invites',
    //   state: 'invites',
    //   roles: ['admin'],
    //   subMenu: [],
    // });

    // <<<<< SubMenus >>>>>

    // facilitytrips
    this.addSubMenuItem("facilitytrips", {
      title: "Dashboard",
      url: "/facility-trips/dashboard",
      roles: ["admin", "authorizer", "dispatcher"],
    });

    this.addSubMenuItem("facilitytrips", {
      title: "Authorization",
      url: "/facility-trips/authorization",
      roles: ["admin", "authorizer"],
    });

    this.addSubMenuItem("facilitytrips", {
      title: "Outstanding",
      url: "/facility-trips/outstanding",
      roles: ["admin", "authorizer", "dispatcher"],
    });

    this.addSubMenuItem("facilitytrips", {
      title: "Offered",
      url: "/facility-trips/offered",
      roles: ["admin", "authorizer", "dispatcher"],
    });

    this.addSubMenuItem("facilitytrips", {
      title: "Accepted",
      url: "/facility-trips/accepted",
      roles: ["admin", "authorizer", "dispatcher"],
    });

    this.addSubMenuItem("facilitytrips", {
      title: "Rejected",
      url: "/facility-trips/rejected",
      roles: ["admin", "authorizer", "dispatcher"],
    });

    this.addSubMenuItem("facilitytrips", {
      title: "In Progress",
      url: "/facility-trips/inprogress",
      roles: ["admin", "authorizer", "dispatcher"],
    });

    this.addSubMenuItem("facilitytrips", {
      title: "Finished",
      url: "/facility-trips/finished",
      roles: ["admin", "authorizer", "dispatcher"],
    });

    this.addSubMenuItem("facilitytrips", {
      title: "Cancelled",
      url: "/facility-trips/cancelled",
      roles: ["admin", "authorizer", "dispatcher"],
    });

    this.addSubMenuItem("facilitytrips", {
      title: "Logs",
      url: "/facility-trips/logs",
      roles: ["admin", "authorizer", "dispatcher"],
    });

    this.addSubMenuItem("facilitytrips", {
      title: "Add",
      url: "/facility-trips/add",
      roles: ["admin", "authorizer", "dispatcher"],
    });

    // provider
    this.addSubMenuItem("provider", {
      title: "Dashboard",
      url: "/provider/dashboard",
      roles: ["admin", "authorizer", "dispatcher"],
    });

    this.addSubMenuItem("provider", {
      title: "Assigned Trips",
      url: "/provider/assigned-trips",
      roles: ["admin", "authorizer", "dispatcher"],
    });

    this.addSubMenuItem("provider", {
      title: "Hanging Trips",
      url: "/provider/offered-trips",
      roles: ["admin", "authorizer", "dispatcher"],
    });

    this.addSubMenuItem("provider", {
      title: "Drivers",
      url: "/provider/drivers",
      roles: ["admin", "authorizer", "dispatcher"],
    });

    this.addSubMenuItem("provider", {
      title: "Fleets",
      url: "/provider/fleets",
      roles: ["admin", "authorizer", "dispatcher"],
    });

    this.addSubMenuItem("provider", {
      title: "Fares",
      url: "/provider/fares",
      roles: ["admin", "authorizer", "dispatcher"],
    });

    // facilities
    this.addSubMenuItem("facilities", {
      title: "List",
      url: "/facilities",
      roles: ["admin", "authorizer", "dispatcher"],
    });

    this.addSubMenuItem("facilities", {
      title: "Add",
      url: "/facilities/add",
      roles: ["admin", "authorizer", "dispatcher"],
    });

    // signup Requets
    // this.addSubMenuItem('signupRequets', {
    //   title: 'Inprogress',
    //   url: '/SignupRequets/pending',
    //   roles: ['admin', 'dispatcher'],
    // });

    // this.addSubMenuItem('signupRequets', {
    //   title: 'Document Review',
    //   url: '/SignupRequets/active',
    //   roles: ['admin', 'dispatcher'],
    // });

    // this.addSubMenuItem('signupRequets', {
    //   title: 'Approved',
    //   url: '/SignupRequets/approved',
    //   roles: ['admin', 'dispatcher'],
    // });

    // companies
    this.addSubMenuItem("companies", {
      title: "Active",
      url: "/companies/active",
      roles: ["admin","authorizer"],
    });

    this.addSubMenuItem("companies", {
      title: "Inactive",
      url: "/companies/inactive",
      roles: ["admin","authorizer"],
    });

    this.addSubMenuItem("companies", {
      title: "Add",
      url: "/companies/add",
      roles: ["admin","authorizer"],
    });

    // Reports
    this.addSubMenuItem("reports", {
      title: "Enterprises Reports",
      // title: "Facility Reports",
      url: "/reports/facility",
      roles: ["admin", "authorizer", "dispatcher"],
    });

    this.addSubMenuItem("reports", {
      title: "Company Reports",
      url: "/reports/company",
      roles: ["admin", "authorizer", "dispatcher"],
    });

    this.addSubMenuItem("reports", {
      title: "Member Reports",
      url: "/reports/member",
      roles: ["admin", "authorizer", "dispatcher"],
    });
  }
}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { CompanyService } from 'src/app/services/company/company.service';
import { FleetService } from 'src/app/services/fleet/fleet.service';
import { fileProcessing, FileSnippet, imageFileProcessing } from 'src/app/utils/image-processing-utils';
import { appAlert } from 'src/app/utils/utils.common';

@Component({
  selector: 'app-add-fleet',
  templateUrl: './add-fleet.component.html',
  styleUrls: ['./add-fleet.component.css']
})
export class AddFleetComponent implements OnInit {
  fleetForm: FormGroup;
  submitted: boolean = false;
  fleetId: string = '';
  companies;
  companyId = '';
  isEdit = false;

  constructor(
    private fb: FormBuilder,
    private fleetService: FleetService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private companyService: CompanyService
    ) { }

  ngOnInit() {
    this.fleetId = this.activatedRoute.snapshot.paramMap.get('fleetId');

    if (this.fleetId) {
      this.isEdit = true;
      this.getFleetDetails();
    }

    this.companyService.getAllCompanies()
      .subscribe((data) => {
        this.companies = data;
      });

    this.fleetForm = this.fb.group({
      driverCar: ['Standard'],
      driverCarNumber: ['', Validators.required],
      driverCarModel: ['', Validators.required],
      driverCarColor: ['', Validators.required],
      capacity: ['', Validators.required],
      vin: ['', Validators.required],
      setiDecall: ['', Validators.required],
      status: ['', Validators.required],
      realOdometer: ['', Validators.required],
      lcp: ['', Validators.required],
      united: ['', Validators.required],
      logisticscare: ['', Validators.required],
      first: ['', Validators.required],
      ownerName: ['', Validators.required],
      ownerBusiness: ['', Validators.required],
      ownerPhone: ['', Validators.required],
      ownerAddress: ['', Validators.required],
      licenseNo: ['', Validators.required],
      insuranceCompany: ['', Validators.required],
      kindOfInsurance: ['', Validators.required],

      carRegistrationExpiry: ['', Validators.required],
      insuranceCardExpiry: ['', Validators.required],
      stateInspectionCardExpiry: ['', Validators.required],

      driverCarRegistrationImage: ['', Validators.required],
      driverInsuranceCardImage: ['', Validators.required],
      driverStateInspectionImage: ['', Validators.required],
      ownerImage: ['', Validators.required],

      preCarRegistrationExpiry: [''],
      preInsuranceCardExpiry: [''],
      preStateInspectionCardExpiry: ['']
    });
  }

  getFleetDetails() {
    this.fleetService.getFleetById(this.fleetId)
    .subscribe((data) => {
      if (data) {
        this.patchData(data);
      }
    });
  }

  patchData(data) {
    this.fleetForm.patchValue({
      ...data,
      preCarRegistrationExpiry: data.carRegistrationExpiry,
      preInsuranceCardExpiry: data.insuranceCardExpiry,
      preStateInspectionCardExpiry: data.stateInspectionCardExpiry
    })
  }

  onSubmit() {
    this.submitted = true;
    console.log('FORM: ', this.fleetForm);
    if (this.fleetForm.invalid) {
      return;
    }

    appAlert({
      title: "Alert!",
      text: "Are you sure you want to proceed?",
      icon: "warning",
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      showCancelButton: true
    }).then((result) => {
      if (result.isConfirmed) {
        this.fleetService.saveFleet(this.fleetForm.value, this.fleetId)
        .subscribe((data) => {
          console.log('Save Driver: ', data);
          if(this.fleetId) {
            this.toastr.success('Fleet updated successfully.','Alert');
          } else {
            this.toastr.success('Fleet added successfully.','Alert');
          }
          // this.patchData(data);
          this.router.navigate(['/provider/fleets']);
      });
      }
    });

  }

  onCompanyChange(evt) {
    this.companyId = evt.target.value;
  }

  fileProcessing(imageInput, form, imageFile) {
    form[imageFile].setValue(null);
    imageFileProcessing(imageInput).subscribe((result) => {
      form[imageFile].setValue(result);
    });
  }

  onDateChange(event, control) {
    console.log(this.fleetForm.value.preCarRegistrationExpiry);
    console.log(moment(event).toISOString());
    if (event) {
      control.setValue(moment(event).toISOString());
    }
  }

  getVehicleType(type) {
    return type === this.fleetForm.controls.driverCar.value;
  }

  setVehicleType(type) {
    this.fleetForm.patchValue({
      driverCar: type,
    });
  }

  getImageUrl(formControl) {
    // debugger;
    if (typeof formControl.value === "string") {
      return formControl.value
    } else if (typeof formControl.value === "object") {
      if(formControl.value) {
        return formControl.value.src;
      } else {
        return formControl.value;
      }
    }
  }

  show() {
    let show = false;
    
    if(this.isEdit) {
      show = true;
    } else {
      if(this.companyId) {
        show = true;
      }
    }

    return show;
  }

  get form() {
    return this.fleetForm.controls;
  }
}

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DownloadService {

  constructor() { }
  downloadURI(uri, name) {
    var link = document.createElement("a");
    console.log(uri, name);
    link.download = name;
    link.href = uri;
    link.click();
  }
}

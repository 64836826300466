import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import * as moment from "moment-timezone";
import { urls } from "src/app/utils/url-utils";
import { errorHandl } from 'src/app/utils/network-utils';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class StaffService {
  url = urls.BASE_URL;
  constructor(private http: HttpClient) { }

  getAllStaff() {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("token")
    });
    let options = { headers: authHeader };
    // console.log(search)
    return this.http.get(this.url + "quarterpanels", options);
  }

  getStaffToEdit(staffId) {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("token")
    });
    let options = { headers: authHeader };
    return this.http.get(this.url + "quarterpanels/" + staffId, options);
  }

  createNewStaff(staff, file) {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("token")
    });
    let options = { headers: authHeader };
    console.log(staff, file)
    delete staff.cpassword;
    const formData: FormData = new FormData();
    formData.append('newProfilePicture', file, file.name);
    Object.keys(staff).forEach(key =>
      formData.append(`quarterpanel[${key}]`, staff[key])
    );
    return this.http.post(this.url + 'quarterpanels', formData, options)
      .pipe(catchError(errorHandl));
  }

  editStaff(staffId, staff, file) {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("token")
    });
    let options = { headers: authHeader };
    console.log(staff, file)
    delete staff.cpassword;
    const formData: FormData = new FormData();
    if (file) {
      formData.append('newProfilePicture', file, file.name);
    }
    Object.keys(staff).forEach(key =>
      formData.append(`quarterpanel[${key}]`, staff[key])
    );
    return this.http.put(this.url + 'quarterpanels/' + staffId, formData, options)
      .pipe(catchError(errorHandl));
  }

  getGoogleTimeZone(params) {
    let options = { params: params };
    // console.log(search)
    return this.http.get('https://maps.googleapis.com/maps/api/timezone/json', options);
  }

}

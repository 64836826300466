import { Component, OnInit } from '@angular/core';
import { MarketplaceServiceService } from 'src/app/services/marketplace/marketplace-service.service';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { AssignService } from 'src/app/services/assign/assign.service';
import { INgxSelectOption } from 'ngx-select-ex';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import * as moment from "moment-timezone";
declare var $: any;
@Component({
  selector: 'app-marketplace-unassigned',
  templateUrl: './marketplace-unassigned.component.html',
  styleUrls: ['./marketplace-unassigned.component.css']
})
export class MarketplaceUnassignedComponent implements OnInit {
  selectedTrips = [];
  trips: any;
  companies: any;
  selectedCompany: any;
  index = -1;
  selectedCompanyTitle = '';
  assignTrips: any;
  tripFilter: any;
  filterMile = 0;
  toAssign: any;
  specialRate: any;
  companyNote: any;
  companyFilter: any;
  tripForm: FormGroup;
  smsForm: FormGroup;
  constructor(
    private formBuilder: FormBuilder,
    private marketPlaceService: MarketplaceServiceService,
    public ngxSmartModalService: NgxSmartModalService,
    private router: Router,
    private assignService: AssignService
  ) { }
  assignId: any;
  ngOnInit() {
    this.tripForm = this.formBuilder.group({
      companyNote: ['', Validators.required]
    });
    this.smsForm = this.formBuilder.group({
      companyNote: ['', Validators.required],
      contactNumber: ['', Validators.required]
    });
    this.getTrips(false);
  }
  fileProgress(fileInput: any) {
    let fileData = <File>fileInput.target.files[0];
    this.marketPlaceService.postScrap(fileData, 'medtrans', 'marketplace').subscribe(res => {
      console.log(res);
      this.getTrips(false);
      this.router.navigate(['/unassigned/marketplace']);
    })
  }
  getTrips(status) {
    this.marketPlaceService.getMarketPlace(status).subscribe((data: any) => {
      console.log(data);
      this.assignTrips = data;
      this.assignTrips.forEach(element => {
        element.isSelected = false;
      });
      this.trips = this.assignTrips;
      this.selectedTrips = [];
      this.milesFilter();
    });
  }
  deleteMarketPlace(id) {
    this.marketPlaceService.deleteMarketPlace(id).subscribe((data: any) => {
      console.log(data);
      this.getTrips(false);
    });
  }

  selectTrips(assign, index) {
    var assignIndex = this.selectedTrips.indexOf(assign._id);
    console.log('IS Exist assign ::: ', assignIndex);
    console.log('Before  Array ::: ', this.selectedTrips);
    if (assignIndex > -1) {
      assign.isSelected = false;
      this.selectedTrips.splice(assignIndex, 1);
    } else {
      assign.isSelected = true;
      this.selectedTrips.push(assign._id);
    }
    console.log('After  Array ::: ', this.selectedTrips);
  }

  selectAll() {
    console.log('Select Array  ---> ', this.selectedTrips);
    if (this.selectedTrips.length === this.trips.length) {
      this.clearSelection();
    } else {
      this.selectedTrips = [];
      this.trips.forEach(assign => {
        // if (assign.status === 'cancelled' && assign.job && assign.job._id) {
        //   return;
        // }
        // if (assign.issues && assign.issues.length) {
        //   return;
        // }
        assign.isSelected = true;
        this.selectedTrips.push(assign._id);
      });
    }
  }

  clearSelection() {
    this.selectedTrips = [];
    this.trips.forEach(function (assign) {
      assign.isSelected = false;
    });
  }
  deleteAllMarketPlace() {
    this.marketPlaceService.deleteAllMarketPlace(this.selectedTrips).subscribe((data: any) => {
      console.log(data);
      this.getTrips(false);
    });
  }
  // saveCompany(id,rate, companyNote) {
  //   this.assignId = id;
  //   console.log(rate);
  //   console.log(this.assignId, this.selectCompany)
  //   this.assignService.updateMarketPlaceCompany(this.assignId, this.selectedCompany, rate, companyNote)
  //     .subscribe((data: any) => {
  //       $.Notification.autoHideNotify('success', 'bottom left', 'Company Assigned','company assigned successfully. ');
  //       this.assignId = '';
  //       if (!this.trips[this.index]['company']) {
  //         this.trips[this.index]['company'] = {};
  //       }
  //       this.trips.splice(this.index, 1);
  //       this.selectedCompanyTitle = ''
  //       this.selectedCompany = '';
  //       this.companyNote = undefined;
  //       this.specialRate = undefined;
  //       this.index = -1
  //       console.log(data);
  //     });
  //   this.ngxSmartModalService.get('myModal').close();
  // }
  // openModal(index, assign) {
  //   this.index = index;
  //   console.log(this.index, assign);
  //   this.toAssign = assign;
  //   this.assignId = assign._id
  //   this.assignService.nearByCompany(assign.jobOriginLatitude, assign.jobOriginLongitude).subscribe((data: any) => {
  //     if (data.success) {
  //       this.companies = data.companies;
  //       console.log(this.companies);
  //     }
  //   });
  //   this.ngxSmartModalService.getModal('myModal').open()
  // }
  // doSelectionChanges(event: INgxSelectOption) {
  //   if (event[0]) {
  //     this.selectedCompanyTitle = event[0].text;
  //   }
  // }
  // selectCompany(companyId) {
  //   this.selectedCompany = companyId;
  //   console.log(this.selectedCompany)
  // }
  milesFilter() {
    console.log(this.filterMile)
    if (this.filterMile > 0) {
      if (this.filterMile <= 15) {
        this.trips = this.assignTrips.filter(element => {
          return (element.milage < this.filterMile && element.milage >= this.filterMile - 5)
        });
      } else {
        this.trips = this.assignTrips.filter(element => {
          return element.milage >= this.filterMile
        });
      }

    } else {
      this.trips = this.assignTrips;
    }
  }
  get form() { return this.tripForm.controls };
  get smsform() { return this.smsForm.controls };
  openModal(index, toAssign) {
    this.index = index;
    this.toAssign = toAssign;
    // this.companyNote = this.toAssign.companyNote;
    this.tripForm.patchValue({ companyNote: '' });
    let companyNote = `
      ${moment(toAssign.scheduleTime).format('dddd DD MMMM, YYYY')}
      ${toAssign.tripRequirement || 'N/A'}
      ${toAssign.tripId || 'N/A'}
      ${toAssign.priorityClient.displayName || 'N/A'}

      ${toAssign.jobOriginAddress || 'N/A'}
      to 
      ${toAssign.jobDestinationAddress || 'N/A'}

      PU: ${moment(toAssign.scheduleTime).format('HHmm') || 'N/A'}
      APPT: ${moment(toAssign.appointmentTime).format('HHmm') || 'N/A'}
      1-2 hour return

      Rate: $${parseFloat(toAssign.preCalculateRate || 0) * 2}

      Yes or No? Please reply to 5402579606
    `;
    this.smsForm.patchValue({ companyNote: companyNote, contactNumber: '' });
    this.ngxSmartModalService.getModal('myUpdateNoteModal').open();
  }
  onUpdateSubmit() {
    console.log(this.tripForm.value.companyNote, this.toAssign.companyNote);
    let companyNote = `${this.toAssign.companyNote} // ${this.tripForm.value.companyNote}`;
    this.assignService.manifestActionAdd({
      companyNote: companyNote,
      action: 'note',
      tripId: this.toAssign.tripId
    })
      .subscribe(response => {
        console.log('Response of Form submit --- ', response);
        if (response) {
          // this.companyNote = companyNote;
          // this.toAssign.companyNote = companyNote;
          this.getTrips(false);
        }
        this.ngxSmartModalService.getModal('myUpdateNoteModal').close()
      });
  }
  onSMSSubmit() {
    console.log('SMS form ', this.smsForm.value);
    this.assignService.manifestActionAdd({
      companyNote: `${this.smsForm.value.companyNote} -- Sent to +1${this.smsForm.value.contactNumber}`,
      contactNumber: `+1${this.smsForm.value.contactNumber}`,
      action: 'sms',
      tripId: this.toAssign.tripId
    })
      .subscribe(response => {
        console.log('Response of Form submit --- ', response);
        if (response) {
          // this.companyNote = companyNote;
          // this.toAssign.companyNote = companyNote;
          this.getTrips(false);
        }
        this.ngxSmartModalService.getModal('myUpdateNoteModal').close()
      });
  }
}

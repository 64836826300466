import { Component, OnInit } from "@angular/core";
// import { tokenKey } from "@angular/core/src/view";
import { Router } from "@angular/router";
import { SharedDataService } from "src/app/services/shared/shared-data.service";
import { SocketService } from "src/app/services/socket/socket.service";
import { showHeading } from "src/app/utils/global-constants";
import { environment } from "src/environments/environment";
import { FacilityTripsService } from "../../../services/facility-trips/facility-trips.service";

@Component({
  selector: "app-facility-trips-count",
  templateUrl: "./facility-trips-count.component.html",
  styleUrls: ["./facility-trips-count.component.css"],
})
export class FacilityTripsCountComponent implements OnInit {
  trips;
  fileName = "Choose file";
  selectedFile;
  tripType = {
    outstandingTrips: {
      name: "Outstanding",
      link: "/facility-trips/outstanding",
      class: "fa fa-list-ol remaining-icon",
    },
    authorizationTrips: {
      name: "Authorization",
      link: "/facility-trips/authorization",
      class: "fa fa-lock remaining-icon",
    },
    offeredTrips: {
      name: "Offered",
      link: "/facility-trips/offered",
      class: "fa fa-list-ol total-icon",
    },
    acceptedTrips: {
      name: "Accepted",
      link: "/facility-trips/accepted",
      class: "fa fa-check-circle completed-icon",
    },
    rejectedTrips: {
      name: "Rejected",
      link: "/facility-trips/rejected",
      class: "fa fa-times-circle-o cancelled-icon",
    },
    inprogressTrips: {
      name: "In Progress",
      link: "/facility-trips/inprogress",
      class: "fa fa-car inprogress-icon",
    },
    finishedTrips: {
      name: "Finished",
      link: "/facility-trips/finished",
      class: "fa fa-check-circle completed-icon",
    },
    cancelledTrips: {
      name: "Cancelled",
      link: "/facility-trips/cancelled",
      class: "fa fa-times-circle-o cancelled-icon",
    },
  };
  showHeading = showHeading;
  user;

  constructor(
    private facilityTripsService: FacilityTripsService,
    private router: Router,
    private socketService: SocketService,
    private sharedData: SharedDataService
  ) {}

  ngOnInit() {
    this.sharedData.getUser().subscribe((user) => {
      this.user = user;
      // console.log("This current user logged in", this.user);
    });

    this.socketService.getNotification().subscribe((data) => {
      if (data) {
        this.getTripsCount();
      }
    });
    this.getTripsCount();
  }

  getTripsCount() {
    this.facilityTripsService.getTripsCount().subscribe((data) => {
      this.trips = data;
    });
  }

  getTripType(key) {
    return this.tripType[key].name;
  }

  getLink(key) {
    return this.tripType[key].link;
  }
  getTripClass(key) {
    return this.tripType[key].class;
  }

  inputFile(fileInput: any) {
    console.log(fileInput);
    if (fileInput.target.files.length > 0) {
      this.selectedFile = fileInput.target.files[0];
      this.fileName = this.selectedFile.name;
    } else {
      this.selectedFile = null;
      this.fileName = "Choose file";
    }
  }
  uploadFile() {
    console.log(this.selectedFile);
    if (!this.selectedFile) return;
    let token;
    if (this.user.platform === "nemt") {
      token = environment.FACILITY_NEMT_TOKEN;
    } else {
      token = environment.FACILITY_NEMTROUTES_TOKEN;
    }

    this.facilityTripsService
      .parseTrips(this.selectedFile, token)
      .subscribe((res) => {
        console.log(res);
        this.router.navigate(["/facility-trips/outstanding"]);
      });
  }
}

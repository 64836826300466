import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FacilityService } from 'src/app/services/facility/facility.service';
import { ActivatedRoute, Router } from '@angular/router';
import { showHeading } from 'src/app/utils/global-constants';
import { appAlert } from 'src/app/utils/utils.common';

@Component({
  selector: 'app-new-facility',
  templateUrl: './new-facility.component.html',
  styleUrls: ['./new-facility.component.css']
})
export class NewFacilityComponent implements OnInit {

  updateForm: FormGroup;
  submitted = false;
  facilityId: any;
  mismatch: any;
  user: any;
  url: any;
  urlImage: any = '';
  fileToUpload: any;
  timezone: any;
  address: any;
  @ViewChild('password', {static: true}) pass: ElementRef;
  // @ViewChild('cpassword') cpass: ElementRef;
  @ViewChild('fileInput', {static: true}) el: ElementRef;
  @ViewChild('address', {static: true}) inputAddress: any;
  showHeading = showHeading;
  selectedTimeZone = 'America/New_York';
  constructor(private formBuilder: FormBuilder,
    private facilityService: FacilityService,
    private router: Router,
    private activatedRoute: ActivatedRoute) {
    this.facilityId = this.activatedRoute.snapshot.paramMap.get('facilityId');
  }

  ngOnInit() {
    this.updateForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      address: ['', Validators.required],
      contactNumber: ['', Validators.required],
      displayName: ['', Validators.required],
      password: ['', Validators.required],
      latitude: [''],
      longitude: [''],
      // cpassword: [''],
      timeZone: ['', Validators.required],
      accountStatus: ['active', Validators.required],
      profileRole: ['', Validators.required]
    });

    if (this.facilityId) {
      this.facilityService.getFacilityToEdit(this.facilityId).subscribe((data: any) => {
        console.log(data);
        this.updateForm.patchValue(data);
        // this.timezone = data.timeZone;
        // this.updateForm.get('timeZone').setValue(data.timezone);
        this.url = data.profileImageURL;
        this.urlImage = this.url;
        this.fileToUpload = null;
        // this.address = data.address;
        this.inputAddress.addresstext.nativeElement.value = data.address;
        this.selectedTimeZone = data.timeZone;
      });
    }
  }

  get form() {
    return this.updateForm.controls;
  }

  getAddress(place) {

    if (place.formatted_address) {
      this.updateForm.patchValue({
        latitude: place.geometry.location.lat(),
        longitude: place.geometry.location.lng(),
        address: place.formatted_address
      });
    }
  }

  changeTimezone(event: any) {
    console.log('TimeZone Event: ', event);
    this.updateForm.patchValue({ timeZone: event });
    console.log('Patched TimeZone: ', this.updateForm.value.timeZone);
  }

  togglePassword() {
    this.pass.nativeElement.type === 'text' ? this.pass.nativeElement.type = 'password' : this.pass.nativeElement.type = 'text';
  }

  // toggleCPassword() {
  //   this.cpass.nativeElement.type === 'text' ? this.cpass.nativeElement.type = 'password' : this.cpass.nativeElement.type = 'text';
  // }

  onSubmit() {
    console.log(this.updateForm.value);
    this.submitted = true;
    // this.updateForm.value.address = this.address;
    // this.updateForm.patchValue({ address: this.address});
    if (this.updateForm.invalid) {
      console.log(this.updateForm.value)
      console.log(this.fileToUpload)

      return;
    }
    // if (this.updateForm.value.password !== this.updateForm.value.cpassword) {
    //   this.mismatch = true;
    //   return;
    // }

    if (!this.facilityId && !this.fileToUpload) {
      return false;
    }

    if (this.facilityId) {
      this.editCompany(this.facilityId, this.updateForm.value);
    } else {
      this.createCompany(this.updateForm.value);
    }
  }

  fileUpload(event) {
    this.url = this.urlImage;
    this.fileToUpload = this.el.nativeElement.files[0];
    if (this.fileToUpload) {
      if (event.target.files && event.target.files[0]) {
        var reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]); // read file as data url
        reader.onload = (event: any) => { // called once readAsDataURL is completed
          this.url = event.target.result;
        }
      }
    }
  }

  createCompany(facility) {
    this.submitted = false;
    this.facilityService.createNewFacility(facility, this.fileToUpload)
      .subscribe(
        (data: any) => {
          console.log(data);
          if (data) {
            appAlert({
              icon: 'success',
              title: 'Facility Created',
              text: 'Facility Created Successfully'
            });
            this.router.navigate(['/facilities']);
          }
        },
        (error) => {
          appAlert({
            icon: 'error',
            title: 'Error',
            text: error
          });
        });
  }

  editCompany(facilityId, facility) {
    this.submitted = false;
    if (!this.updateForm.value.password) {
      delete this.updateForm.value.password;
    }
    this.facilityService.editCompanyNew(facilityId, facility, this.fileToUpload)
      .subscribe(
        (data: any) => {
          console.log(data);
          this.router.navigate(['/facilities']);
        },
        (error) => {
          appAlert({
            icon: 'error',
            title: 'Error',
            text: error
          });
        });
  }

}

import {Injectable} from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  ActivatedRoute,
  CanActivateChild,
  CanDeactivate, CanLoad, UrlTree, Route, UrlSegment
} from '@angular/router';
import {catchError, map} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import * as moment from 'moment-timezone';
import {AuthService} from '../auth/auth.service';
import {SharedDataService} from '../shared/shared-data.service';
import {DynamicMenuService} from '../../components/partials/sidebar/dynamic-menu-service/dynamic-menu.service';
//
// @Injectable({
//     providedIn: 'root'
//   })
// export class AuthorizationGuard implements CanActivate {
//     constructor(
//         private authService: AuthService,
//         private router: Router,
//         private sharedData: SharedDataService,
//         private activatedRoute: ActivatedRoute,
//         private menuService: DynamicMenuService
//
//     ) {
//     }
//     canActivate(
//         next: ActivatedRouteSnapshot,
//         state: RouterStateSnapshot) {
//         return this.authService.verifyToken()
//             .pipe(
//                 map((data) => {
//                   const menuObject = this.menuService.getMenu('admin');
//                   console.log(state.url);
//                   console.log(Object.entries(menuObject));
//                   if (Object.keys(menuObject).indexOf('dashboard') > -1) {
//                     console.log('has test1');
//                   }
//                     if (data.user != null   ) {
//                         return true;
//                     } else {
//                         this.sharedData.updateLoginStatus(false);
//                         localStorage.removeItem('token');
//                         this.router.navigate(['/login']);
//                         return false;
//                     }
//                 }),
//                 catchError(() => {
//                     return of(false);
//                 })
//             );
//     }
// }
//
//

@Injectable({
  providedIn: 'root'
})
export class AuthorizationGuard implements CanActivate, CanActivateChild, CanDeactivate<unknown>, CanLoad {


  constructor(private authService: AuthService,
              private router: Router,
              private sharedData: SharedDataService,
              private activatedRoute: ActivatedRoute,
              private menuService: DynamicMenuService) {
  }

  // @ts-ignore
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const url: string = state.url;
    return this.checkUserLogin(next, url);
  }

  // @ts-ignore
  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canActivate(next, state);
  }

  // @ts-ignore
  canDeactivate(
    component: unknown,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }

  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return true;
  }

  checkUserLogin(route: ActivatedRouteSnapshot, url: any) {
    // if (this.authService.isLoggedIn()) {
    //   const userRole = this.authService.getRole();
    //   if (route.data.role && route.data.role.indexOf(userRole) === -1) {
    //     this.router.navigate(['/home']);
    //     return false;
    //   }
    //   return true;
    // }
    //
    // this.router.navigate(['/home']);
    // return false;



    return this.authService.verifyToken()
      .pipe(
        map((data) => {
          if (data.user != null && route.data.role && route.data.role.indexOf(data.user.roleType) > -1  ) {
            return true;
          } else if ( data.user != null && data.user.roleType && route.data.role && !(route.data.role.indexOf(data.user.roleType) > -1)) {
            this.router.navigate(['/']);
            return false;
          } else {
            this.sharedData.updateLoginStatus(false);
            localStorage.removeItem('token');
            this.router.navigate(['/login']);
            return false;
          }
        }),
        catchError((error) => {
          this.sharedData.updateLoginStatus(false);
          localStorage.removeItem('token');
          this.router.navigate(['/login']);
          return of(false);
        })
      );

  }
}


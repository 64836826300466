import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CompanyService } from '../../../services/company/company.service';
import { ReportsService } from '../../../services/reports/reports.service';
import { SharedDataService } from 'src/app/services/shared/shared-data.service';
import * as moment from "moment-timezone";
import { showHeading } from 'src/app/utils/global-constants';
@Component({
  selector: 'app-company-reports',
  templateUrl: './company-reports.component.html',
  styleUrls: ['./company-reports.component.css']
})
export class CompanyReportsComponent implements OnInit {

  currentUser: any;

  searchForm: FormGroup;
  companies: any = [];
  submitted = false;
  companyReportsData;
  selectedCompany = null;
  startDate = null;
  endDate = null;
  totalJobFee = 0;
  userPlatform: any = 'nemt';
  showHeading = showHeading;
  constructor(private fb: FormBuilder, private companyService: CompanyService,
    private reportsService: ReportsService, private sharedData: SharedDataService) { }

  ngOnInit() {
    this.sharedData.getUser().subscribe(data => {
      this.userPlatform = data.platform;
      this.currentUser = data;
    });

    this.companyService.getAllCompanies()
      .subscribe(data => {
        this.companies = data;
      })

      this.searchForm = this.fb.group({
        user: ['', Validators.required],
        reportType: ['company', Validators.required],
        providerStatus: [''],
        startDate: [null],
        endDate: [null],
        dateRange: ['']
      })
  }

  onDateChange(event) {
    if (event) {
      this.searchForm.patchValue({
        startDate: moment(event[0]).startOf("day").toISOString(),
        endDate: moment(event[1]).endOf("day").toISOString()
      });
    }
  }

  get form() {
    return this.searchForm.controls;
  }

  onSubmit() {
    this.companyReportsData = undefined;
    console.log(this.searchForm)
    this.submitted = true;
    if (this.searchForm.invalid) {
      return
    }

    this.reportsService.getFacilityReport(this.searchForm.value)
      .subscribe(data => {
        console.log(data)
        if (data && data['length'] > 0) {
          this.companyReportsData = data[0].jobs;
          this.companyReportsData.sort((val1, val2)=> {return    moment(val1.scheduleTime).diff(moment(val2.scheduleTime), 'minutes')  })
          this.companies.forEach(element => {
            if (element._id === this.searchForm.value.user) {
              this.selectedCompany = element;
            }
          });
          this.startDate = this.searchForm.value.startDate;
          this.endDate = this.searchForm.value.endDate;
          this.calculateJobFee(this.companyReportsData);
        }
      })
  }

  calculateJobFee(trips) {
    this.totalJobFee = 0;
    trips.forEach(element => {
      this.totalJobFee += element.jobFee;
    });
  }

  downloadFacilityReport() {
    if (this.companyReportsData.length > 0) {
      console.log(this.selectedCompany)
      this.reportsService.downloadFacilityReport(this.companyReportsData, this.selectedCompany, this.startDate, this.endDate, this.totalJobFee, 'Provider Trips Report')
        .subscribe(data => {
          console.log(data);
          // console.log(url, fileName);
          const body = <HTMLDivElement>document.body;
          const linkSource = data['pdfFile'];
          const downloadLink = document.createElement('a');
          downloadLink.href = linkSource;
          downloadLink.target = '_blank';
          downloadLink.download = data['fileName'];
          body.appendChild(downloadLink);
          console.log(downloadLink)
          console.log(data['pdfFile'])
          console.log(data['fileName'])
          console.log(downloadLink.click())
          // downloadLink.click();
          // downloadLink.dispatchEvent(new MouseEvent('click'));
          var event = document.createEvent('Event');
          event.initEvent('click', true, true);
          downloadLink.dispatchEvent(event);
          // (window.URL || window.webkitURL).revokeObjectURL(downloadLink.href);

        })
    }
  }

}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-member-trips',
  templateUrl: './member-trips.component.html',
  styleUrls: ['./member-trips.component.css']
})
export class MemberTripsComponent implements OnInit {
  memberTrips;
  search;

  constructor() { }

  ngOnInit() {
    this.memberTrips = [1,2,3,4,5];
  }

  getMemberTrips() {
  }

}

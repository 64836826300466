import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { errorHandl } from 'src/app/utils/network-utils';
import { urls } from 'src/app/utils/url-utils';

@Injectable({
  providedIn: 'root'
})
export class InviteService {
  url = urls.BASE_URL;
  
  constructor(private http: HttpClient) { }

  sendInvite(email) {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("token")
    });
    let options = { headers: authHeader };
    
    const formData: FormData = new FormData();
    formData.append('email', email);

    return this.http.post(this.url + 'invites', formData, options)
      .pipe(catchError(errorHandl));
  }

  getInviteList(): Observable<any> {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("token")
    });
    let options = { headers: authHeader };
    
    return this.http.get(this.url + "invites", options);
  }
}

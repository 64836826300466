import { Component, OnInit } from '@angular/core';
import { SignupRequestsService } from '../../../services/signup-requests/signup-requests.service';
import { ActivatedRoute } from '@angular/router';
import { SharedDataService } from 'src/app/services/shared/shared-data.service';
import { appAlert, getOnboardUrl } from 'src/app/utils/utils.common';
import { showHeading } from 'src/app/utils/global-constants';

@Component({
  selector: 'app-signup-requests',
  templateUrl: './signup-requests.component.html',
  styleUrls: ['./signup-requests.component.css']
})
export class SignupRequestsComponent implements OnInit {
  signupRequests;
  searchFacility: any;
  showHeading = showHeading;

  constructor(
    private signupRequestsService: SignupRequestsService, 
    private activatedRoute: ActivatedRoute,
    private sharedService: SharedDataService
    ) { }

  ngOnInit() {
    this.sharedService.signupRequestsListener().subscribe(data => {
      if (data) {
        this.getRequests();
      }
    });
  
    this.getRequests();
  }

  getRequests() {
    // this.status = this.activatedRoute.snapshot.url[1].path;
    let obj = {
      accountStatus: 'pending'
    }

    this.signupRequestsService.getAllSignupRequests(obj)
      .subscribe(arg => {
        this.signupRequests = arg;
        console.log(arg);
      });
  }

  approve(signupRequestId) {
    appAlert({
      title: "Alert!",
      text: "Are you sure you want to create the company?",
      icon: "warning",
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      showCancelButton: true
    }).then((result) => {
      if (result.isConfirmed) {
        this.approveRequest(signupRequestId);
      }
    });
    
  }

  approveRequest(signupRequestId) {
    this.signupRequestsService
      .approveSignupRequest(signupRequestId)
      .subscribe((data) => {
        if (data.success) {
          // update sidebar signup request counters
          this.sharedService.addSignupRequests({ success: true });
          appAlert({
            icon: "success",
            title: "Company Created",
            text: "Company Created Successfully",
          });
        }
      });
  }




}

import { Component, OnInit } from "@angular/core";
import { CompanyService } from "src/app/services/company/company.service";
import { OfferedTripsService } from "src/app/services/offered-trips/offered-trips.service";
import { appAlert } from "src/app/utils/utils.common";

@Component({
  selector: "app-offered-trips",
  templateUrl: "./offered-trips.component.html",
  styleUrls: ["./offered-trips.component.css"],
})
export class OfferedTripsComponent implements OnInit {
  offeredTrips = [];
  companies;
  searchFleet = "";
  companyId;

  constructor(
    private offeredTripsService: OfferedTripsService,
    private companyService: CompanyService
    ) {}

  ngOnInit() {
    this.companyService.getAllCompanies()
    .subscribe((data) => {
      this.companies = data;
    });
    this.offeredTripsService.getOfferedTrips().subscribe((data) => {
      this.offeredTrips = data;
    });
  }
  submit(trip, status) {

    appAlert({
      title: "Alert!",
      text: "Are you sure you want to proceed?",
      icon: "warning",
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      showCancelButton: true
    }).then((result) => {
      if (result.isConfirmed) {
        trip.providerStatus = status;
       this.updateTripData(trip);
      }
    });
  }
  updateTripData(trip) {
    this.offeredTripsService.updateTrip(trip).subscribe((data) => {
      console.log(data);
      this.offeredTrips = this.offeredTrips.filter(obj => obj._id !== data._id);
    });
  }
  onCompanyChange(evt) {
    this.companyId = evt.target.value;
    if(this.companyId) {
      // this.getCompanyFleets("active");
    }
  }
}

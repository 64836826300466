import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { urls } from 'src/app/utils/url-utils';

@Injectable({
  providedIn: 'root'
})
export class CredentialsService {
  url = urls.BASE_URL;

  constructor(private http: HttpClient) { }

  getDrivers(companyId) {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("token")
    });
    let params = new HttpParams();
    params = params.append('company', companyId);

    let options = {
      headers: authHeader,
      params: params
    };

    return this.http.get(this.url + "driveralivis", options);
  }

  getDriverById(driverId) {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("token")
    });
    let options = { headers: authHeader };
    return this.http.get(this.url + "driveralivis/" + driverId, options);
  }

  getVehicles(companyId) {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("token")
    });

    let params = new HttpParams();
    params = params.append('company', companyId);

    let options = {
      headers: authHeader,
      params: params
    };

    return this.http.get(this.url + "fleetalivis", options);
  }

  getVehicleById(vehicleId) {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("token")
    });
 
    let options = { headers: authHeader };
    return this.http.get(this.url + "fleetalivis/" + vehicleId, options);
  }

  getAllCompanies() {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("token")
    });
    let options = { headers: authHeader };
    return this.http.get(this.url + "companyalivis", options);

  }
  getCompaniesDetails(id) {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("token")
    });
    let options = { headers: authHeader };
    return this.http.get(this.url + "companyalivis/" + id, options);

  }

  updateCompany(object, companyId) {
    const formData = new FormData();
    for (var key of Object.keys(object)) {
      formData.append(key, object[key]);
    }
    return this.http.put<any>(this.url + 'companyalivis/' + companyId, formData)
  }

  updateDriver(object, driverId) {
    const formData = new FormData();
    for (var key of Object.keys(object)) {
      formData.append(key, object[key]);
      console.log(key, object[key]);
    }
    return this.http.put<any>(this.url + 'driveralivis/' + driverId, formData)
  }

  updateVehicle(object, vehcileId) {
    const formData = new FormData();
    for (var key of Object.keys(object)) {
      formData.append(key, object[key]);
    }
    return this.http.put<any>(this.url + 'fleetalivis/' + vehcileId, formData)
  }

  sendErrorEmail(object, id) {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("token"),
      'Content-Type': 'application/json'
    });
    let options = {
      headers: authHeader,
    };
    
    return this.http.post<any>(this.url + 'companyalivisemails/' + id, object, options)
  }

  sendApprovalEmail(object, id) {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("token"),
      'Content-Type': 'application/json'
    });
    let options = {
      headers: authHeader,
    };
    
    return this.http.put<any>(this.url + 'companyalivisemails/' + id, object, options)
  }
}

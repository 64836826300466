import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {FacilityTripsService} from '../../../services/facility-trips/facility-trips.service';
import { AssignService } from 'src/app/services/assign/assign.service';
import { SharedDataService } from 'src/app/services/shared/shared-data.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { showHeading } from 'src/app/utils/global-constants';
import { ToastrService } from 'ngx-toastr';
import { CompanyService } from 'src/app/services/company/company.service';

declare var $: any;
@Component({
  selector: 'app-facility-trips-details',
  templateUrl: './facility-trips-details.component.html',
  styleUrls: ['./facility-trips-details.component.css']
})
export class FacilityTripsDetailsComponent implements OnInit {

  @ViewChild('audioOption', {static: true}) audioPlayerRef: ElementRef;
  tripId: any;
  trip: any;
  sendSMSForm: FormGroup;
  submitted = false;
  currentUser: any;
  origin: any;
  destination: any;
  companies: any = [];
  showHeading = showHeading;
  preferredprovider:any ={}
  constructor(private activatedRoute:ActivatedRoute, private formBuilder: FormBuilder,
    private facilityTripsService: FacilityTripsService, private sharedData: SharedDataService,
    private assignService: AssignService,
    private toastr: ToastrService,
    private companyService: CompanyService) {
      this.tripId = this.activatedRoute.snapshot.paramMap.get('tripId');
     }

  ngOnInit() {

    this.fetchCompanies();
    this.sendSMSForm = this.formBuilder.group({
      contactNumber :['',Validators.required]
    })
    this.sharedData.getUser().subscribe((data:any) => {
      this.currentUser = data;
    });

  }

  fetchCompanies(){

    this.companyService.getAllCompanies('active', true).subscribe(arg => {
      this.companies = arg;
      console.log(arg)

    this.getFacilityTripDetail();
    });
  }

  getFacilityTripDetail() {
    this.facilityTripsService.getTripDetail(this.tripId).subscribe((data: any) =>{
      this.trip = data;
      console.log('Trip Details : Source 1 : ', this.trip)
      if (this.trip && this.trip.assign) {
        this.getTripDetailsSecondSource();
      }
      this.preferredprovider  = this.companies.find(
        (i) => i._id.toString() === this.trip.suggestedprovider
        );
      this.getDirection();
    });
  }

  getTripDetailsSecondSource() {
    // seconds source to fetch trip details
    this.assignService
      .getTripDetail(this.trip.assign)
      .subscribe((data: any) => {
        if (data) {
          this.trip.job = data.job;
          console.log('Trip Details: Source 2 : ', data);
        }
      });
  }

  getDirection(){
    this.origin ={ lat : this.trip.jobOriginLatitude , lng: this.trip.jobOriginLongitude};
    this.destination ={ lat : this.trip.jobDestinationLatitude, lng: this.trip.jobDestinationLongitude };
  }

  get form() { return this.sendSMSForm.controls; }

  validateSMS(){
    this.submitted = true;
    if(this.sendSMSForm.invalid){
      return;
    }
    let params = {
      contactNumber: this.sendSMSForm.value.contactNumber,
      // trackId: this.trip.trackId,
      trackId: this.trip._id,
      latitude: this.trip.driver.latitude,
      longitude: this.trip.driver.longitude,
      jobOriginLatitude: this.trip.jobOriginLatitude,
      jobOriginLongitude: this.trip.jobOriginLongitude,
      jobDestinationLatitude: this.trip.jobDestinationLatitude,
      jobDestinationLongitude: this.trip.jobDestinationLongitude,
      jobStatus: this.trip.jobStatus,
      status: this.trip.status
    };
    this.assignService.sendSms(params).subscribe((data: any) => {
      if (data && data.success) {
        console.log('send sms', data);
        // $.Notification.autoHideNotify('success', 'bottom left', 'SMS Sent','SMS sent Successfully to the member');
        this.toastr.success(data.message,'Alert');
      }else{
        this.toastr.error(data.message,'Alert');

      }
      this.onAudioPlay()
    });

  }

  googleImageURL(latitude, longitude) {
    return `https://maps.googleapis.com/maps/api/staticmap?markers=icon:https://nemtpanel.com/modules/companies/client/img/buttons/normal.png|${latitude},${longitude}&size=160x170&key=AIzaSyC60nn_VZXJTxtmWuIB9SnHGU6USDb1LGo`;
   }

   onAudioPlay() {
    this.audioPlayerRef.nativeElement.play();
  }


}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { urls } from 'src/app/utils/url-utils';

@Injectable({
  providedIn: 'root'
})
export class TripAssignModalService {

  url = urls.BASE_URL;
  constructor(private http: HttpClient) { }

  getNearbyDrivers(data) {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("token")
    });
    let options = {
       headers: authHeader
       };
    return this.http.post(this.url + "drivers/nearby",data, options);
  }

  assiginDriver(data) {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("token")
    });
    let options = {
       headers: authHeader
       };
    return this.http.post(this.url + "assigns/selectedtrips",data, options);
  }


}

import { Injectable } from '@angular/core';
import { SharedDataService } from '../shared/shared-data.service';
import { Subject, BehaviorSubject } from 'rxjs';
import { urls } from "src/app/utils/url-utils";
import { SocketService } from '../socket/socket.service';

declare var $:any;
@Injectable({
  providedIn: 'root'
})

export class MarketplaceNotificationsService {
  url = urls.SERVER_URL;
  loggedIn: any;
  i = 0;
  private notification = new BehaviorSubject(null);

  constructor(private sharedData: SharedDataService, private socketService: SocketService) {
    this.sharedData.getLoginStatus().subscribe(status => this.loggedIn = true);
    //this.socketService.Socket();
  }
 

  getNotification() {
    return this.notification;
  }

  setNotification(data) {
    this.notification.next(data);
  }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { AlarmingTripsService } from 'src/app/services/alarming-trips/alarming-trips.service';
import { CompanyService } from 'src/app/services/company/company.service';
import { showHeading } from 'src/app/utils/global-constants';
import { getBrokersList, getJobStatus } from "src/app/utils/utils.common";

import * as moment from "moment-timezone";
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { NgxSmartModalService } from 'ngx-smart-modal';

declare var _: any;

@Component({
  selector: 'app-alarming-trips',
  templateUrl: './alarming-trips.component.html',
  styleUrls: ['./alarming-trips.component.css']
})
export class AlarmingTripsComponent implements OnInit, OnDestroy {
  alarmingTrips = [];
  companies = {};
  search;
  view = "list";
  showHeading = showHeading;
  companyType = getBrokersList;
  getTripJobStatus = getJobStatus;
  currentTime = moment().toISOString();
  intervalTimeID;
  intervalTripID;
  assignNotesModalObj;
  searchCompany = '';
  companyList: any = [];
  selectedCompanies = [];
  companyFilter = '';
  form: FormGroup;
  selectedCompaniesList = [];

  constructor(
    private alarmingTripService: AlarmingTripsService,
    private companyService: CompanyService,
    private fb: FormBuilder,
    public ngxSmartModalService: NgxSmartModalService
  ) {
    this.form = this.fb.group({
      companyIds: this.fb.array([])
    })
   }

  ngOnInit() {
    this.intervalTimeID = setInterval(() => {
      this.currentTime = moment().toISOString();
    }, 1000);

    this.fetchCompanies();
    this.intervalTripID = setInterval(() => {
      this.fetchAlarmingTrips();
    }, 15000);
    this.fetchAlarmingTrips();
  }

  onCompanyCheckboxChange(e) {
    const companyIds: FormArray = this.form.get('companyIds') as FormArray;
  
    if (e.target.checked) {
      companyIds.push(new FormControl(e.target.value));
    } else {
      let i: number = 0;
      companyIds.controls.forEach((item: FormControl) => {
        if (item.value == e.target.value) {
          companyIds.removeAt(i);
          return;
        }
        i++;
      });
    }

    // console.log(this.form.value);
  }

  fetchAlarmingTrips() {
    // this.alarmingTrips = [];
    this.alarmingTripService
    .getAlarmingTrips(this.form.value.companyIds.toString())
    .subscribe((data: any) => {
      if (data && data.assigns && data.assigns.length) {
        this.alarmingTrips = [];
        this.alarmingTrips = data.assigns;
      } else {
        this.alarmingTrips = [];
      }
    });
  }

  fetchCompanies() {
    this.companyService.getAllCompanies().subscribe((data: any) => {
      if (data && data.length) {
        this.companyList = data;
        this.companyList = _.sortBy( this.companyList, 'displayName' );
        this.companies = data.reduce((obj, item) => (obj[item._id] = item, obj) ,{});
      }
    });
  }

  getCompanyById(companyId) {
    if (this.companies.hasOwnProperty(companyId)) {
      return this.companies[companyId];
    }
    return { displayName : "--", timeZone: 'America/New_York'}
  }

  toggleView() {
    if (this.view === "list") {
      this.view = "grid";
    } else {
      this.view = "list";
    }
    console.log("View", this.view);
  }

  expiringSoonTime(trip, timeZone) {
    const now = moment().tz(timeZone).format();
    const expiringSoonTime = moment(trip.scheduleTime);
    return expiringSoonTime.diff(now, "minutes");
  }

  showNotes(trip): boolean {
    if (
      trip.companyNote ||
      trip.note ||
      trip.changeReason ||
      trip.billerNote ||
      trip.driverNote
    ) {
      return true;
    } else {
      return false;
    }
  }

  ngOnDestroy() {
    if (this.intervalTimeID) {
      clearInterval(this.intervalTimeID);
    }
    if (this.intervalTripID) {
      clearInterval(this.intervalTripID);
    }
  }




  setModalNotesContent(tripDetail) {
    console.log("Notes Modal");
    this.assignNotesModalObj = tripDetail;
  }

  openModal() {
    this.ngxSmartModalService.getModal('myModal').open()
  }

  closeModal() {
    this.ngxSmartModalService.getModal('myModal').close();
  }

  onModalCloseEvent() {
    this.operationsOnModalClose();
  }

  onDismiss() {
    this.operationsOnModalClose();
  }

  operationsOnModalClose() {
    this.companyFilter = '';
    this.fetchAlarmingTrips();
    this.selectedCompaniesList = [];
    if (this.form.value.companyIds) {
      this.selectedCompaniesList = this.filterCompanies(this.companyList, this.form.value.companyIds);  
    }
  }

  filterCompanies(companies, companyIds) {
    // const companiesList = _.cloneDeep(companies);
    const filteredCompanies = companies.filter(function (company) {
      return companyIds.indexOf(company._id) > -1;
    });

    return filteredCompanies;
  }

  isCompanySelected(companyId) {
    const companiesIds = this.form.value.companyIds;
    let found = false;
    if (companiesIds) {
      if (companiesIds.indexOf(companyId) > -1) {
        found = true;
      }
    }
     
    return found;
  }
}

import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FacilityService } from '../../../services/facility/facility.service';
import { ReportsService } from '../../../services/reports/reports.service';
import { SharedDataService } from 'src/app/services/shared/shared-data.service';
import * as moment from "moment-timezone";
import { showHeading } from 'src/app/utils/global-constants';
@Component({
  selector: 'app-facility-reports',
  templateUrl: './facility-reports.component.html',
  styleUrls: ['./facility-reports.component.css']
})
export class FacilityReportsComponent implements OnInit {

  currentUser: any;

  searchForm: FormGroup;
  facilities: any = [];
  submitted = false;
  facilityReportsData;
  selectedFacility = null;
  startDate = null;
  endDate = null;
  totalJobFee = 0;
  userPlatform: any = 'nemt';
  showHeading = showHeading;
  constructor(private fb: FormBuilder, private facilityService: FacilityService,
    private reportsService: ReportsService, private sharedData: SharedDataService) { }

  ngOnInit() {
    this.sharedData.getUser().subscribe(data => {
      this.userPlatform = data.platform;
      this.currentUser = data;
    });
    this.facilityService.getAllFacilities()
      .subscribe(data => {
        this.facilities = data;
      })
    this.searchForm = this.fb.group({
      user: ['', Validators.required],
      reportType: ['facility', Validators.required],
      providerStatus: [''],
      startDate: [null],
      endDate: [null],
      dateRange: ['']
    })
  }

  onDateChange(event) {
    console.log(event);
    if (event) {
      this.searchForm.patchValue({
        startDate: moment(event[0]).startOf("day").toISOString(),
        endDate: moment(event[1]).endOf("day").toISOString()
      });
    }
  }

  get form() {
    return this.searchForm.controls;
  }

  onSubmit() {
    this.facilityReportsData = undefined;
    console.log(this.searchForm)
    this.submitted = true;
    if (this.searchForm.invalid) {
      return
    }

    this.reportsService.getFacilityReport(this.searchForm.value)
      .subscribe(data => {
        console.log(data)
        if (data && data['length'] > 0) {
          this.facilityReportsData = data[0].jobs;
          this.facilityReportsData.sort((val1, val2)=> {return moment(val1.scheduleTime).diff(moment(val2.scheduleTime), 'minutes')})
          this.facilities.forEach(element => {
            if (element._id === this.searchForm.value.user) {
              this.selectedFacility = element;
            }
          });
          this.startDate = this.searchForm.value.startDate;
          this.endDate = this.searchForm.value.endDate;
          this.calculateJobFee(this.facilityReportsData);
        }
      })
  }

  calculateJobFee(trips) {
    this.totalJobFee = 0;
    trips.forEach(element => {
      this.totalJobFee += element.jobFee;
    });
  }

  downloadFacilityReport() {
    if (this.facilityReportsData.length > 0) {
      console.log(this.selectedFacility)
      this.reportsService.downloadFacilityReport(this.facilityReportsData, this.selectedFacility, this.startDate, this.endDate, this.totalJobFee, 'Enterprise Trips Report')
        .subscribe(data => {
          console.log(data);
          // console.log(url, fileName);
          const body = <HTMLDivElement>document.body;
          const linkSource = data['pdfFile'];
          const downloadLink = document.createElement('a');
          downloadLink.href = linkSource;
          downloadLink.target = '_blank';
          downloadLink.download = data['fileName'];
          body.appendChild(downloadLink);
          console.log(downloadLink)
          console.log(data['pdfFile'])
          console.log(data['fileName'])
          console.log(downloadLink.click())
          // downloadLink.click();
          // downloadLink.dispatchEvent(new MouseEvent('click'));
          var event = document.createEvent('Event');
          event.initEvent('click', true, true);
          downloadLink.dispatchEvent(event);
          // (window.URL || window.webkitURL).revokeObjectURL(downloadLink.href);

        })
    }
  }

}

import { Component, OnInit, ElementRef, ViewChild, Renderer2 } from "@angular/core";
import { AssignService } from "../../services/assign/assign.service";
import * as moment from "moment-timezone";
import { DatePipe } from "@angular/common";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { SharedDataService } from "src/app/services/shared/shared-data.service";
import { DownloadService } from "src/app/services/download/download.service";
declare var Custombox: any;
@Component({
  selector: "app-completed-trips",
  templateUrl: "./completed-trips.component.html",
  styleUrls: ["./completed-trips.component.css"]
})
export class CompletedTripsComponent implements OnInit {
  @ViewChild("date", {static: true}) date_v: ElementRef;
  assigns: any;
  datePipeEn = new DatePipe("en-US");
  searchForm: FormGroup;
  currentUser: any;
  submitted = false;
  openModalCheck = false;
  companyType: any;
  jobId: any;
  filteredTrips: any;
  token: any;
  selectedCompany = '';
  companies: any;
  constructor(private assignService: AssignService, private renderer: Renderer2, private formBuilder: FormBuilder, private sharedData: SharedDataService, private downloadService: DownloadService) {
    this.sharedData.getModal().subscribe(data => {
      this.openModalCheck = data;
    });
    this.assignService.getCompanies().subscribe((data: any) => {
      if (data.success === true) {
        this.companies = data.companies;
        console.log(this.companies)
      }
    });
  }
  companyFilter(selectedCompany) {
    console.log(this.selectedCompany)
    this.assignService.getCompanyToken(this.selectedCompany).subscribe((data: any) => {
      console.log(data);
      localStorage.setItem('companyToken', data.companyToken);
      localStorage.setItem('companyID', this.selectedCompany);

    });
  }
  ngOnInit() {
    var scheduleTime = moment()
      .startOf("day")
      .toISOString();
    this.searchForm = this.formBuilder.group({
      search_date: ["", Validators.required],
      company: ['']
    });

    // this.assignService.getCompletedTrips(scheduleTime, this.companyType, this.token).subscribe(data => {
    //   this.assigns = data;
    //   this.filteredTrips = data;
    // });
    this.sharedData.getUser().subscribe(data => {
      this.currentUser = data;
    });
    this.initJQuery();
  }
  get form() { return this.searchForm.controls; }
  submit() {
    // alert("done")
  }
  filterTrips(key, value) {
    this.filteredTrips = this.assigns.filter(function (assign) {
      return assign[key] === value;
    });
  }
  selectBrokerType(companyType) {
    // console.log(this.searchForm.value.search_date);
    if (this.companyType === companyType) {
      this.companyType = '';
      return false;
    }
    this.companyType = companyType;
    this.filterTrips('companyType', this.companyType);
  }
  onSubmit() {
    this.searchForm.value.search_date = this.datePipeEn.transform(
      this.date_v.nativeElement.value,
      "yyyy-MM-dd"
    );
    var scheduleTime = moment(this.searchForm.value.search_date)
      .startOf("day")
      .toISOString();
    this.submitted = true;
    if (!this.searchForm.value.search_date) {
      return;
    }
    this.getDataOfDate(scheduleTime);
  }
  getDataOfDate(scheduleTime) {
    if (this.selectedCompany === '') {
      return;
    }
    this.assignService.getCompletedTrips(scheduleTime, this.companyType).subscribe(data => {
      this.assigns = data;
      this.filteredTrips = this.assigns;
      console.log(this.assigns[0])
    });
    this.submitted = false;
  }


  getPDF(assignId) {
    this.assignService.getAmbPostPDF(assignId).subscribe((data: any) => {
      var baseURL = window.location.origin;
      var name = data.pdfName;
      var url = baseURL + "/pdf/" + name;
      this.downloadService.downloadURI(url, name);
    });
  }
  update(value) {
  }

  initJQuery() {
    this.addJsToElement("../assets/plugins/bootstrap-datepicker/js/bootstrap-datepicker.min.js").onload = () => { };
    this.addJsToElement("../assets/pages/jquery.form-pickers.init.js").onload = () => { };
  }
  openModal(job) {
    this.sharedData.updateModal(true);
    this.jobId = job;
    console.log(job)
    Custombox.open({ target: "#custom-modal", effect: "fadein", overlaySpeed: "200", overlayColor: "#36404a" });
  }
  closeModal() {
    this.openModalCheck = false;
    // Custombox.close();
  }

  addJsToElement(src: string): HTMLScriptElement {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = src;
    this.renderer.appendChild(document.body, script);
    return script;
  }
}
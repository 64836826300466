import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  // private dataSource = new BehaviorSubject(JSON.parse(JSON.stringify({"facility":"5fbe15187879f27da3c57c2a","_id":null,"appointmentTime":"2021-02-09T02:30:53+05:00","companyNote":"ghjgcnccnbcbnc","jobDestinationAddress":"92 - Shahrah-e-Quaid-e-Azam, The، Shahrah-e-Quaid-e-Azam, Jubilee Town, Lahore, Punjab 54000, Pakistan","jobDestinationLatitude":31.5566123,"jobDestinationLongitude":74.3263366,"jobOriginAddress":"Lahore Ring Rd, Block A Bankers Town, Lahore, Punjab, Pakistan","jobOriginLatitude":31.4515431,"jobOriginLongitude":74.3949027,"jobPassengerNumber":1,"jobType":"assigned","priorityClient":{"displayName":"erwreertr","contactNumber":"8976544678","cellPhone":"","clientId":"67543356"},"dob":"2021-01-31T19:00:00.000Z","scheduleTime":"2021-02-09T01:30:53+05:00","isMedical":true,"iSschUrgentCare":true,"isUrgentCare":true,"isWheelChair":true,"isCarSeat":true,"isLongDistance":true,"isOxygen":true,"memberId":"","tripRequirement":"AMB","schedulePickupHour":"01","schedulePickupMinutes":"30","appointmentPickupHour":"02","appointmentPickupMinutes":"30","scheduledTime":"2021-02-09T05:57:53-05:00","milage":null,"tripLegs":[{"scheduleTime":"2021-02-09T03:30:53+05:00","schedulePickupHour":"03","schedulePickupMinutes":"30","jobOriginAddress":"92 - Shahrah-e-Quaid-e-Azam, The، Shahrah-e-Quaid-e-Azam, Jubilee Town, Lahore, Punjab 54000, Pakistan","jobOriginLatitude":31.5566123,"jobOriginLongitude":74.3263366,"jobDestinationAddress":"Lahore Ring Rd, Block A Bankers Town, Lahore, Punjab, Pakistan","jobDestinationLatitude":31.4515431,"jobDestinationLongitude":74.3949027,"dob":"2021-01-31T19:00:00.000Z"}],"isWillCall":false,"additionalOptions":"multiLeg","additionalMember":false,"additionalPersonName":"","additionalPersonGender":"","tripReason":"Medical Checkup","doctorName":"aads","doctorContactNumber":"5436575","payorType":"Medicaid","medicalId":"65433567","memberNotes":"","driverInstructions":"","memberHomeZipCode":"87654356"})));
  private dataSource = new BehaviorSubject(JSON.parse(JSON.stringify({})));
  currentData = this.dataSource.asObservable();

  constructor() { }

  changeData(message: string) {
    this.dataSource.next(message)
  }





}

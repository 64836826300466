import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AuthService } from '../../services/auth/auth.service';
import { ActivatedRoute, Router } from "@angular/router";
import { SharedDataService } from "../../services/shared/shared-data.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"]
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  submitted = false;
  platform = 'nemt';
  @ViewChild('password', {static: true}) pass: ElementRef;
  constructor(private formBuilder: FormBuilder, private auth: AuthService, private router: Router, private sharedData: SharedDataService,private route: ActivatedRoute) {
    if(localStorage.getItem("token")){
      this.sharedData.updateLoginStatus(true);
      router.navigate(["/"]);
    }
  }
  ngOnInit() {
    console.log('Hostname: ', window.location.hostname);
    if (window.location.hostname.indexOf('nemtroutes') > -1 ) {
      this.platform = 'nemtroutes';
    } else if (window.location.hostname.indexOf('nemtplatform') > -1) {
      this.platform = 'nemt';
    }
    
    this.loginForm = this.formBuilder.group({
      email: ["", [Validators.required,Validators.email]],
      password: ["", Validators.required],
      roles: ['quarter']
    });
  }
  get form() {
    return this.loginForm.controls;
  }
  onSubmit() {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    this.auth.signin(this.loginForm.value);
  }
  togglePassword(){
    this.pass.nativeElement.type === 'text' ? this.pass.nativeElement.type = 'password' : this.pass.nativeElement.type = 'text' ;
  }
}

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  BASE_URL: "https://provider.qalbs.com/api/",
  SERVER_URL: "https://provider.qalbs.com/",
  ALIVI_ONBOARD_URL: "http://alividev.nemtservices.com/",

  FACILITY_NEMTROUTES_TOKEN:
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjoiNjA2ZWVhMjFmMDAwOGUyZTA0MDRlYjA2IiwiYWNjb3VudFN0YXR1cyI6ImFjdGl2ZSIsInJvbGVzIjoiZmFjaWxpdHkiLCJpYXQiOjE2MTc4ODE2NjF9.KgqlIXTFKoMIXhGQSHZ4C0-iCRySK3GR0uA9WrBGjK0",
    FACILITY_NEMT_TOKEN:
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjoiNjA2ZWVhMjFmMDAwOGUyZTA0MDRlYjA2IiwiYWNjb3VudFN0YXR1cyI6ImFjdGl2ZSIsInJvbGVzIjoiZmFjaWxpdHkiLCJpYXQiOjE2MTc4ODE2NjF9.KgqlIXTFKoMIXhGQSHZ4C0-iCRySK3GR0uA9WrBGjK0",
  NEW_SERVER_URL: "https://reskinned.nemtservices.com/"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

import { Injectable } from "@angular/core";
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { catchError } from "rxjs/operators";
import { urls } from "../../utils/url-utils";
import { errorHandl } from "../../utils/network-utils";
import { Observable, BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class TripService {
  url = urls.BASE_URL;
  private assignTripSource: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor(private http: HttpClient) {}

  getAssignListWithNgrx(endPoint): Observable<any> {
    let params = new HttpParams();
    params = params.append("isShowAllTrips", endPoint.isShowAllTrips);
    params = params.append("status", endPoint.status);
    if (endPoint.date) {
      params = params.append("scheduleTime", endPoint.date);
    }

    return this.http
      .get<any>(urls.BASE_URL + urls.GET_UNASSIGN_TRIPS_URL, { params })
      .pipe(catchError(errorHandl));
  }

  createNewTrip(tripData): Observable<any> {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("token"),
    });
    let options = { headers: authHeader };

    const timeKeys = [
      "schedulePickupHour",
      "schedulePickupMinutes",
      "appointmentPickupHour",
      "appointmentPickupMinutes",
      "scheduledTime",
    ];
    delete tripData["companyType"]; // not require right now
    timeKeys.forEach((element) => {
      delete tripData[element];
    });
    tripData.tripLegs = tripData.tripLegs.splice(1, tripData.tripLegs.length);
    if (tripData.tripLegs.length === 0) {
      delete tripData["tripLegs"];
    } else if (tripData.tripLegs.length > 0 && !tripData.isMedical) {
      for (let i = 0; i < tripData.tripLegs.length; i++) {
        delete tripData.tripLegs[i].tripId;
      }
    }
    console.log(tripData);
    if (tripData._id) {
      return this.http
        .put<any>(
          this.url + urls.CREATE_NEW_TRIP_URL + "/" + tripData._id,
          tripData,
          options
        )
        .pipe(catchError(errorHandl));
    } else {
      delete tripData._id;
      return this.http
        .post<any>(this.url + urls.CREATE_NEW_TRIP_URL, tripData, options)
        .pipe(catchError(errorHandl));
    }
  }

  getUnassignedTripDetailByIdWithNgrx(unassignedTripId): Observable<any> {
    return this.http
      .get<any>(
        urls.BASE_URL + urls.CREATE_NEW_TRIP_URL + "/" + unassignedTripId
      )
      .pipe(catchError(errorHandl));
  }

  changeAssignTripSource(data) {
    this.assignTripSource.next(data);
  }

  getCurrentAssignTripSource(): Observable<any> {
    return this.assignTripSource.asObservable();
  }

  findMemberTripRequest(endPoint: any): Observable<any> {
    let params = new HttpParams();
    params = params.append("type", "member");
    if(endPoint.displayName.length !== 0)
    params = params.append("displayName", endPoint.displayName);
    if(endPoint.contactNumber.length !== 0)
    params = params.append("contactNumber", endPoint.contactNumber);

    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("token"),
    });
    let options = { headers: authHeader, params };
    return this.http
      .get<any>(urls.BASE_URL + urls.FETCH_MEMBER_TRIPS_URL, options)
      .pipe(catchError(errorHandl));
  }
}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { urls } from 'src/app/utils/url-utils';

@Injectable({
  providedIn: 'root'
})
export class OperationsService {
  url = urls.BASE_URL;

  constructor(private http: HttpClient) { }

  getOperations(search): Observable<any> {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("token")
    });
    let options = { headers: authHeader };
    return this.http.post<any>(this.url + "assigns/operations", search,options);
  }

  cancelTrip(payload, token) {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + token
    });

    let options = { headers: authHeader };
    return this.http.post(this.url + 'assigns' + `/${payload._id}`, payload , options);
  }

  markCompleteTrip(payload, token): Observable<any> {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + token
    });
    let options = { headers: authHeader };
    const id = payload._id;
    return this.http.post<any>(this.url + `assigns/markcompleted?assignId=${id}`, payload, options);
  }

  getTripAssistanceList(companyId): Observable<any> {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("token")
    });
    let options = { headers: authHeader };
    return this.http.get<any>(this.url + 'tripassistances?company='+companyId, options);
  }

  processTripAssistance(tripId, payload) {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("token")
    });
    let options = { headers: authHeader };
    return this.http.put<any>(this.url + 'tripassistances' + `/${tripId}`, payload, options);
  }
}

import { Component, OnInit, ElementRef, ViewChild, Renderer2, AfterViewInit } from '@angular/core';
import * as moment from "moment-timezone";
import { DatePipe } from "@angular/common";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { SharedDataService } from "src/app/services/shared/shared-data.service";
import { FacilityTripsService } from '../../../services/facility-trips/facility-trips.service';
import { AssignService } from 'src/app/services/assign/assign.service';
import { UniquePipe } from 'ngx-pipes';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { ActivatedRoute, Router } from '@angular/router'
import { MarketplaceNotificationsService } from '../../../services/marketplaceNotifications/marketplace-notifications.service';
import { showHeading } from 'src/app/utils/global-constants';
import { ToastrService } from 'ngx-toastr';
import { FacilityService } from 'src/app/services/facility/facility.service';
declare var $: any;
declare var _: any;

@Component({
  selector: 'app-facility-trips',
  templateUrl: './facility-trips.component.html',
  styleUrls: ['./facility-trips.component.css'],
  providers: [UniquePipe]
})
export class FacilityTripsComponent implements OnInit, AfterViewInit {

  @ViewChild('audioOption', {static: true}) audioPlayerRef: ElementRef;
  @ViewChild("date", {static: true}) date_v: ElementRef;
  assigns: any;
  datePipeEn = new DatePipe("en-US");
  searchForm: FormGroup;
  currentUser: any;
  submitted = false;
  emptySearch: boolean;
  filteredAssigns: any;
  searchFacilitator: any;
  searchProvider = '';
  searchFacility = '';
  searchText: string;
  facility: any;
  selectedCompany: any;
  facilitator: any;
  providers: any;
  companyNote: any;
  preSpecialRate: any;
  index = -1;
  toAssign: any;
  assignId: any;
  companies: any;
  companyFilter: any;
  tripLogs = null;
  status = null;
  changeReason = null;
  cancelTripId = null;
  companyTypes = {
    'lcp': 'LCP',
    'default': 'Default',
    'first': 'MTM',
    'logisticscare': 'Logisticare',
    'medtrans': 'NMN',
    'united': 'SouthEastrans',
    'medicaid': 'Medicaid',
    'amera': 'Amera'
  };
  userPlatform = 'nemt';

  showHeading = showHeading;
  view = 'list';
  filterOpen = false;
  memberId;
  member;

  constructor(private renderer: Renderer2, private formBuilder: FormBuilder, private uniquePipe: UniquePipe,
    private sharedData: SharedDataService, private facilityTripsService: FacilityTripsService,
    private assignService: AssignService, public ngxSmartModalService: NgxSmartModalService,
    private activatedRoute: ActivatedRoute, private marketplaceNotificationsService: MarketplaceNotificationsService,
    private toastr: ToastrService,
    private facilityService: FacilityService) { }

  ngAfterViewInit(): void {
    this.initJQuery();
  }

  ngOnInit() {
    this.searchForm = this.formBuilder.group({
      search_date: ['', Validators.required],
      search: ['', Validators.required],
    });

    this.sharedData.getUser().subscribe(data => {
      if (data.platform) {
        this.userPlatform = data.platform;
      }
      this.currentUser = data;
      const todayDate = moment().startOf("day").toISOString();
      this.searchForm.patchValue({ search_date: moment(todayDate ).tz(this.currentUser.timeZone,true).toISOString() });
      this.date_v.nativeElement.value =   this.datePipeEn.transform(
        this.searchForm.value.search_date ,
        "yyyy-MM-dd"
      );
      console.log(this.searchForm.value)

      this.getData();
    });

  }

  get form() { return this.searchForm.controls; }

  onSubmit() {
    this.emptySearch = false;
    this.submitted = true;
    if (!this.searchForm.value.search_date && !this.searchForm.value.search) {
      // $.Notification.autoHideNotify('error', 'bottom left', 'Empty Search', 'please fill atleast one field to continue search. ')
      this.toastr.error('Please fill atleast one field to continue search.','Empty Search');
      this.onAudioPlay();
      this.emptySearch = true;
      return;
    }
    this.getData();
  }


  clearSearch() {
    this.searchForm.patchValue({
      search_date: '',
      search: '',
      search_name: '',
      search_phone: ''
    });
  }

  getData() {

    // console.log(this.activatedRoute)
    console.log(this.searchForm.value)

    console.log(this.activatedRoute.snapshot.url[1].path)
    this.status = this.activatedRoute.snapshot.url[1].path;
    this.searchForm.value.search_date = this.datePipeEn.transform(
      this.date_v.nativeElement.value,
      "yyyy-MM-dd"
    );
    // const scheduleTime = moment(this.searchForm.value.search_date).startOf("day").toISOString();
    const scheduleTime = moment(this.searchForm.value.search_date).tz(this.currentUser.timeZone,true).toISOString();
    let search = {}

    if (this.searchForm.value.search) {
      search = {
        'search': this.searchForm.value.search
      };
    }
    if (this.searchForm.value.search_date) {
      // search["scheduleTime"] = scheduleTime
      search = {
        ...search,
        'scheduleTime': scheduleTime
      };

    }

    if (this.status) {
      // search['status'] = this.status;

      search = {
        ...search,
        'status': this.status
      };
    }

    console.log(search, this.searchForm.value.search_date );
    this.facilityTripsService.getAllFacilityTrips(search).subscribe(data => {
      console.log(data);
      this.assigns = data;
      this.searchText = '';
      this.searchFacilitator = '';
      this.searchFacility = '';
      this.filteredAssigns = data;
      console.log(this.facility);
      // this.assigns.forEach(element => {
      //   element.companyType = this.sharedData.mapBrokers(element.companyType);
      // });
      // console.log(this.assigns)
      const facility = this.uniquePipe.transform(this.assigns, 'facility');
      this.facility = facility.map(elem => {
        return elem.facility;
      });
      console.log(this.facility);

      const facilitator = this.uniquePipe.transform(this.assigns, 'facilitator');
      this.facilitator = facilitator.map(elem => {
        return elem.facilitator;
      });
      console.log(this.facilitator);

      const providersCompanies = this.uniquePipe.transform(this.assigns, 'company');
      this.providers = providersCompanies.map(elem => {
        return elem.company;
      });
      console.log(this.providers);

    });
    this.submitted = false;
    console.log('URL_STATUS ', this.status);
  }

  filterResult() {
    console.log(this.searchProvider);
    console.log(this.searchFacilitator);
    console.log(this.searchText);
    console.log(this.searchFacility);
    this.filteredAssigns = this.assigns;
    if (this.searchText !== '') {
      if (this.searchText === 'onway') {
        this.filteredAssigns = this.filteredAssigns.filter(x => x.jobStatus === this.searchText);
      }
      else {
        this.filteredAssigns = this.filteredAssigns.filter(x => x.status === this.searchText);
      }
    }
    if (this.searchProvider !== '') {
      this.filteredAssigns = this.filteredAssigns.filter(x => x.company._id === this.searchProvider);
    }

    if (this.searchFacilitator !== '') {
      this.filteredAssigns = this.filteredAssigns.filter(x => x.facilitator._id === this.searchFacilitator);
    }
    if (this.searchFacility !== '') {
      this.filteredAssigns = this.filteredAssigns.filter(x => (x.facility._id === this.searchFacility));
    }
    // if (this.searchFacilitator === '' && this.searchText !== '') {
    //   this.filteredAssigns = this.assigns.filter(x => x.status === this.searchText);
    // }
    // if (this.searchText === '' && this.searchFacilitator !== '') {
    //   this.filteredAssigns = this.assigns.filter(x => x.facilitator._id === this.searchFacilitator);
    // }
    // if (this.searchText !== '' && this.searchFacilitator !== '') {
    //   this.filteredAssigns = this.assigns.filter(x => (x.facilitator._id === this.searchFacilitator && x.status === this.searchText));
    // }
    // if (this.searchText === '' && this.searchFacilitator === '' && this.searchFacility !== '') {
    //   this.filteredAssigns = this.assigns;
    // }
    // if (this.searchFacility !== '') {
    //   this.filteredAssigns = this.filteredAssigns.filter(x => (x.facility._id === this.searchFacility));
    // }
  }

  sendBack() {
    if (!this.changeReason) {
      // $.Notification.autoHideNotify('error', 'bottom left', 'Error', 'Provide cancel reason.');
      this.toastr.error('Provide cancel reason.','Error');
      this.onAudioPlay();
      return;
    }
    // const tripId = this.filteredAssigns[ind]._id;
    this.facilityTripsService.cancelTrip(this.cancelTripId, this.changeReason)
      .subscribe((data: any) => {
        console.log(data);
        this.cancelTripId = null;
        this.changeReason = null;
        this.getData();
      },
      error => {
        if(error && error.error) {
          this.toastr.error(error.error.message,'Error');
          this.onAudioPlay();
        }
      });
    this.ngxSmartModalService.get('mySendBackModal').close();

  }

  saveCompany(id, rate, companyNote, assign) {
    this.assignId = id;
    let data: any = {
      company: {
        _id: this.selectedCompany.id,
        displayName: this.selectedCompany.text
      },
      // currentCompany: {
      //   _id: this.selectedCompany.id,
      //   displayName: this.selectedCompany.text
      // },

      providerStatus: 'offered',
      previousCompany: assign.company ? assign.company : undefined
    }
    if (rate) {
      data.preSpecialRate = rate;
    }
    if (companyNote) {
      data.companyNote = companyNote;
    }

    console.log(assign);
    this.facilityTripsService.assignFacilityTrip(this.assignId, data)
      .subscribe((data: any) => {
        console.log('facilityTripsService', data);
        this.marketplaceNotificationsService.setNotification(data);
        // $.Notification.autoHideNotify('success', 'bottom left', 'Company Assigned', 'company assigned successfully. ');
        this.toastr.success('Company assigned successfully.','Company Assigned');
        this.onAudioPlay();
        this.assignId = '';
        // if (!this.trips[this.index]['company']) {
        //   this.trips[this.index]['company'] = {};
        // }

        // this.trips.splice(this.index, 1);
        // this.selectedCompanyTitle = ''
        this.selectedCompany = '';
        this.companyNote = undefined;
        this.preSpecialRate = undefined;
        this.companyFilter = undefined;
        this.index = -1
        //console.log(data);
        this.getData();
      });
    this.ngxSmartModalService.get('myModal').close();
  }

  openModal(index, assign) {
    this.selectedCompany = '';
    this.companyNote = assign.companyNote ? assign.companyNote : undefined;
    this.preSpecialRate = assign.preSpecialRate ? assign.preSpecialRate : undefined;
    this.index = index;
    //console.log(this.index, assign);
    this.toAssign = assign;
    this.assignId = assign._id
    this.assignService.nearByCompany(assign.jobOriginLatitude, assign.jobOriginLongitude, assign.jobCarType).subscribe((data: any) => {
      if (data.success) {
        console.log(data);
        this.companies = data.companies;
        this.companies = _.sortBy(this.companies, 'text');
        console.log(this.companies);
      }
    });
    this.ngxSmartModalService.getModal('myModal').open()
  }

  openTripLogModal(index, assign) {
    this.facilityTripsService.tripLogs(assign._id).subscribe((data: any) => {
      this.tripLogs = data;
      this.ngxSmartModalService.getModal('myTripLogModal').open()
    });

  }

  openSendBackModal(index) {
    this.cancelTripId = this.filteredAssigns[index]._id;;
    this.ngxSmartModalService.getModal('mySendBackModal').open()
  }

  selectCompany(companyId) {
    this.selectedCompany = companyId;
  }

  calculateEstimatedFare() {

  }

  dateFilter() {
    // this.searchForm.value.search_date = this.datePipeEn.transform(
    //   this.date_v.nativeElement.value,
    //   "yyyy-MM-dd"
    // );
    // let scheduleTime = this.searchForm.value.search_date//.toISOString();
    // scheduleTime = scheduleTime.split('T')[0];
    // console.log(scheduleTime)
    // this.searchText = '';
    // this.searchFacilitator = '';
    // this.searchFacility = '';
    // this.filteredAssigns = this.assigns;
    // if (scheduleTime) {
    //   this.filteredAssigns = this.filteredAssigns.filter(x => x.scheduleTime.split('T')[0] === scheduleTime);
    // }
  }

  initJQuery() {
    // this.addJsToElement("../assets/plugins/bootstrap-datepicker/js/bootstrap-datepicker.min.js").onload = () => { };
    // this.addJsToElement("../assets/pages/jquery.form-pickers.init.js").onload = () => { };




    this.addJsToElement("assets/js/table.js").onload = () => { console.log('table loded in fac') };

  }
  addJsToElement(src: string): HTMLScriptElement {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = src;
    this.renderer.appendChild(document.body, script);
    return script;
  }

  onAudioPlay() {
    this.audioPlayerRef.nativeElement.play();
  }

  toggleView() {
    if (this.view === 'list') {
      this.view = 'grid';
    } else {
      this.view = 'list';
    }
    console.log('View', this.view);
  }

  toggleFilter() {
    this.filterOpen = !this.filterOpen;
  }

  distributeTrip(tripId) {
    this.facilityTripsService.distributeTrip(tripId)
      .subscribe(data => {
        this.marketplaceNotificationsService.setNotification(data);
        // $.Notification.autoHideNotify('success', 'bottom left', 'Trip Distributed', 'Trip distributed successfully.');
        this.toastr.success('Trip distributed successfully.','Trip Distributed');
        this.onAudioPlay();
        console.log('Distribute Trip Response ', data);
        this.getData();
      });
  }

  approveAuthorizedTrip(tripId) {
    this.facilityTripsService.approveAuthorizedTrip(tripId, {isAuthorized: true})
      .subscribe(data => {
        if(data) {
          this.marketplaceNotificationsService.setNotification(data);
          this.toastr.success('Trip has been approved.','Alert');
          this.getData();
        }
      });
  }

  highlightRow(status,isAuthorized) {
    if(status === 'outstanding' && isAuthorized === false) {
      console.log('here!!!')
      return true;

    }
    else {
      return false;
    }
  }

  actionPermission(btn: string, providerStatus: string, isAuthorized: boolean): boolean {
    let show = false;

    if(btn.toUpperCase() === 'APPROVE') {
     if(this.status === 'authorization' && isAuthorized === false) {
      show = true;
     }
    } else if (btn.toUpperCase() === 'DISTRIBUTE') {
      if(providerStatus === 'unassigned') {
        if(status === 'outstanding' && isAuthorized === false) {

        }
      }
    } else if (btn.toUpperCase() === 'SEND_BACK') {
      
    } else if (btn.toUpperCase() === 'ASSIGN_REASSIGN') {
      
    } else if (btn.toUpperCase() === 'EDIT') {
      
    }

    return show;
  }

  showDriver() {
    let show = true;
    if (this.status === 'authorization' ||
      this.status === 'outstanding' ||
      this.status === 'offered' ||
      this.status === 'accepted' ||
      this.status === 'rejected') {
      show = false;
    }

    return show;
  }

  showProvider() {
    let show = true;
    if (this.status === 'authorization' ||
      this.status === 'outstanding'
    ) {
      show = false;
    }

    return show;
  }

  getTripStatus(providerStatus, jobStatus) {
      let status = '';
      if(this.status === 'authorization' ||
      this.status === 'outstanding' ||
      this.status === 'offered' ||
      this.status === 'accepted' ||
      this.status === 'rejected') {
        status = providerStatus;
      } else {
        status = jobStatus === "pending" ? "Unassigned" : jobStatus === "arrived" ? "On Scene" : jobStatus === "started" ? "Member Onboard" : jobStatus
      }

      return status;
  }

  openAuthorizeTripModel(assign) {
    this.toAssign = assign;
    this.memberId = assign.facility._id;
    if (this.memberId) {
      this.getMemberDetails(this.memberId);
    }
    this.ngxSmartModalService.get('authoriseTripModal').open();
  }

  getMemberDetails(memberId) {
    if (memberId) {
      this.facilityService
      .getFacilityToEdit(memberId)
      .subscribe((data: any) => {
        this.member = data;
        console.log('Member Details: ', this.member);  
      });
    }
  }

  authorizeTrip(tripId) {
    this.facilityTripsService.approveAuthorizedTrip(tripId, {isAuthorized: true})
      .subscribe(data => {
        if(data) {
          this.marketplaceNotificationsService.setNotification(data);
          this.toastr.success('Trip has been approved.','Alert');
          this.getData();
          this.ngxSmartModalService.get('authoriseTripModal').close();
        }
      });
  }

  

}

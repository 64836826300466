import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { CompanyService } from "src/app/services/company/company.service";
import { OperationsService } from "src/app/services/operations/operations.service";
import * as moment from "moment-timezone";
import { showHeading } from "src/app/utils/global-constants";
import { SharedDataService } from "src/app/services/shared/shared-data.service";
import { appAlert, getBrokersList, getJobStatus } from "src/app/utils/utils.common";
import { NgxSmartModalService } from "ngx-smart-modal";
declare var $: any;
declare var _: any;
@Component({
  selector: "app-operations",
  templateUrl: "./operations.component.html",
  styleUrls: ["./operations.component.css"],
})
export class OperationsComponent implements OnInit, OnDestroy {
  assignDriverModalObj;
  cancelTripModalObj;
  assignModalObj;
  assignNotesModalObj;
  alarmingTrips = [];
  activeTrips = [];
  upcomingTrips = [];
  scheduleTrips = [];
  cancelledTrips = [];
  finishTrips = [];
  showMarkedReady = false;
  searchKeyword = "";
  companies: any = [];
  submitted = false;
  emptySearch: boolean;
  showHeading = showHeading;
  currentTime = moment().toISOString();
  intervalTimeID;
  intervalTripID = null;
  selectedCompany;
  showLoading = false;
  timeZone = "America/New_York";
  pretimePickerDate = moment(moment(), "YYYY-MM-DD").startOf("day").format();
  timePickerDate = moment(moment(), "YYYY-MM-DD").startOf("day").format();
  @ViewChild("audioOption", { static: true }) audioPlayerRef: ElementRef;
  companyType = getBrokersList;
  getTripJobStatus = getJobStatus;
  requestSubscribe = null;
  cancelReason = '';
  companiesByKey;
  tripAssistanceList;
  tripAssistanceTimer;

  constructor(
    private companyService: CompanyService,
    private operationsService: OperationsService,
    private sharedData: SharedDataService,
    public ngxSmartModalService: NgxSmartModalService,
  ) {}

  ngOnInit() {
    this.intervalTimeID = setInterval(() => {
      this.currentTime = moment().toISOString();
    }, 1000);

    this.sharedData.getUser().subscribe((user) => {
      // console.log("This current user logged in", user);
      if (user) {
        this.timeZone = user.timeZone;
      }
    });
    this.companyService
      .getAllCompanies()
      .subscribe((data: any) => {
        if (data && data.length) {
          this.companies = data;
          this.companies = _.sortBy(this.companies, "displayName");
          this.companiesByKey = data.reduce((obj, item) => (obj[item._id] = item, obj), {});
        }
      });

    this.sharedData.geteMarkReadyStatus().subscribe((data) => {
      // console.log("Modal Detail MArked Ready");
      if (data) {
        this.alarmingTrips = [];
        this.activeTrips = [];
        this.upcomingTrips = [];
        this.scheduleTrips = [];
        this.cancelledTrips = [];
        this.finishTrips = [];
        this.getOperations();
      }
    });
  }

  getTripAssistance() {
    if(this.tripAssistanceTimer){
      clearInterval(this.tripAssistanceTimer);
    }

    this.tripAssistanceTimer = setInterval(() => {
      this.getTripAssistanceList();
    }, 30000)
  }

  getTripAssistanceList() {
    this.operationsService
      .getTripAssistanceList(this.selectedCompany._id)
      .subscribe(data => {
        if (data) {
          this.tripAssistanceList = data;
        }
      });
  }

  processTripAssistace(trip) {
    appAlert({
      title: "Alert!",
      text: `Are you sure you want to ${trip.isDone ? 'unmarked' : 'mark'} ready?`,
      icon: "warning",
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      showCancelButton: true
    }).then((result) => {
      if (result.isConfirmed) {
        let payload = trip;

        if (trip.isDone) {
          payload.isDone = false;
        } else {
          payload.isDone = true;
        }
        this.operationsService
          .processTripAssistance(trip._id, payload)
          .subscribe(data => {
            if (data) {
              this.tripAssistanceList.forEach(item => {
                if (item._id === data._id) {
                  item = data;
                }
              });
            }
          })
      }
    });
  }

  clearTripInterval() {
    if (this.requestSubscribe) this.requestSubscribe.unsubscribe();
    clearInterval(this.intervalTripID);
    this.intervalTripID = null;
  }
  setTripInterval() {
    // call first Time
    this.getOperations();
    // Start interval
    this.intervalTripID = setInterval(() => {
      this.getOperations();
    }, 15000);
  }
  selectCompany(id) {
    this.clearTripInterval();

    this.alarmingTrips = [];
    this.activeTrips = [];
    this.upcomingTrips = [];
    this.scheduleTrips = [];
    this.cancelledTrips = [];
    this.finishTrips = [];

    this.selectedCompany = this.companies.find((x) => x._id === id);

    if (this.selectedCompany) {
      this.timeZone = this.selectedCompany.timeZone;
      this.setTripInterval();
      if (this.selectedCompany.isTripAssistance) {
        this.getTripAssistance();
        this.getTripAssistanceList();
      }
    }
  }

  getOperations() {
    this.showLoading = false;
    if (!this.selectedCompany) return;
    this.showLoading = true;
    // let search = {
    //   company: this.selectedCompany,
    //   scheduleTime: this.currentTime
    //     .tz(this.timeZone)
    //     .startOf("day")
    //     .toISOString(),
    // };

    let search = {
      company: this.selectedCompany,
      scheduleTime: moment
        .tz(this.timePickerDate, "YYYY-MM-DD", this.timeZone)
        .toISOString(),
    };

    this.requestSubscribe = this.operationsService
      .getOperations(search)
      .subscribe((data) => {
        // console.log("TRIPS DATA: ", data);
        this.showLoading = false;
        this.filterJobs(data);
      });
  }

  filterJobs(data) {
    this.alarmingTrips = [];
    this.activeTrips = [];
    this.upcomingTrips = [];
    this.scheduleTrips = [];
    this.cancelledTrips = [];
    this.finishTrips = [];

    if (!this.selectedCompany) return;
    data.assigns.forEach((assign) => {
      const time = this.timeCalculation(assign, data.currentTime);
      if (assign.status === "inprogress") {
        // inprogress
        this.activeTrips.push(assign);
      } else if (assign.status === "finished") {
        // finished
        this.finishTrips.push(assign);
      } else if (assign.status === "cancelled") {
        // cancelled
        this.cancelledTrips.push(assign);
      } else if (
        time <= 60 ||
        assign.status === "declined" ||
        assign.status === "bumped"
      ) {
        // alarming
        this.alarmingTrips.push(assign);
      } else if (time <= 120 && time >= 60) {
        // Upcoming
        // this.upcomingTrips.push(assign);
        this.scheduleTrips.push(assign);
      } else if (time > 120) {
        // schedule
        this.scheduleTrips.push(assign);
      }
    });
    // console.log(
    //   this.alarmingTrips,
    //   this.activeTrips,
    //   this.upcomingTrips,
    //   this.scheduleTrips,
    //   this.cancelledTrips
    // );
  }

  timeCalculation(assign, currentTime) {
    var end = moment(assign.scheduleTime);
    var startTime = moment(currentTime);
    var duration = moment.duration(end.diff(startTime));
    return duration.asMinutes();
  }

  expiringSoonTime(trip) {
    const now = moment().tz(this.timeZone).format();
    const expiringSoonTime = moment(trip.scheduleTime);
    return expiringSoonTime.diff(now, "minutes");
  }

  getHeading() {
    return showHeading;
  }
  showTrips(b) {
    console.log(JSON.parse(b));
    this.showMarkedReady = JSON.parse(b);
  }

  setModalNotesContent(tripDetail) {
    console.log("Notes Modal");
    this.assignNotesModalObj = tripDetail;
  }
  setModalContent(tripDetail) {
    console.log("Trip Details Modal");
    this.assignModalObj = tripDetail;
  }

  setAssignDriverModal(trip) {
    this.assignDriverModalObj = trip;
  }

  fetchEta(trip, objectName) {
    console.log(objectName);
    this.companyService.getFetchETA(trip._id).subscribe((data: any) => {
      console.log(data);
      const selectedTrip = this[objectName].find((x) => x._id === data._id);
      if (selectedTrip) {
        selectedTrip.etaMessage = data.etaMessage;
      }
    });
  }

  response(responce): void {
    this.assignDriverModalObj = null;
    console.log("modal close");
    this.getOperations();
  }

  showNotes(trip): boolean {
    if (
      trip.companyNote ||
      trip.note ||
      trip.changeReason ||
      trip.billerNote ||
      trip.driverNote
    ) {
      return true;
    } else {
      return false;
    }
  }

  ngOnDestroy() {
    if (this.intervalTimeID) {
      clearInterval(this.intervalTimeID);
    }

    if(this.tripAssistanceTimer){
      clearInterval(this.tripAssistanceTimer);
    }

    // clearInterval(this.intervalTripID);
    this.clearTripInterval();
  }

  onDateChange(event) {
    const pickerDate = moment(event, "YYYY-MM-DD").format();
    if (event) {
      this.timePickerDate = pickerDate;
    }
    // Refresh Date
    this.getOperations();
    console.log(this.timePickerDate);
  }

  trackByScheduleFn(index, item) {
    if (!item) return;
    return item._id; // index or item.id
  }
  trackByActiveFn(index, item) {
    if (!item) return;
    return item._id; // index or item.id
  }
  trackByCancelledFn(index, item) {
    if (!item) return;
    return item._id; // index or item.id
  }
  trackByFinishFn(index, item) {
    if (!item) return;
    return item._id; // index or item.id
  }
  trackByAlarmingFn(index, item) {
    if (!item) return;
    return item._id; // index or item.id
  }

  inprogressCancelled(trip) {
    this.ngxSmartModalService.getModal('mySendBackModal').open()
    this.cancelTripModalObj = trip;
  }

  submitCancelReason() {
    if (!this.cancelReason) {
      return;
    }

    const companyId = this.selectedCompany ? this.selectedCompany._id : '';
    if (!companyId) {
      return;
    }
      
    this.companyService
      .getCompanyToken(companyId)
      .subscribe((data:any) => {
        if (data) {
          const token = data.companyToken;
          if (token) {
            this.cancelTripRequest(token);
          }
        }
      });
  }

  cancelTripRequest(token) {
    this.cancelTripModalObj.changeReason = this.cancelReason,
      this.cancelTripModalObj.status = "cancelled",
      this.cancelTripModalObj.jobStatus = "cancelled"

    this.operationsService
      .cancelTrip(this.cancelTripModalObj, token)
      .subscribe(data => {
        if (data) {
          this.ngxSmartModalService.getModal('mySendBackModal').close();
          appAlert({
            icon: 'success',
            title: 'Trip Cancel',
            text: 'Trip cancelled successfully'
          });
          this.clear()
          this.getOperations();
        }
      })
  }

  markCompleted(trip) {
    if (window.confirm('Are you sure you want to complete this trip?')) {
      const companyId = this.selectedCompany ? this.selectedCompany._id : '';
      if (!companyId) {
        return;
      }

      this.companyService
        .getCompanyToken(companyId)
        .subscribe((data: any) => {
          if (data) {
            const token = data.companyToken;
            if (token) {
              this.markCompleteRequest(trip, token);
            }
          }
        });

    }
  }

  markCompleteRequest(trip, token) {
    this.operationsService
        .markCompleteTrip(trip, token)
        .subscribe(data => {
          if (data) {
            appAlert({
              icon: 'success',
              title: 'Mark Complete',
              text: 'Trip marked complete successfully'
            });
            this.clear();
            this.getOperations();
          }
        });
  }

  clear() {
    this.alarmingTrips = [];
    this.activeTrips = [];
    this.upcomingTrips = [];
    this.scheduleTrips = [];
    this.cancelledTrips = [];
    this.finishTrips = [];
  }
  
}

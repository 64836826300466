/// <reference types="@types/googlemaps" />
import {
  Component,
  OnInit,
  ViewChild,
  Renderer2,
  OnDestroy,
  AfterViewInit,
} from "@angular/core";
import { SocketService } from "src/app/services/socket/socket.service";
import { SharedDataService } from "src/app/services/shared/shared-data.service";
import { LatLngBounds, MapsAPILoader, AgmInfoWindow, AgmMap } from "@agm/core";
import * as moment from "moment-timezone";
import { AssignService } from "src/app/services/assign/assign.service";
import { UniquePipe } from "ngx-pipes";
import { showHeading } from "src/app/utils/global-constants";

declare var $: any;
declare var google: any;

@Component({
  selector: "app-map-view",
  templateUrl: "./map-view.component.html",
  styleUrls: ["./map-view.component.css"],
  providers: [UniquePipe],
})
export class MapViewComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild("AgmMap", {static: true}) agmElement: AgmMap;
  online = 0;
  offline = 0;
  buzy = 0;
  event: any;
  assigns: any;
  count: number;
  user: any;
  drivers: any;
  selectedCompanyDrivers = [];
  currentCompanyDrivers: any;
  selectedCompany = "all";
  currentCompany = undefined;
  showStatus = ["online", "buzy"];
  status = false;
  selectedCard = "";
  zoom = 7;
  companies = [];
  autoZoom = true;
  showDrivers = true;
  showCompanies = false;
  searchAddress = '';
  public expiringSoonTrips = [];
  public approachingTrips = [];
  public markedTrips = [];
  public dataNotificationsList = [];
  notificationSearch = "";
  interval;
  agmMap;
  origin = {
    latitude: 37.0902,
    longitude: 95.7129,
  };
  showHeading = showHeading;
  showSections = false;
  selectedHourTab = 1;
  searchMarker;

  constructor(
    private socketService: SocketService,
    private renderer: Renderer2,
    private assignService: AssignService,
    private sharedData: SharedDataService,
    private uniquePipe: UniquePipe
  ) {
    this.sharedData.geteMarkReadyStatus().subscribe((data) => {
      if (this.assigns !== undefined && data !== null) {
        const index = this.assigns.findIndex(
          (assign) => assign._id === data._id
        );
        this.assigns[index].isReadyForPickup = data.isReadyForPickup;
      }
    });
  }

  ngOnInit() {
    this.sharedData.addExpireTripsListener().subscribe((data) => {
      console.log("addExpireTripsListener ", data);

      if (data && data.success) {
        this.expiringSoonTrips = data.assigns;
        this.setMapZoom();
      } else {
        this.expiringSoonTrips = [];
      }
    });
    this.sharedData.getMarkReadyTripsListener().subscribe((data) => {
      console.log("MArked Ready", data);

      if (data && data.success) {
        this.markedTrips = data.assigns;
      } else {
        this.markedTrips = [];
      }
    });
    this.sharedData.getDispatchApproachingTripsListener().subscribe((data) => {
      console.log("getDispatchApproachingTripsListener ", data);
      if (data && data.success) {
        this.approachingTrips = data.assigns;
        // console.log('APPROACHING TRIPS (SOCKET) ', this.approachingTrips, this.selectedHourTab);
      } else {
        this.approachingTrips = [];
      }
    });

    this.sharedData.getDrivers().subscribe((data) => {
      if (data) {
        if (this.agmMap) {
          this.drivers = data;
          this.callDriverRelatedFilter();
        }
      } else {
        if (this.agmMap) {
          this.drivers = [];
          this.callDriverRelatedFilter();
        }
      }
    });
  }

  ngAfterViewInit() {
    console.log(this.agmElement);
    this.agmElement.mapReady.subscribe((map) => {
      this.agmMap = map;

      this.getUser();
      const search = {};
      const scheduleTime = moment().startOf("day").toISOString();
      search["scheduleTime"] = scheduleTime;
      this.assignService.getAllTrips(search).subscribe((data: any) => {
        this.assigns = data;
      });

      this.interval = setInterval(() => {
        this.fetchAllData();
      }, 10000);
    });
  }

  setupCompanies() {
    this.companies = [];
    if (this.drivers) {
      this.drivers.forEach((element, index) => {
        let temp = {
          id: "all",
          text: "All Companies",
          timeZone: "",
          address: "",
          contactNumber: "",
          displayName: "",
          email: "",
          latitude: 0,
          longitude: 0,
          profileImageURL: "",
        };
        this.companies.push(temp);
        if (element.driverCompany !== null) {
          temp = {
            id: element.driverCompany._id,
            text: element.driverCompany.displayName,
            timeZone: element.driverCompany.timeZone,
            address: element.driverCompany.address,
            contactNumber: element.driverCompany.contactNumber,
            displayName: element.driverCompany.displayName,
            email: element.driverCompany.email,
            latitude: element.driverCompany.latitude,
            longitude: element.driverCompany.longitude,
            profileImageURL: element.driverCompany.profileImageURL,
          };
          this.companies.push(temp);
        }
      });
      this.companies = this.uniquePipe.transform(this.companies, "id");
      // this.online = this.drivers.filter(x => x.status === 'online').length;
      // this.offline = this.drivers.filter(x => x.status === 'offline').length;
      // this.buzy = this.drivers.filter(x => x.status === 'buzy').length;
    }
  }

  getUser() {
    this.sharedData.getUser().subscribe((data) => {
      console.log(data);
      this.user = data;
      this.socketService.getDrivers();
    });
  }

  updateStatus(status) {
    if (status === this.selectedCard) {
      this.showStatus = ["online", "buzy"];
      this.selectedCard = "";
      return false;
    }
    if (status === "offline") {
      this.showStatus = ["offline"];
    } else if (status === "online") {
      this.showStatus = ["online"];
    } else if (status === "buzy") {
      this.showStatus = ["buzy"];
    }
    this.selectedCard = status;

    this.setMapZoom();
  }

  getNameChars(assign) {
    if (assign && assign.priorityClient && assign.priorityClient.displayName) {
      return assign.priorityClient.displayName
        .toLowerCase()
        .trim()
        .split(" ")[0];
    }
  }

  expiringSoonTime(trip) {
    const now = moment.utc(new Date()).local().format();
    const expiringSoonTime = moment(trip.scheduleTime);
    return expiringSoonTime.diff(now, "minutes");
  }

  companyFilter() {
    this.currentCompany = this.companies.filter((x) => {
      return x.id === this.selectedCompany;
    });

    if (this.selectedCompany !== "all") {
      this.showSections = true;
    } else {
      this.showSections = false;
    }

    this.applyFilterOnDrivers();
    this.fetchAllData();
    this.selectedCard = "";
  }

  applyFilterOnDrivers() {
    if (this.selectedCompany !== "all") {
      this.selectedCompanyDrivers = this.drivers.filter(
        (d) => d.driverCompany._id === this.currentCompany[0].id
      );
      this.findOnlineOfflineDrivers(this.selectedCompanyDrivers);
      // this.online = this.selectedCompanyDrivers.filter((x) => x.status === 'online').length;
      // this.offline = this.selectedCompanyDrivers.filter((x) => x.status === 'offline').length;
      // this.buzy = this.selectedCompanyDrivers.filter((x) => x.status === 'buzy').length;
      // // this.online = this.drivers.filter((x) => x.status === "online").length;
      // // this.offline = this.drivers.filter((x) => x.status === "offline").length;
      // // this.buzy = this.drivers.filter((x) => x.status === "buzy").length;
    } else {
      this.selectedCompanyDrivers = this.drivers;
      this.findOnlineOfflineDrivers(this.selectedCompanyDrivers);
      // // this.online = this.drivers.filter(x => x.status === 'online' && x.driverCompany && x.driverCompany._id === company).length;
      // // this.offline = this.drivers.filter(x => x.status === 'offline' && x.driverCompany && x.driverCompany._id === company).length;
      // // this.buzy = this.drivers.filter(x => x.status === 'buzy' && x.driverCompany && x.driverCompany._id === company).length;
      // this.selectedCompanyDrivers = this.drivers;
      // this.online = this.drivers.filter((x) => x.status === 'online').length;
      // this.offline = this.drivers.filter((x) => x.status === 'offline').length;
      // this.buzy = this.drivers.filter((x) => x.status === 'buzy').length;
      // // this.online = 0;
      // // this.offline = 0;
      // // this.buzy = 0;
    }
  }

  findOnlineOfflineDrivers(drivers) {
    if (drivers) {
      this.online = drivers.filter((x) => x.status === "online").length;
      this.offline = drivers.filter((x) => x.status === "offline").length;
      this.buzy = drivers.filter((x) => x.status === "buzy").length;
    }
  }

  getMarkedReadyTrips() {
    this.markedTrips = [];
    if (this.selectedCompany !== "all") {
      console.log(this.currentCompany && this.currentCompany.length > 0);
      const companyObject = {
        company: {
          _id: this.currentCompany[0].id,
          timeZone: this.currentCompany[0].timeZone,
        },
      };
      console.log(companyObject);
      this.socketService.getMarkReadyTripsEmit(companyObject);
    }
  }

  getExpireTrips() {
    this.expiringSoonTrips = [];
    if (this.selectedCompany !== "all") {
      console.log(this.currentCompany && this.currentCompany.length > 0);
      const companyObject = {
        company: {
          _id: this.currentCompany[0].id,
          timeZone: this.currentCompany[0].timeZone,
        },
      };
      console.log(companyObject);
      this.socketService.getExpireTripsEmit(companyObject);
    }
  }

  getDispatchApproachingTripsCall(hours) {
    this.selectedHourTab = hours;
    this.approachingTrips = [];
    if (this.selectedCompany !== "all") {
      console.log(this.currentCompany && this.currentCompany.length > 0);
      let companyObject = {
        company: {
          _id: this.currentCompany[0].id,
          timeZone: this.currentCompany[0].timeZone,
        },
        hours: hours,
      };
      console.log(companyObject);
      this.socketService.getApproachingTripsEmit(companyObject);
    }
  }

  getNotification() {
    this.dataNotificationsList = [];
    if (this.selectedCompany !== "all") {
      this.assignService
        .getNotification(this.currentCompany[0].id)
        .subscribe((data: any) => {
          console.log(data);
          if (data.length > 0) {
            this.dataNotificationsList = data;
          } else {
            this.dataNotificationsList = [];
          }
        });
    }
  }

  fetchAllData() {
    if (this.selectedCompany !== "all") {
      this.getMarkedReadyTrips();
      this.getNotification();
      this.getDispatchApproachingTripsCall(this.selectedHourTab);
      this.getExpireTrips();
      this.socketService.getDrivers();
    } else {
      this.expiringSoonTrips = [];
      this.markedTrips = [];
      this.approachingTrips = [];
      this.dataNotificationsList = [];
      // Apply filter Driver related things

      if (this.agmMap) {
        if (this.drivers) {
          this.socketService.getDrivers();
        } else {
          this.callDriverRelatedFilter();
        }
      }
    }
  }

  setMapBoundsWithRespectToDrivers() {
    this.sharedData.getDriverLocation().subscribe((driver) => {
      if (this.drivers && driver && driver.id) {
        for (var i = 0; i < this.drivers.length; i++) {
          if (this.drivers[i]._id.toString() === driver._id.toString()) {
            this.drivers[i].latitude = driver.latitude;
            this.drivers[i].longitude = driver.longitude;
            this.drivers[i].status = driver.status;
          }
        }
      }

      if (this.selectedCompanyDrivers && driver && driver.id) {
        for (let i = 0; i < this.selectedCompanyDrivers.length; i++) {
          if (
            this.selectedCompanyDrivers[i]._id.toString() ===
            driver._id.toString()
          ) {
            this.selectedCompanyDrivers[i].latitude = driver.latitude;
            this.selectedCompanyDrivers[i].longitude = driver.longitude;
            this.selectedCompanyDrivers[i].status = driver.status;
          }
        }
      }
    });
    this.setMapZoom();
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
  }

  callDriverRelatedFilter() {
    this.setupCompanies();
    this.setMapBoundsWithRespectToDrivers();
    this.applyFilterOnDrivers();
  }

  private setMapZoom() {
    if (!this.autoZoom) return;
    if (this.agmMap) {
      const bounds: LatLngBounds = new google.maps.LatLngBounds();

      if (this.showDrivers && this.selectedCompanyDrivers) {
        for (const driver of this.selectedCompanyDrivers) {
          if (
            driver.driverCompany &&
            this.showStatus.indexOf(driver.status) > -1
          ) {
            bounds.extend(
              new google.maps.LatLng(driver.latitude, driver.longitude)
            );
          }
        }
      }

      if (this.showCompanies && this.companies) {
        for (const company of this.companies) {
          if (company.id !== "all") {
            bounds.extend(
              new google.maps.LatLng(company.latitude, company.longitude)
            );
          }
        }
      }

      if (this.searchMarker) {
        bounds.extend(
          new google.maps.LatLng(
            this.searchMarker.latitude,
            this.searchMarker.longitude
          )
        );
      }

      for (const trips of this.expiringSoonTrips) {
        bounds.extend(
          new google.maps.LatLng(
            trips.jobOriginLatitude,
            trips.jobOriginLongitude
          )
        );
      }
      this.agmMap.setCenter(bounds.getCenter());
      this.agmMap.fitBounds(bounds);
    }
  }

  getOriginAddress(place) {
    console.log(place);
    // this.autoZoom = false;
    this.searchMarker = {
      latitude: place.geometry.location.lat(),
      longitude: place.geometry.location.lng(),
    };
    this.setMapZoom();
  }
  changeNgModal() {
    this.setMapZoom();
  }
  clearSearch() {
    this.searchMarker = undefined;
    this.searchAddress = '';
    this.setMapZoom();
  }
}

import { Component, OnInit } from "@angular/core";
import { SharedDataService } from "../../../services/shared/shared-data.service";
import { Router } from "@angular/router";
import { SocketService } from "src/app/services/socket/socket.service";
@Component({
  selector: "app-nav",
  templateUrl: "./nav.component.html",
  styleUrls: ["./nav.component.css"]
})
export class NavComponent implements OnInit {
  user: any;
  constructor(private sharedData: SharedDataService ,private router: Router, private socketService: SocketService) {
  }

  ngOnInit() {
    this.sharedData.getUser().subscribe(data=>{
      this.user = data;
    })
  }
  signout(){
    localStorage.removeItem("token");
    this.sharedData.updateLoginStatus(false);
    this.socketService.disconnectSocket();
    this.router.navigate(["/login"]);
  }
  openProfile(){
    this.router.navigate(["/profile"]);
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { urls } from "src/app/utils/url-utils";
import { catchError } from 'rxjs/operators';
import { errorHandl } from 'src/app/utils/network-utils';
import { throwError } from 'rxjs/internal/observable/throwError';

@Injectable({
  providedIn: 'root'
})
export class FacilityService {

  url = urls.BASE_URL;
  constructor(private http: HttpClient) { }

  getAllFacilities(profileRole = 'facility') {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("token")
    });
    let options = { headers: authHeader };
    // console.log(search)
    return this.http.get(this.url + "facilities?profileRole=" + profileRole, options);
  }

  createNewFacility(facility, image) {
    console.log(facility)
    console.log(image)
    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("token")
    });
    let options = { headers: authHeader };
    // console.log(search)
    const formData: FormData = new FormData();
    Object.keys(facility).forEach(key => {
      formData.append(key, facility[key]);
    })
    formData.append('newProfilePicture', image)
    return this.http.post(this.url + "facilities", formData, options)
      .pipe(catchError(errorHandl));
  }

  getFacilityToEdit(facilityId) {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("token")
    });
    let options = { headers: authHeader };
    // console.log(search)
    return this.http.get(this.url + `facilities/${facilityId}`, options);
  }

  editCompany(facilityId, facility, image) {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("token")
    });
    let options = { headers: authHeader };
    // console.log(search)
    return this.http.put(this.url + `facilities/${facilityId}`, facility, options)
      .pipe(catchError(errorHandl));
  }

  editCompanyNew(facilityId, facility, image) {
    console.log(facility)
    console.log(image)
    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("token")
    });
    let options = { headers: authHeader };
    // console.log(search)
    const formData: FormData = new FormData();
    Object.keys(facility).forEach(key => {
      formData.append(key, facility[key]);
    })
    if (image) {
      formData.append('newProfilePicture', image)
    }
    return this.http.put(this.url + `facilities/${facilityId}`, formData, options)
      .pipe(catchError(errorHandl));
  }
}

import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NemtCodesService } from 'src/app/services/nemt-codes/nemt-codes.service';
import { showHeading } from 'src/app/utils/global-constants';

@Component({
  selector: 'app-add-nemt-code',
  templateUrl: './add-nemt-code.component.html',
  styleUrls: ['./add-nemt-code.component.css']
})
export class AddNemtCodeComponent implements OnInit {
  purposeForm: FormGroup;
  codeId: any;
  submitted = false;
  showHeading = showHeading;

  constructor(
    private fb: FormBuilder,
    private nemtCodesService: NemtCodesService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.codeId = this.activatedRoute.snapshot.paramMap.get('id');
  }

  ngOnInit() {
    this.purposeForm = this.fb.group({
      code: ['', Validators.required],
      title: ['', Validators.required],
      status: ['active', Validators.required],
    });

    if (this.codeId) {
      this.getNemtCodeDetails();
    }
  }

  getNemtCodeDetails() {
    this.nemtCodesService
      .getNemtCodeById(this.codeId)
      .subscribe((data: any) => {
        if (data) {
          this.purposeForm.patchValue(data);
        }
      });
  }

  onSubmit() {
    console.log(this.purposeForm.value);
    this.submitted = true;

    if (this.purposeForm.invalid) {
      return;
    }

    this.nemtCodesService
      .saveNemtCode(this.codeId, this.purposeForm.value)
      .subscribe(data => {
        if (data) {
          this.router.navigate(['/nemt-codes']);
        }
      });
  }

  get form() {
    return this.purposeForm.controls;
  }
}


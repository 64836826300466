import { Component, OnInit, OnDestroy, Input, Renderer2, OnChanges } from '@angular/core';
import { AssignService } from 'src/app/services/assign/assign.service';
import { SharedDataService } from 'src/app/services/shared/shared-data.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
declare var Custombox: any;
import * as moment from "moment-timezone";
import { DateTimeAdapter } from 'ng-pick-datetime';
import { Router } from '@angular/router';
import { appAlert } from 'src/app/utils/utils.common';

@Component({
  selector: 'app-adjustment-modal',
  templateUrl: './adjustment-modal.component.html',
  styleUrls: ['./adjustment-modal.component.css']
})
export class AdjustmentModalComponent implements OnInit, OnDestroy {
  @Input('job') jobId: any;
  job: any;
  adjustmentForm: FormGroup;
  submitted = false;
  currentUser: any;
  drivers: any;
  driverCar: any;
  escort: any;
  helper: any;
  support: any;
  index = -1;
  constructor(private router: Router,
    private dateTimeAdapter: DateTimeAdapter<any>,
    private renderer: Renderer2,
    private assignService: AssignService,
    private sharedData: SharedDataService,
    private formBuilder: FormBuilder) {

    this.sharedData.getUser().subscribe((data: any) => {
      this.currentUser = data;
    });
  }
  ngOnInit() {
    console.log(this.jobId)
    let companyID = localStorage.getItem('companyID');
    this.initJQuery();
    this.assignService
      .getTripByJob(this.jobId)
      .subscribe((data: any) => {
        this.job = data;
        console.log(this.job);
        this.assignService.getDrivers(companyID).subscribe((data: any) => {
          this.drivers = data;
          console.log(this.drivers)
          this.index = this.drivers.findIndex(driver => driver._id === this.job.driver._id);
          this.driverCar = this.drivers[this.index].driverCar;
          this.initForm();
        });
      });
  }
  initForm() {
    this.adjustmentForm = this.formBuilder.group({
      'jobFee': [this.job.jobFareDetail.jobFee, Validators.required],
      'driver': [this.drivers[this.index], Validators.required],
      'odometerStart': [this.job.odometerStart, Validators.required],
      'odometerStop': [this.job.odometerStop, Validators.required],
      'isEscort': [false],
      'escortName': [this.job.escortName],
      'escortRelation': [this.job.escortRelation],
      'totalMiles': [this.job.additionalInfoJob.totalMiles, Validators.required],
      'created': [this.job.created, Validators.required],
      'jobArrivedTime': [this.job.trackInfoJob.jobArrived.time, Validators.required],
      'jobStartedTime': [this.job.trackInfoJob.jobStarted.time, Validators.required],
      'jobCompletedTime': [this.job.trackInfoJob.jobCompleted.time, Validators.required],
      'jobTollFee': [this.job.additionalInfoJob.jobTollFee, Validators.required],
      'jobCarType': [this.job.jobCarType, Validators.required]

      // 'jobFee': ['', Validators.required],
      // 'driver': ['', Validators.required],
      // 'odometerStart': ['', Validators.required],
      // 'odometerStop': ['', Validators.required],
      // 'isEscort': [false],
      // 'escortName': [''],
      // 'escortRelation': [''],
      // 'totalMiles': ['', Validators.required],
      // 'created': ['', Validators.required],
      // 'jobArrivedTime': ['', Validators.required],
      // 'jobStartedTime': ['', Validators.required],
      // 'jobCompletedTime': ['', Validators.required],
      // 'jobTollFee': ['', Validators.required],
      // 'jobCarType': ['', Validators.required]

    });
  }
  get form() { return this.adjustmentForm.controls };

  onSubmit() {
    this.submitted = true;
    if (this.adjustmentForm.invalid) {
      return;
    }
    this.updateJob();

  }
  updateJob() {

    this.adjustmentForm.value.jobStartedTime = moment(this.adjustmentForm.value.jobStartedTime).toISOString();
    this.adjustmentForm.value.jobCompletedTime = moment(this.adjustmentForm.value.jobCompletedTime).toISOString();
    this.adjustmentForm.value.jobArrivedTime = moment(this.adjustmentForm.value.jobArrivedTime).toISOString();
    this.adjustmentForm.value.created = moment(this.adjustmentForm.value.created).toISOString();

    this.job.created = this.adjustmentForm.value.created;
    this.job.trackInfoJob.jobArrived.time = this.adjustmentForm.value.jobArrivedTime;
    this.job.trackInfoJob.jobStarted.time = this.adjustmentForm.value.jobStartedTime;
    this.job.trackInfoJob.jobCompleted.time = this.adjustmentForm.value.jobCompletedTime

    var obj = {
      jobFee: Number(this.adjustmentForm.value.jobFee),
      totalMiles: Number(this.adjustmentForm.value.totalMiles),
      jobArrived: this.job.trackInfoJob.jobArrived,
      jobStarted: this.job.trackInfoJob.jobStarted,
      jobCompleted: this.job.trackInfoJob.jobCompleted,
      jobFinished: this.job.trackInfoJob.jobCompleted,
      // jobMinutes: this.job.additionalInfoJob.jobMinutes,
      additionalWaitTime: this.job.additionalInfoJob.additionalWaitTime,
      jobTollFee: Number(this.adjustmentForm.value.jobTollFee),
      created: this.job.created,
      odometerStart: Number(this.adjustmentForm.value.odometerStart),
      odometerStop: Number(this.adjustmentForm.value.odometerStop),
      driver: this.adjustmentForm.value.driver._id, // July 31, 2018
      jobCarType: this.adjustmentForm.value.driver.driverCar,
      escortName: this.adjustmentForm.value.escortName || '',
      escortRelation: this.adjustmentForm.value.escortRelation || '',
      isEscort: this.adjustmentForm.value.isEscort
    };
    this.assignService.AdjustTrips(this.jobId, obj).subscribe(data => {
      console.log(data);
      this.closeModal();
      appAlert({
        icon: "success",
        title: "Trip Verified Successfully",
        text: "Your trip is verified Successfully "
      });
      this.router.navigate(['/adjusted-trips']);

    });
  }
  ngOnDestroy() {
  }
  closeModal() {
    this.sharedData.updateModal(false);
    Custombox.close();
  }
  initJQuery() {

  }
  updateDriver() {
    this.driverCar = this.adjustmentForm.value.driver.driverCar;
  }
  updateEscort() {
    this.escort = this.adjustmentForm.value.isEscort;
    if (this.escort === true) {
      this.helper = 'Helper';
      this.adjustmentForm.value.escortName = 'ASDSDS';
      this.support = 'Support';
    } else {
      this.helper = '';
      this.adjustmentForm.value.escortName = 'BSDSDAS';
      this.support = '';
    }
  }

  addJsToElement(src: string): HTMLScriptElement {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = src;
    this.renderer.appendChild(document.body, script);
    return script;
  }
}

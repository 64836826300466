import { Component, OnInit } from '@angular/core';
import { showHeading } from 'src/app/utils/global-constants';
import { FacilityTripsService } from '../../../services/facility-trips/facility-trips.service';

@Component({
  selector: 'app-facility-trips-logs',
  templateUrl: './facility-trips-logs.component.html',
  styleUrls: ['./facility-trips-logs.component.css']
})
export class FacilityTripsLogsComponent implements OnInit {

  tripLogs = null;
  showHeading = showHeading;
  constructor(private facilityTripsService: FacilityTripsService) { }

  ngOnInit() {
    this.getAllTripsLogs();
  }
  getAllTripsLogs() {
    this.facilityTripsService.allTripLogs().subscribe((data: any) => {
      this.tripLogs = data;
      console.log(data)
    });
  }

}

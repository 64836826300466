import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CompanyService } from 'src/app/services/company/company.service';
import { SharedDataService } from 'src/app/services/shared/shared-data.service';
import { showHeading } from 'src/app/utils/global-constants';
import { urls } from 'src/app/utils/url-utils';
import * as moment from "moment-timezone";

@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.css']
})
export class CompaniesComponent implements OnInit {
  companies: any;
  accountStatus: any;
  userPlatform: any = 'nemt';
  searchComapny;
  showHeading = showHeading;
  canView = false;
  viewAccess = false;
  user;
  timeZone;
  today;
  constructor(
    private companyService: CompanyService,
    private activatedRoute: ActivatedRoute,
    private sharedData: SharedDataService,
    private router: Router
  ) {
    document.addEventListener('keyup', e => {
      console.log(e);
      // e.ctrlKey &&
      if (e.altKey && (e.key.toLowerCase() === 'v' || e.key.toLowerCase() === '√') && this.viewAccess) {
        this.canView = !this.canView;
      }
    });
  }

  ngOnInit() {
    this.sharedData.getUser().subscribe(data => {
      if (data) {
        this.user = data;
        this.timeZone = data.timeZone;
        this.userPlatform = data.platform;
        if (data.roleType === 'admin') {
          this.viewAccess = true;
        }
        this.today = moment().tz(this.timeZone);
      }
    });
    //this.accountStatus = this.activatedRoute.snapshot.paramMap.get('status');
    this.activatedRoute.params.subscribe(params => {
      this.accountStatus = params['status'];
      this.companyService
        .getAllCompanies(this.accountStatus, true)
        .subscribe(data => {
          this.companies = data;
          
          if (this.companies && this.companies.length) {
            // this.companies.sort((a, b) => a.displayName.localeCompare(b.displayName)); // ASC
            // this.companies.sort((a, b) => <any>new Date(a.created) - <any>new Date(b.created));
          }
      });
    });
  }

  openProviderPanel(companyId, isNewPanel) {
    this.companyService.getCompanyToken(companyId)
      .subscribe((data: any) => {
        if (isNewPanel) {
          window.open(`${urls.NEW_SERVER_URL}auth-token/${data.companyToken}`, '_blank');
        } else {
          window.open(`${urls.SERVER_URL}auth_token?authtoken=${data.companyToken}`, '_blank');
        }
      });
  }

  getTrialStatus(date) {
    const trialEndDate = moment(date).tz(this.timeZone);
    return trialEndDate.diff(this.today, 'days');
  }

}

import { Component, OnInit } from '@angular/core';
import { CompanyService } from 'src/app/services/company/company.service';
import { DriverService } from 'src/app/services/driver/driver.service';
import { SharedDataService } from 'src/app/services/shared/shared-data.service';

@Component({
  selector: 'app-drivers',
  templateUrl: './drivers.component.html',
  styleUrls: ['./drivers.component.css']
})
export class DriversComponent implements OnInit {
  search = '';
  drivers = [];
  companies;
  driver;
  companyId = '';

  constructor(
    private companyService: CompanyService,
    private driverService: DriverService
  ) { }

  ngOnInit() {
    this.companyService.getAllCompanies()
      .subscribe((data) => {
        this.companies = data;
      });
  }

  onCompanyChange(evt) {
    this.companyId = evt.target.value;
    console.log('Company ID: ', this.companyId);
    if(this.companyId) {
      this.getCompanyDrivers(this.companyId, "active");
    } else {
      this.drivers = [];
    }
  }

  getCompanyDrivers(companyId, status) {
    this.driverService.getCompanyDrivers(companyId, status)
      .subscribe((data: any) => {
        console.log('Drivers: ', data)
        this.drivers = data;
      });
  }

  openAssignVehicleModal(driver) {
    this.driver = driver;
  }

  vehicleAssigned(evt) {
    console.log('Vehicle Assigned: ', evt);
    this.driver = evt;
    this.getCompanyDrivers(this.companyId, "active");
  }
}

import { Component, OnInit } from '@angular/core';
import { SharedDataService } from 'src/app/services/shared/shared-data.service';
import { StaffService } from 'src/app/services/staff/staff.service';
import { showHeading } from 'src/app/utils/global-constants';

@Component({
  selector: 'app-staff',
  templateUrl: './staff.component.html',
  styleUrls: ['./staff.component.css']
})
export class StaffComponent implements OnInit {
  staffList: any;
  userPlatform: any = 'nemt';
  searchStaff;
  showHeading = showHeading;
  constructor(private staffService: StaffService, private sharedData: SharedDataService) { }

  ngOnInit() {
    this.sharedData.getUser().subscribe(data => {
      this.userPlatform = data.platform;
    });
    this.staffService.getAllStaff().subscribe(arg => {
      this.staffList = arg;
    });
  }

}

import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Router } from '@angular/router';
import { SharedDataService } from 'src/app/services/shared/shared-data.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  passwordForm: FormGroup;
  submitted = false;
  constructor(private formBuilder: FormBuilder, private auth: AuthService, private router: Router, private sharedData: SharedDataService) {
    if(localStorage.getItem("token")){
      this.sharedData.updateLoginStatus(true);
      router.navigate(["/"]);
    }
  }
  ngOnInit() {
    this.passwordForm = this.formBuilder.group({
      email: ['', [Validators.required,Validators.email]],
      roles: ['quarter']
    });
  }
  get form() {
    return this.passwordForm.controls;
  }
  onSubmit() {
    this.submitted = true;
    if (this.passwordForm.invalid) {
      return;
    }
    console.log(this.passwordForm.value)
    this.auth.reset(this.passwordForm.value);
  }

}

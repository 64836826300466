import { Component, OnInit } from '@angular/core';
import { showHeading } from 'src/app/utils/global-constants';

@Component({
  selector: 'app-new-standing-order',
  templateUrl: './new-standing-order.component.html',
  styleUrls: ['./new-standing-order.component.css']
})
export class NewStandingOrderComponent implements OnInit {
  showHeading = showHeading;
  constructor() { }

  ngOnInit() {
  }

}

import { Component, OnInit } from '@angular/core';
import * as XLSX from 'xlsx';
import { MarketplaceServiceService } from 'src/app/services/marketplace/marketplace-service.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-excel-parser',
  templateUrl: './excel-parser.component.html',
  styleUrls: ['./excel-parser.component.css']
})
export class ExcelParserComponent implements OnInit {
  data: any;
  companyType ='medtrans';
  parserType = 'original';
  constructor(private marketplaceService: MarketplaceServiceService, private router: Router) { }
  ngOnInit() {
  }
  fileProgress(fileInput: any) {
    let fileData = <File>fileInput.target.files[0];
    this.marketplaceService.postScrap(fileData, this.companyType, this.parserType).subscribe(res => {
      console.log(res);
      this.router.navigate(['/marketplace/manifest']);
    })
  }
}

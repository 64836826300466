import { Component, OnInit, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { FacilityTripsService } from '../../../services/facility-trips/facility-trips.service';
import { MarketplaceNotificationsService } from '../../../services/marketplaceNotifications/marketplace-notifications.service';
import { SocketService } from '../../../services/socket/socket.service';
import { DynamicMenuService } from './dynamic-menu-service/dynamic-menu.service';
import { AuthService } from '../../../services/auth/auth.service';
import { SignupRequestsService } from 'src/app/services/signup-requests/signup-requests.service';
import { SharedDataService } from 'src/app/services/shared/shared-data.service';
import { NavigationStart, Router } from '@angular/router';
import { appAlert } from 'src/app/utils/utils.common';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})

export class SidebarComponent implements OnInit {

  @ViewChild('audioOption', {static: true}) audioPlayerRef: ElementRef;
  user;
  trip;
  menu;
  userPlatform: any = 'placeholder-sidenav';
  signupRequest;

  constructor(private facilityTripsService: FacilityTripsService,
    private marketplaceNotificationsService: MarketplaceNotificationsService,
    private socketService: SocketService,
    private auth: AuthService,
    private renderer: Renderer2,
    private menuService: DynamicMenuService,
    private signupRequestService: SignupRequestsService,
    private sharedService: SharedDataService,
    private router: Router
  ) {
  }

  ngOnInit() {
    this.sharedService.getUser().subscribe(data=>{
      this.user = data;
    })
    this.auth.getUserProfile().subscribe((data: any) => {
      if (data && data.success === true) {
        this.userPlatform = data.user.platform;
        // console.log('Dashboard -> userPlatform:', this.userPlatform);
        this.menu = this.menuService.getMenu(data.user.platform, data.user.roleType);
        // console.log('Main List >>> ', JSON.stringify(this.menu));
        this.addJsToElement('../../../../assets/js/jquery.app.js').onload = () => {
        };
      }
    });
    // console.log('here');
    this.getCount('Facility Trips');
    this.getCount('Signup Requets');
    this.marketplaceNotificationsService.getNotification().subscribe(data => {
      // console.log(data);
      if (data) {
        this.getCount('Facility Trips');
        this.getCount('Signup Requets');
        this.onAudioPlay();
      }
    });

    this.socketService.getNotification().subscribe(data => {
      // console.log(data);
      if (data) {
        this.getCount('Facility Trips');
        this.getCount('Signup Requets');
        this.onAudioPlay();
      }
    });


    this.sharedService.signupRequestsListener().subscribe(data => {
      // console.log('shared service compo',data);
      if (data) {
        this.getCount('Signup Requets');
        this.onAudioPlay();
      }
    });
  }

  addJsToElement(src: string): HTMLScriptElement {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = src;
    this.renderer.appendChild(document.body, script);
    return script;
  }

  //getTripsCount
  getCount(title) {
    // if (title !== 'Facility Trips') {
    //   return;
    // }

    if (title === 'Facility Trips') {
      this.facilityTripsService.getTripsCount()
        .subscribe(data => {
          this.trip = data;
        });
    }

    if (title === 'Signup Requets') {
      this.signupRequestService.getSignupRequestsCount()
        .subscribe(data => {
          this.signupRequest = data;
        });
    }
  }

  getSinupRequestStatusCount(itemTitle, itemSubTitle, signupRequest) {
    if (!signupRequest || itemTitle !== 'Signup Requets') {
      return;
    }
    let counter = '0';
    if (itemSubTitle === 'Inprogress') {
      counter = signupRequest.inprogress;
    } else if (itemSubTitle === 'Document Review') {
      counter = signupRequest.review;
    } else if (itemSubTitle === 'Approved') {
      counter = signupRequest.approved;
    }

    return counter;
  }


  getCounterText(itemTitle, itemSubTitle, trips) {
    // console.log('getCounterText', itemTitle, itemSubTitle, trips);
    if (!trips || itemTitle !== 'Facility Trips') {
      return;
    }
    let counter = '0';
    if (itemSubTitle === 'Outstanding') {
      counter = trips.outstandingTrips;
    } else if (itemSubTitle === 'Offered') {
      counter = trips.offeredTrips;
    } else if (itemSubTitle === 'Accepted') {
      counter = trips.acceptedTrips;
    } else if (itemSubTitle === 'Rejected') {
      counter = trips.rejectedTrips;
    } else if (itemSubTitle === 'In Progress') {
      counter = trips.inprogressTrips;
    } else if (itemSubTitle === 'Finished') {
      counter = trips.finishedTrips;
    } else if (itemSubTitle === 'Cancelled') {
      counter = trips.cancelledTrips;
    } else if (itemSubTitle === 'Authorization') {
      counter = trips.authorizationTrips;
    }
    // console.log(counter);
    return counter;
  }

  onAudioPlay() {
    this.audioPlayerRef.nativeElement.play();
  }


  sortMethod(a, b) {
    return a.value.position > b.value.position ? 1 : -1;
  }

  signout() {
    // swal({
    //   title: "Alert!",
    //   text: "Are you sure you want to proceed?",
    //   icon: "warning",
    //   buttons: [
    //     'No',
    //     'Yes'
    //   ],
    //   dangerMode: false,
    // }).then((isConfirm) => {
    //   if (isConfirm) {
    //     localStorage.removeItem("token");
    //     this.sharedService.updateLoginStatus(false);
    //     this.socketService.disconnectSocket();
    //     this.router.navigate(["/login"]);
    //   }
    // });

    appAlert({
      title: "Alert!",
      text: "Are you sure you want to proceed?",
      icon: "warning",
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      showCancelButton: true
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.removeItem("token");
        this.sharedService.updateLoginStatus(false);
        this.socketService.disconnectSocket();
        this.router.navigate(["/login"]);
      }
    });
  }

  openProfile(){
    this.router.navigate(["/profile"]);
  }

  // getActivatedRoute() {
  //   this.router.events.subscribe(event => {
  //     if (event instanceof NavigationStart) {
  //       console.log('Navigation In Sidemenu', event.url);
  //       // if (event.url === '/login' || event.url === '/reset/password') {
  //       // }
  //     }
  //   });
  // }
}

import { Observable, Subject } from 'rxjs';
export class FileSnippet {
  constructor(public src: string, public file: File) {}
}
export function imageFileProcessing(imageInput): Observable<any> {
  const file: File = imageInput.files[0];
  const reader = new FileReader();

  const selectedFileObserver = new Subject<any>();
  reader.addEventListener('load', (event: any) => {
    const selectedFile = new FileSnippet(event.target.result, file);
    if (
      selectedFile.file.type === 'image/jpeg' ||
      selectedFile.file.type === 'image/jpg' ||
      selectedFile.file.type === 'image/gif' ||
      selectedFile.file.type === 'image/png'
    ) {
      if (selectedFile.file.size <= 10 * 1000000) {
        selectedFileObserver.next(selectedFile);
      } else {
        imageInput.value = '';
        selectedFileObserver.next(null);

        // sweetAlert('Error', "Image size must be less than 10 MB", 'error', 'Ok');
      }
    } else {
      imageInput.value = '';
      selectedFileObserver.next(null);

      // sweetAlert('Error', "Image type unsupported", 'error', 'Ok');
    }
  });
  if (file && file.type && file.type.indexOf('image') !== -1) {
    reader.readAsDataURL(file);
  }
  
  return selectedFileObserver;
}

export function fileProcessing(imageInput): Observable<any> {
  const file: File = imageInput.files[0];
  const reader = new FileReader();

  const selectedFileObserver = new Subject<any>();
  reader.addEventListener('load', (event: any) => {
    const selectedFile = new FileSnippet(event.target.result, file);
    selectedFileObserver.next(selectedFile);
  });
  reader.readAsDataURL(file);
  return selectedFileObserver;
}

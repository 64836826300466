import { Component, OnInit, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { SharedDataService } from 'src/app/services/shared/shared-data.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { showHeading } from 'src/app/utils/global-constants';
import { appAlert } from 'src/app/utils/utils.common';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit, AfterViewInit {
  user;
  updateForm: FormGroup;
  submitted = false;
  message: any;
  message2: any;
  message3: any;
  fileToUpload: any;
  mismatch: any;
  address: any;
  profileImageUrl: any;

  @ViewChild('fileInput', {static: true}) el:ElementRef;
  @ViewChild('password', {static: true}) pass:ElementRef;
  @ViewChild('cpassword', {static: true}) cpass:ElementRef;
  @ViewChild('address', {static: true}) inputAddress: any;

  showHeading = showHeading;
  selectedTimeZone = 'America/New_York';

  constructor(private formBuilder: FormBuilder, private authService: AuthService, private sharedData: SharedDataService) {
    // console.log("in here");
  }
  ngAfterViewInit(): void {
    this.inputAddress.addresstext.nativeElement.innerText = "Tony Stark";
    // console.log(this.inputAddress);
    // this.getUsers();
  }
  ngOnInit() {

    this.updateForm = this.formBuilder.group({
      email: ['', [Validators.required,Validators.email]],
      address: [''],
      contactNumber: ['', Validators.required],
      displayName: ['', Validators.required],
      password:[''],
      cpassword:[''],
      timeZone:['', Validators.required]
    });

    setTimeout(() => {
      this.getUsers();
    }, 3000);
  }
  getUsers(){
    this.sharedData.getUser().subscribe(data=>{
      this.user = data;
      this.updateForm.patchValue(this.user)
      this.profileImageUrl = this.user.profileImageURL;
      this.inputAddress.addresstext.nativeElement.value = this.user.address
       console.log( this.inputAddress.addresstext.nativeElement.value );
      console.log(this.user);
      this.selectedTimeZone = data.timeZone;
    });
  }
  updateProfile(user){
    this.submitted = false;
    console.log(user);
    delete this.updateForm.value.cpassword;
    if(!this.updateForm.value.password){
      delete this.updateForm.value.password;
    }
    this.authService.updateProfile(user).subscribe( (response:any) =>{
      if(response.success) {
        appAlert({
          icon: "success",
          title: "Profile Updated Successfully",
          text: "Changes to your profile are successfully made "
        });
      }
      this.user = response.user ;
      this.sharedData.updateUser(response.user);
    });
  }
  fileUpload() {
    this.fileToUpload = this.el.nativeElement.files[0];
    this.authService.postFile(this.fileToUpload).subscribe( (data:any) => {
      this.sharedData.updateUser(data.user);
      }, error => {
        console.log(error);
      });
  }
  get form() {
    return this.updateForm.controls;
  }
  onSubmit() {
    this.submitted = true;
    this.updateForm.value.address = this.address;
    if (this.updateForm.invalid ) {
      return;
    }
    if(this.updateForm.value.password !== this.updateForm.value.cpassword){
      this.mismatch = true;
      return;
    }
    this.updateProfile(this.updateForm.value);
  }
  getAddress(place) {
    this.address = place.formatted_address;
    if(place.formatted_address) {
      this.updateForm.value.address = place.formatted_address;
      this.address = place.formatted_address;
    }
  }
  togglePassword(){
    this.pass.nativeElement.type === 'text' ? this.pass.nativeElement.type = 'password' : this.pass.nativeElement.type = 'text' ;
  }
  toggleCPassword(){
    this.cpass.nativeElement.type === 'text' ? this.cpass.nativeElement.type = 'password' : this.cpass.nativeElement.type = 'text' ;
  }

  changeTimezone(event: any) {
    this.updateForm.patchValue({ timeZone: event });
  }
}

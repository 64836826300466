import { AfterViewInit, Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { OfferedFaresService } from 'src/app/services/offered-fares/offered-fares.service';
import { SharedDataService } from 'src/app/services/shared/shared-data.service';
import { showHeading } from 'src/app/utils/global-constants';
import { getBrokersList } from 'src/app/utils/utils.common';

@Component({
  selector: 'app-new-offered-fare',
  templateUrl: './new-offered-fare.component.html',
  styleUrls: ['./new-offered-fare.component.css']
})
export class NewOfferedFareComponent implements OnInit {
  signuprequestId: string;
  platform: string;
  companyFares: any;
  index: number;
  vehicleTypes = ['Standard', 'Premium', 'SUV', 'WAV', 'Stretcher'];
  isEditingFare: boolean = false;
  isEditingZone: boolean = false;
  zoneIndex: number = -1;

  fareForm: FormGroup;
  public zoneFares: FormArray;
  fareFormSubmitted: boolean = false;
  public newZoneFare = {
    fare: null,
    from: null,
    to: null
  }
  zoneFareSubmitted: boolean = false;
  user: any;
  companyTypes = getBrokersList;
  showHeading = showHeading;
  constructor(private fb: FormBuilder,
    private offeredFaresService: OfferedFaresService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private sharedData: SharedDataService) {
    this.getUsers();
  }

  ngOnInit() {

    this.fareForm = this.fb.group({
      _id: [''],
      vehicleType: ['Standard', Validators.required],
      pickupSurcharge: this.fb.group({
        fare: [null, Validators.required],
        per: [null, Validators.required],
        after: [null, Validators.required]
      }),
      baseFare: this.fb.group({
        fare: [null, Validators.required],
        for: [null, Validators.required]
      }),
      zones: this.fb.array([]),
      perMile: [null, Validators.required],
      minimumFare: [null, Validators.required],
      cancelCharges: [null, Validators.required],
      perMin: [null, Validators.required],
      waitTime: this.fb.group({
        fare: [null, Validators.required],
        per: [null, Validators.required],
        after: [null, Validators.required]
      }),
      additionalPassengerCharges: [null, Validators.required],
      company: [null, Validators.required],
      companyType: [null, Validators.required],
      for: ['broker', Validators.required]
    });

    this.zoneFares = this.fareForm.get("zones") as FormArray;

    this.signuprequestId = this.activatedRoute.snapshot.paramMap.get('signuprequestId');

    if (this.router.url.indexOf('edit') > -1) {
      this.isEditingFare = true;
      this.offeredFaresService
        .getOfferedFareByID(this.signuprequestId)
        .subscribe(data => {
          console.log('GET FARE BY ID : ', data);
          this.fareForm.patchValue(data);
          const zones = data.zones;
          if (zones.length > 0) {
            zones.forEach(zoneFare => {
              this.zoneFares.push(this.fb.group({ ...zoneFare }));
            });
          }
        });
    } else {
      // console.log('This current user logged in', this.user);
      this.fareForm.patchValue({
        company: this.signuprequestId,
        companyType: this.user.platform
      });
    }
  }

  getUsers() {
    this.sharedData.getUser().subscribe(user => {
      this.user = user;
      // console.log('This current user logged in', this.user);
    });
  }

  validateNumber(event) {
    const keyCode = event.keyCode;
    const excludedKeys = [8, 37, 39, 46, 190];
    if (!((keyCode >= 48 && keyCode <= 57) ||
      (keyCode >= 96 && keyCode <= 105) ||
      (excludedKeys.includes(keyCode)))) {
      event.preventDefault();
    }
  }

  setVehicleType(type) {
    this.fareForm.patchValue({
      vehicleType: type,
    });
  }

  addVehicleType(vehicleType) {
    // const getFare = this.companyFares.find(fare => {
    //   return fare.vehicleType === vehicleType;
    // });

    // console.log('getFare', getFare);
    // if (getFare) {
    //   //  console.log(typeof this.fareForm.controls._id.value)
    //   if (getFare._id === this.fareForm.controls._id.value){
    //     console.log('Same Vehicle exists edit');
    //   } else {
    //     console.log('Vehicle already exists');
    //       swal({
    //         icon: 'info',
    //         title: '',
    //         text: 'Vehicle fare already exists.'
    //       });
    //     return false;
    //   }
    // }

    // this.fareFormSubmitted = false;
    // this.fareForm.reset();
    // if (this.zoneFares !== undefined && this.zoneFares !== null) {
    //   while (this.zoneFares.length !== 0) {
    //     this.zoneFares.removeAt(0)
    //   }
    // }
    this.fareForm.patchValue({
      vehicleType: vehicleType,
    });
    return true;
  }

  getVehicleType(type) {
    return type === this.fareForm.controls.vehicleType.value;
  }

  addZone() {
    this.zoneFareSubmitted = true;
    this.zoneFares = this.fareForm.get("zones") as FormArray;
    //const newZoneFare = this.fareForm.controls['newZone'].value;
    const t = this.newZoneFare;
    const newZoneFare = this.newZoneFare;
    let isNewZoneValid = Object.values(newZoneFare);
    if (isNewZoneValid.includes(null) || isNewZoneValid.includes("")) {
      console.log('Please provide valid value')
      return;
    }
    console.log(newZoneFare, Object.values(newZoneFare));

    this.zoneFares.push(this.fb.group({ ...newZoneFare }));
    console.log(this.zoneFares);
    this.newZoneFare.fare = null;
    this.newZoneFare.from = null;
    this.newZoneFare.to = null;
    this.zoneFareSubmitted = false;
    // let newZone = this.fb.group({
    //   fare: [newZoneFare.fare, Validators.required],
    //   from: [newZoneFare.from, Validators.required],
    //   to: [newZoneFare.to, Validators.required],
    // });

    // this.zoneFares.push(newZone);
    //this.fareForm.controls['newZone'].reset();
  }

  editZone(index) {
    this.zoneIndex = index;
    this.newZoneFare = { ...this.zoneFares.at(index).value };
    this.isEditingZone = true;
  }

  saveZone() {
    //debugger;
    this.zoneFareSubmitted = true;
    const newZoneFare = this.newZoneFare;
    let isNewZoneValid = Object.values(newZoneFare);
    this.isEditingZone = true;
    if (isNewZoneValid.includes(null) || isNewZoneValid.includes("")) {
      console.log('Please provide valid value')
      return;
    }
    this.zoneFares.at(this.zoneIndex).patchValue(this.newZoneFare);
    this.clearZone();
    this.zoneFareSubmitted = false;
  }

  clearZone() {
    this.zoneIndex = -1;
    this.newZoneFare = { fare: null, from: null, to: null };
    this.isEditingZone = false;
  }

  deleteZone(index) {
    this.zoneFares.removeAt(index);
  }

  get zones(): FormArray {
    return this.fareForm.get('zones') as FormArray;
  }

  saveFareForm() {
    this.fareFormSubmitted = true;
    const v = this.fareForm.invalid;
    console.log(this.fareForm);

    if (this.fareForm.invalid) {
      console.log('form invalid');
      return false;
    }
    this.fareForm.patchValue({ companyType: this.user.platform });
    const fare = this.fareForm.value;
    delete fare._id;

    if (!this.addVehicleType(this.fareForm.controls.vehicleType.value)) {
      return false;
    }

    console.log(fare);

    this.proceedMethod(fare)
      .subscribe((data: any) => {
        console.log('fare created: ', data);
        this.router.navigate(['/offeredfares/' + this.fareForm.controls.company.value + '/list']);
      });
  }

  proceedMethod(fare) {
    if (this.isEditingFare) {
      return this.offeredFaresService.editOfferedFareByID(this.signuprequestId, fare)
    }
    return this.offeredFaresService.createOfferedFare(fare)
  }

  get f(): any {
    return this.fareForm.controls;
  }

}

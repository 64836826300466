import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MarketplaceServiceService } from 'src/app/services/marketplace/marketplace-service.service';
import * as moment from "moment-timezone";
@Component({
  selector: 'app-marketplace-stat',
  templateUrl: './marketplace-stat.component.html',
  styleUrls: ['./marketplace-stat.component.css']
})
export class MarketplaceStatComponent implements OnInit {
  stats: any;
  totalJobs = 0;
  acceptedJobs = 0;
  pendingJobs = 0; 
  // earnedFare = 0;
  @ViewChild('date', {static: true}) date: ElementRef;
  constructor(private marketplaceService: MarketplaceServiceService) { }

  ngOnInit() {
    this.getMarketplaceStats('','');
  }
  getStats(){
    let searchedDate = this.date.nativeElement.value;
    let startTime = moment(searchedDate).startOf("day").toISOString();
    console.log(startTime);
  }
  selectRange() {
    let date = this.date.nativeElement.value;
    let i = date.indexOf("~");
    let to = date.substring(0, i).trim();
    let from = date.substring(i + 1).trim();
    let startTime = moment(to).startOf("day").toISOString();
    let endTime = moment(from).startOf("day").toISOString();
    console.log(startTime, endTime);
   this.getMarketplaceStats(startTime,endTime);
  }
  getMarketplaceStats(startTime , endTime){
    this.totalJobs = 0;
    this.acceptedJobs = 0;
    this.pendingJobs = 0;
    // this.earnedFare = 0; 
    this.marketplaceService.getMarketPlaceStats(startTime,endTime).subscribe((data:any)=>{
      this.stats = data.data;
      console.log(this.stats);
      this.stats.map(data =>{
        this.totalJobs += data.totalJobs;
        this.acceptedJobs += data.acceptedJobs;
        this.pendingJobs += data.pendingJobs;
        // this.earnedFare = data.totalSpecialRate; 
      })
    })
  };
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import * as moment from "moment-timezone";
import { Observable } from "rxjs";
import { TripSchema } from "./trip.model";
import { urls } from "src/app/utils/url-utils";
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FacilityTripsService {

  url = urls.BASE_URL;
  scheduleTime: any;
  constructor(private http: HttpClient) {
    this.scheduleTime = moment()
      .startOf("day")
      .toISOString();
   }

  getAllFacilityTrips(search) {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("token")
    });
    let options = { headers: authHeader, params: search };
    // console.log(search)
    return this.http.get(this.url + "facilitytrips", options);
  }

  getEditTripDetail(tripId): Observable<TripSchema> {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("token")
    });
    // console.log(tripId);
    // console.log("JWT " + localStorage.getItem("token"))
    let options = { headers: authHeader };
    return this.http.get(this.url + "assigns/singleTrip/" + tripId, options);
    // return this.http.get(this.url + "dispatches/" + tripId, options );
  }

  getCompanies() {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("token")
    });
    let options = { headers: authHeader };
    return this.http.get(this.url + 'quarter/companies/active', options);
  }

  createTrip(assigns) {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("token")
    });
    let options = { headers: authHeader };
    console.log(assigns)
    let obj = assigns;
    obj.priorityClient = {
      displayName: assigns.displayName,
      clientId: assigns.clientId,
      cellPhone: assigns.cellPhone,
      contactNumber: assigns.contactNumber
    };
    console.log(obj.priorityClient);
    console.log(assigns.displayName);
    console.log(assigns.contactNumber);
    delete obj.displayName;
    delete obj.clientId;
    delete obj.cellPhone;
    delete obj.contactNumber;
    // console.log(obj);
    return this.http.post(this.url + "facilitytrips", obj, options);
  }

  getTripDetail(tripId) {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("token")
    });

    let options = { headers: authHeader };
    return this.http.get(this.url + "facilitytrips/" + tripId, options);
  }

  assignFacilityTrip(assignId,data) {
    // let obj: any = {
    //   company
    // };
    // if (rate) {
    //   obj.preSpecialRate = rate;
    // }
    // if (companyNote) {
    //   obj.companyNote = companyNote;
    // }
    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("token")
    });

    let options = { headers: authHeader };
    return this.http.put(this.url + "facilitytrips/" + assignId, data , options);
  }
  postEditTrip(assignId, assigns) {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("token")
    });
    let options = { headers: authHeader };
    console.log(assigns)
    let obj = assigns;
    obj.priorityClient = {
      displayName: assigns.displayName,
      clientId: assigns.clientId,
      cellPhone: assigns.cellPhone,
      contactNumber: assigns.contactNumber
    };
    delete obj.displayName;
    delete obj.clientId;
    delete obj.cellPhone;
    delete obj.contactNumber;
    console.log(obj);
    return this.http.post(this.url + "quarterpanels/edit/trip/" + assignId, obj, options);
  }

  cancelTrip(tripId, changeReason) {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("token")
    });
    let obj = {
      changeReason
    }
    let options = { headers: authHeader };
    console.log(options);
    return this.http.post(this.url + "facilitytrips/" + tripId, obj, options);
  }

  getTripsCount() {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("token")
    });

    let options = { headers: authHeader };
    return this.http.get(this.url + "facilitytrips/dashboard", options);
  }

  tripLogs(facilityId) {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("token")
    });
    let options = { headers: authHeader, params: { facilityId } };
    return this.http.get(this.url + 'facilitytrips/logs', options);
  }

  allTripLogs() {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("token")
    });
    let options = { headers: authHeader };
    return this.http.get(this.url + 'facilitytrips/logs', options);
  }

  distributeTrip(tripId) {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("token")
    });
    let options = { headers: authHeader };
    return this.http.get(this.url + 'distribute/' + tripId, options);
  }



  parseTrips(data,token) {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + token
    });
    let options = { headers: authHeader };
    const formData = new FormData();
    formData.append('uploadExcel', data);
    return this.http.post(this.url + 'facilitytrips/parse', formData, options);
  }

  approveAuthorizedTrip(tripId, data) {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("token")
    });
    let options = { headers: authHeader };
    return this.http.put(this.url + "tripauthorization/" + tripId, data, options);
  }

}

import { Component, OnInit } from '@angular/core';
import { AssignedTripsService } from 'src/app/services/assigned-trips/assigned-trips.service';
import { CompanyService } from 'src/app/services/company/company.service';

@Component({
  selector: 'app-assigned-trips',
  templateUrl: './assigned-trips.component.html',
  styleUrls: ['./assigned-trips.component.css']
})
export class AssignedTripsComponent implements OnInit {
  fleets = [1,2,3,4,5];
  companies;
  searchTrips = '';
  trips;
  companyId = '';
  assignNotesModalObj;
  
  constructor(
    private companyService: CompanyService,
    private tripService: AssignedTripsService
  ) { }

  ngOnInit() {
    this.companyService.getAllCompanies()
      .subscribe((data) => {
        this.companies = data;
      });

    
  }

  onCompanyChange(evt) {
    this.companyId = evt.target.value;
    console.log('Company ID: ', this.companyId);
    if(this.companyId) {
      this.tripService.getTrips(this.companyId)
      .subscribe((data) => {
        console.log('Trips: ', data);
        this.trips = data;
      });
    } else {
      this.trips = [];
    }
  }

  setModalNotesContent(tripDetail) {
    console.log("Notes Modal");
    this.assignNotesModalObj = tripDetail;
  }

}

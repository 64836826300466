import { Injectable } from "@angular/core";
import { HttpHeaders } from "@angular/common/http";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { SharedDataService } from "../shared/shared-data.service";
import {Observable} from 'rxjs';
import { urls } from "src/app/utils/url-utils";
import { appAlert } from "src/app/utils/utils.common";

@Injectable({
  providedIn: "root"
})
export class AuthService {
  url = urls.BASE_URL;

  constructor(
    private http: HttpClient,
    private router: Router,
    private sharedData: SharedDataService
  ) {}
  signin(credentials) {
    this.http
      .post(this.url + "auth/signin", credentials)
      .subscribe((data: any) => {
        if (data && data.success === true) {
          localStorage.setItem("token", data.token);
          this.sharedData.updateLoginStatus(true);
          this.sharedData.updateUser(data.user);
          this.router.navigate(["/"]);
        } else if (data && data.success === false) {
          appAlert({
            icon: "error",
            title: "Invalid Credentials",
            text: data.message
          });
        } else {
          appAlert({
            icon: "error",
            title: "Something Went Wrong",
            text: "Please make sure you have active internet Connection"
          });
        }
      });
  }
  me() {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("token")
    });
    let options = { headers: authHeader };
    return this.http
      .get(this.url + "users/me", options)
      .subscribe((data: any) => {
        if (data && data.success === true) {
          this.sharedData.updateLoginStatus(true);
          this.sharedData.updateUser(data.user);
          // this.router.navigate(["/"]);
        }
      });
  }
  getUserProfile(): Observable<any> {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("token")
    });
    let options = { headers: authHeader };
    return this.http
      .get(this.url + "users/me", options);

  }


  updateProfile (user){
    let userHeaders = new HttpHeaders({
      'Authorization': 'JWT ' + localStorage.getItem("token")
    });
    let options = { headers: userHeaders };
    // console.log(user)
    return this.http.put(this.url + 'users', user, options);
  }
  postFile(fileToUpload: File) {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("token")
    });
    let options = { headers: authHeader };
    const formData: FormData = new FormData();
    formData.append('newProfilePicture', fileToUpload, fileToUpload.name);
    return this.http.post(this.url+'users/picture', formData, options);
}
  reset(credentials) {
    return this.http
      .post(this.url + "auth/forgot", credentials)
      .subscribe((data: any) => {
        if (data && data.success === true) {
          appAlert({
            icon: "success",
            title: "Reset Password Successfully",
            text: data.message
          });
          this.router.navigate(["/login"]);
        } else {
          // console.log(data);
          appAlert({
            icon: "error",
            title: "Something went wrong!",
            text: data.message
          });
          this.router.navigate(["/login"]);
        }
      });
  }

  verifyToken(): Observable<any> {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("token")
    });
    let options = { headers: authHeader };
    return this.http.get<any>(this.url + "users/me", options);
  }
}

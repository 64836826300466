import { Component, OnInit, Input } from '@angular/core';
declare var $: any;
@Component({
  selector: 'app-company-note-modal',
  templateUrl: './company-note-modal.component.html',
  styleUrls: ['./company-note-modal.component.css']
})
export class CompanyNoteModalComponent implements OnInit {

  @Input() assignNotesModalObj: any;
  constructor() { }

  ngOnInit() {
    console.log(this.assignNotesModalObj)
  
  }

}

import { Component, OnInit } from '@angular/core';
import { showHeading } from 'src/app/utils/global-constants';

@Component({
  selector: 'app-standing-order',
  templateUrl: './standing-order.component.html',
  styleUrls: ['./standing-order.component.css']
})
export class StandingOrderComponent implements OnInit {
  showHeading = showHeading;
  search = '';
  standingOrders = [1,2,3,4,5,6,7];


  constructor() { }

  ngOnInit() {
  }

}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { InviteService } from 'src/app/services/invite/invite.service';
import { showHeading } from 'src/app/utils/global-constants';

@Component({
  selector: 'app-invite-companies',
  templateUrl: './invite-companies.component.html',
  styleUrls: ['./invite-companies.component.css']
})
export class InviteCompaniesComponent implements OnInit {
  inviteForm;
  inviteList = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'I', 'J', 'I', 'J'];
  //inviteList = [];
  inviteSearch = ''; 
  message = "Invite more providers to join NEMT Routes. You may enter multiple emails using comma separation.";
  submitted = false;
  // ^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$
  showHeading = showHeading;
  constructor(private formBuilder: FormBuilder,
    private inviteService: InviteService) { }

  ngOnInit() {
    this.inviteForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern('^([A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[A-Za-z]{2,4},?)+$')]]
    });

    this.inviteService.getInviteList().subscribe((data) => {
      this.inviteList = data;
    });
  }

  onSubmit() {
    console.log(this.inviteForm.value);
    // this.inviteService.sendInvite(this.inviteForm.value.email)
    //   .subscribe((data) => {
    //     // Call Get Invite List
    //     this.inviteForm.reset();
    //   });
    this.submitted = true;

    if (this.inviteForm.invalid) {
      console.log('INVALID', this.inviteForm.value);
      return false;
    }

    this.submitted = false;

    this.inviteForm.reset();
  }

  clearSearch() {
    this.inviteSearch = '';
  }

  get form() {
    return this.inviteForm.controls;
  }
}

import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { INgxSelectOption } from 'ngx-select-ex';
import { MarketplaceServiceService } from 'src/app/services/marketplace/marketplace-service.service';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { AssignService } from 'src/app/services/assign/assign.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from "@angular/common";
import * as moment from "moment-timezone";
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { SharedDataService } from "src/app/services/shared/shared-data.service";
import { DateTimeAdapter } from 'ng-pick-datetime';
import { ToastrService } from 'ngx-toastr';
declare var $: any;
declare var _: any;
@Component({
  selector: 'app-manifest',
  templateUrl: './manifest.component.html',
  styleUrls: ['./manifest.component.css']
})
export class ManifestComponent implements OnInit {
  @ViewChild("date", {static: true}) date_v: ElementRef;
  @ViewChild('pickupElem', {static: true}) pickupElem: any;
  @ViewChild('dropoffElem', {static: true}) dropoffElem: any;
  datePipeEn = new DatePipe("en-US");
  manifesttype: any;
  trips: any;
  companies: any;
  selectedCompany: any;
  index = -1;
  selectedCompanyTitle = '';
  assignTrips: any;
  filterMile = 0;
  toAssign: any;
  preSpecialRate: any;
  companyNote: any;
  tripFilter: any;
  companyFilter: any;
  dateFilter: any;
  scheduleTime: any;
  tripForm: FormGroup;
  currentUser: any;
  constructor(
    dateTimeAdapter: DateTimeAdapter<any>,
    private sharedData: SharedDataService,
    private formBuilder: FormBuilder,
    private marketPlaceService: MarketplaceServiceService,
    public ngxSmartModalService: NgxSmartModalService,
    private assignService: AssignService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private toastr: ToastrService
  ) {
    dateTimeAdapter.setLocale("en-US");
    this.sharedData.getUser().subscribe(data => {
      this.currentUser = data;
      // console.log(data);
      //  dateTimeAdapter.setLocale("en-US");
    });

  }
  assignId: any;
  ngOnInit() {


    this.tripForm = this.formBuilder.group({
      priorityClient: this.formBuilder.group({ // make a nested group
        displayName: ['', Validators.required],
        contactNumber: ['', Validators.required]
      }),
      appointmentTime: [],
      // displayName: ['', Validators.required],
      // contactNumber: ['', Validators.required],
      jobOriginAddress: ['', Validators.required],
      jobOriginLatitude: [''],
      jobOriginLongitude: [''],
      jobDestinationAddress: ['', Validators.required],
      jobDestinationLatitude: [''],
      jobDestinationLongitude: [''],
      companyNote: [],
      tripId: [''],
      tripRequirement: ['', Validators.required],
      scheduleTime: ['', Validators.required],
    });
    this.activatedRoute.params.subscribe(
      params => {
        this.manifesttype = params['manifest'];
        console.log('Categrory --- ', this.manifesttype);
        this.getTrips(true);
      }
    );
    // this.dateFilter = moment().startOf("day").toISOString();
  }
  searchDateFilter() {
    this.dateFilter = this.datePipeEn.transform(
      this.date_v.nativeElement.value,
      "yyyy-MM-dd"
    );
    this.scheduleTime = moment(this.dateFilter).startOf("day").toISOString();
    this.getTrips(true);
  }
  clearDateFilter() {
    this.dateFilter = undefined;//  moment().startOf("day").toISOString();
    this.scheduleTime = undefined;
    this.getTrips(true);
  }
  getTrips(status) {
    this.marketPlaceService.getMarketPlace(status, this.manifesttype, this.scheduleTime).subscribe((data: any) => {
      //console.log(data);
      this.assignTrips = data;
      this.trips = data;
    });
  }
  saveCompany(id, rate, companyNote) {
    this.assignId = id;
    //console.log(rate);
    //console.log(this.assignId, this.selectCompany)
    this.assignService.updateMarketPlaceCompany(this.assignId, this.selectedCompany, rate, companyNote, null, false)
      .subscribe((data: any) => {
        console.log('updateMarketPlaceCompany', data);
        // $.Notification.autoHideNotify('success', 'bottom left', 'Company Assigned', 'company assigned successfully. ');
        this.toastr.success('Company assigned successfully.','Company Assigned');
        this.assignId = '';
        if (!this.trips[this.index]['company']) {
          this.trips[this.index]['company'] = {};
        }

        this.trips.splice(this.index, 1);
        this.selectedCompanyTitle = ''
        this.selectedCompany = '';
        this.companyNote = undefined;
        this.preSpecialRate = undefined;
        this.index = -1
        //console.log(data);
        this.getTrips(true);
      });
    this.ngxSmartModalService.get('myModal').close();
  }
  openModal(index, assign) {
    this.selectedCompany = '';
    this.companyNote = undefined;
    this.preSpecialRate = undefined;
    this.index = index;
    //console.log(this.index, assign);
    this.toAssign = assign;
    this.assignId = assign._id
    this.assignService.nearByCompany(assign.jobOriginLatitude, assign.jobOriginLongitude).subscribe((data: any) => {
      if (data.success) {
        this.companies = data.companies;
        this.companies = _.sortBy(this.companies, 'text');
        //console.log(this.companies);
      }
    });
    this.ngxSmartModalService.getModal('myModal').open()
  }
  doSelectionChanges(event: INgxSelectOption) {
    if (event[0]) {
      this.selectedCompanyTitle = event[0].text;
    }
  }
  selectCompany(companyId) {
    this.selectedCompany = companyId;
    //console.log(this.selectedCompany)
  }
  milesFilter() {
    //console.log(this.filterMile)
    if (this.filterMile > 0) {
      if (this.filterMile <= 15) {
        this.trips = this.assignTrips.filter(element => {
          return (element.milage < this.filterMile && element.milage >= this.filterMile - 5)
        });
      } else {
        this.trips = this.assignTrips.filter(element => {
          return element.milage >= this.filterMile
        });
      }

    } else {
      this.trips = this.assignTrips;
    }
  }
  calculateEstimatedFare() {
    //console.log(this.selectedCompany)
    this.marketPlaceService.calculateEstimatedFare(this.assignId, this.selectedCompany).subscribe((data: any) => {
      if (data.success === true) {
        this.preSpecialRate = data.totalFare;
      }
      //console.log(data);
    });
  }
  /*
  * Form Validation
  */
  get form() { return this.tripForm.controls };

  /**
   * Update Modal
   */
  openUpdateModal(index, assign) {
    console.log(assign.scheduleTime);
    console.log(moment.tz(assign.scheduleTime, 'Asia/Karachi').format())
    console.log(moment.tz(assign.scheduleTime, 'America/New_York').format())
    // return false;
    this.index = index;
    console.log(this.index, assign);
    this.toAssign = assign;
    this.assignId = assign._id;
    this.tripForm.patchValue({
      ...assign,
      scheduleTime: moment.tz(assign.scheduleTime, this.currentUser.timeZone).format('YYYY-MM-DDTHH:mm:ss'),
      appointmentTime: moment.tz(assign.appointmentTime, this.currentUser.timeZone).format('YYYY-MM-DDTHH:mm:ss')
    });
    // this.tripForm.get('scheduleTime').setValue(moment.tz(assign.scheduleTime, 'America/New_York').format('YYYY-MM-DDTHH:mm:ss'));
    this.pickupElem.addresstext.nativeElement.value = assign.jobOriginAddress;
    this.dropoffElem.addresstext.nativeElement.value = assign.jobDestinationAddress;
    this.ngxSmartModalService.getModal('myUpdateModal').open()
  }

  /**
   * Get Address
   */
  getAddress(input, $event) {

    if (input === 'pickup') {
      this.tripForm.get('jobOriginAddress').setValue($event.formatted_address);
      this.tripForm.get('jobOriginLatitude').setValue($event.geometry.location.lat());
      this.tripForm.get('jobOriginLongitude').setValue($event.geometry.location.lng());
    }
    if (input === 'dropoff') {
      this.tripForm.get('jobDestinationAddress').setValue($event.formatted_address);
      this.tripForm.get('jobDestinationLatitude').setValue($event.geometry.location.lat());
      this.tripForm.get('jobDestinationLongitude').setValue($event.geometry.location.lng());
    }
  }
  onUpdateSubmit() {
    console.log('Form Subbmit')
    console.log(this.tripForm.value);
    this.tripForm.value.scheduleTime = moment.tz(moment(this.tripForm.value.scheduleTime).format('YYYY-MM-DDTHH:mm:ss'), this.currentUser.timeZone).toISOString();
    this.tripForm.value.appointmentTime = moment.tz(moment(this.tripForm.value.appointmentTime).format('YYYY-MM-DDTHH:mm:ss'), this.currentUser.timeZone).toISOString();
    this.assignService.updateManifestTrip(this.assignId, this.tripForm.value)
      .subscribe(response => {
        console.log('Response of Form submit --- ', response);
        if (response) {
          // let index = this.trips.map(e => e._id.toString()).indexOf(this.assignId.toString());
          // console.log(index, this.index);
          // if (index > -1) {
          //   this.trips[this.index] = response;
          // }
          this.getTrips(true);
        }
        this.ngxSmartModalService.getModal('myUpdateModal').close()
      });
  }

}

/// <reference types="@types/googlemaps" />
import { Component, ViewChild, EventEmitter, Output, OnInit, AfterViewInit, Input } from '@angular/core';
import { MapsAPILoader } from '@agm/core';
@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.css']
})
export class AutocompleteComponent implements OnInit, AfterViewInit {
  @Input() adressType: string;
  @Output() setAddress: EventEmitter<any> = new EventEmitter();
  @ViewChild('addresstext', {static: true}) addresstext: any;

  autocompleteInput: string;
  queryWait: boolean;

  constructor(private mapsAPILoader: MapsAPILoader) {
  }
  ngOnInit() {
  }
  ngAfterViewInit() {
    this.getPlaceAutocomplete();
  }
  private getPlaceAutocomplete() {
    this.mapsAPILoader.load().then(
      () => {
        const autocomplete = new google.maps.places.Autocomplete(this.addresstext.nativeElement,
          {
            types: []  // 'establishment' / 'address' / 'geocode'
          });
        google.maps.event.addListener(autocomplete, 'place_changed', () => {
          const place = autocomplete.getPlace();
          this.invokeEvent(place);
        });
      });
  }
  invokeEvent(place: Object) {
    this.setAddress.emit(place);
  }
}
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { FileSnippet } from "src/app/utils/image-processing-utils";
import { setFormData } from "src/app/utils/network-utils";
import { urls } from "src/app/utils/url-utils";

@Injectable({
  providedIn: "root",
})
export class FleetService {
  url = urls.BASE_URL;

  constructor(private http: HttpClient) {}

  getFleets(status) {
    let authHeader = new HttpHeaders({
      authorization:
        "JWT " +
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjoiNWZjMDk0YWIyN2Q1NmUyODYxNWY2NzRkIiwiYWNjb3VudFN0YXR1cyI6ImFjdGl2ZSIsInJvbGVzIjoiY29tcGFueSIsImlhdCI6MTYxNzI1NDU1Mn0.8jnQPjveHFqM7GCXrRopTOdxo-keq-n0JLNTuZUG0Ds",
    });

    // let authHeader = new HttpHeaders({
    //   authorization: "JWT " + localStorage.getItem("token")
    // });

    let options = { headers: authHeader };
    return this.http.get(this.url + "fleets?status=" + status, options);
  }

  getCompanyFleets(status) {
    let authHeader = new HttpHeaders({
      authorization:
        "JWT " +
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjoiNWZjMDk0YWIyN2Q1NmUyODYxNWY2NzRkIiwiYWNjb3VudFN0YXR1cyI6ImFjdGl2ZSIsInJvbGVzIjoiY29tcGFueSIsImlhdCI6MTYxNzI1NDU1Mn0.8jnQPjveHFqM7GCXrRopTOdxo-keq-n0JLNTuZUG0Ds",
    });

    // let authHeader = new HttpHeaders({
    //   authorization: "JWT " + localStorage.getItem("token")
    // });

    let options = { headers: authHeader };
    return this.http.get(
      this.url + "fleets?isFleetSection=true&status=" + status,
      options
    );
  }

  getFleetById(fleetId) {
    let authHeader = new HttpHeaders({
      authorization:
        "JWT " +
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjoiNWZjMDk0YWIyN2Q1NmUyODYxNWY2NzRkIiwiYWNjb3VudFN0YXR1cyI6ImFjdGl2ZSIsInJvbGVzIjoiY29tcGFueSIsImlhdCI6MTYxNzI1NDU1Mn0.8jnQPjveHFqM7GCXrRopTOdxo-keq-n0JLNTuZUG0Ds",
    });

    // let authHeader = new HttpHeaders({
    //   authorization: "JWT " + localStorage.getItem("token")
    // });

    let options = { headers: authHeader };
    return this.http.get(this.url + "fleets/" + fleetId, options);
  }

  saveFleet(fleet, fleetId) {
    let authHeader = new HttpHeaders({
      authorization:
        "JWT " +
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjoiNWZjMDk0YWIyN2Q1NmUyODYxNWY2NzRkIiwiYWNjb3VudFN0YXR1cyI6ImFjdGl2ZSIsInJvbGVzIjoiY29tcGFueSIsImlhdCI6MTYxNzI1NDU1Mn0.8jnQPjveHFqM7GCXrRopTOdxo-keq-n0JLNTuZUG0Ds",
    });

    // let authHeader = new HttpHeaders({
    //   authorization: "JWT " + localStorage.getItem("token")
    // });

    let options = { headers: authHeader };

   const formData = setFormData(fleet,  'fleet');
    if (fleetId) {
      return this.http.put(this.url + "fleets/" + fleetId, formData, options);
    } else {
      return this.http.post(this.url + "fleets", formData, options);
    }
  }

  downloadQR(fleetId) {
    let authHeader = new HttpHeaders({
      authorization:
        "JWT " +
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjoiNWZjMDk0YWIyN2Q1NmUyODYxNWY2NzRkIiwiYWNjb3VudFN0YXR1cyI6ImFjdGl2ZSIsInJvbGVzIjoiY29tcGFueSIsImlhdCI6MTYxNzI1NDU1Mn0.8jnQPjveHFqM7GCXrRopTOdxo-keq-n0JLNTuZUG0Ds",
    });

    // let authHeader = new HttpHeaders({
    //   authorization: "JWT " + localStorage.getItem("token")
    // });

    let options = { headers: authHeader };
    return this.http.get(this.url + "fleets/generateqr/" + fleetId, options);
  }
}

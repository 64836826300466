import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
// import { ResponseContentType } from "@angular/http";
import * as moment from "moment-timezone";
import { Observable } from "rxjs";
import { TripSchema } from "./trip.model";
import { urls } from "src/app/utils/url-utils";

@Injectable({
  providedIn: "root",
})
export class AssignService {
  url = urls.BASE_URL;
  scheduleTime: any;

  constructor(private http: HttpClient) {
    this.scheduleTime = moment().startOf("day").toISOString();
  }
  getTripStats(startTime = "", endTime = "") {
    let duration = {
      start: startTime,
      end: endTime,
    };
    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("token"),
    });
    let options = { headers: authHeader, params: duration };
    return this.http.get(this.url + "assigns/insurancepanelstats", options);
  }
  getTripNEMTStats(startDate, endDate, companyToken) {
    let duration = {
      startDate,
      endDate
    };
    let authHeader = new HttpHeaders({
      authorization: "JWT " + companyToken,
    });
    let options = { headers: authHeader, params: duration };
    return this.http.get(this.url + "kpis/jobs/stats", options);
  }
  getTripStatDetails(payload) {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("token"),
    });
    let options = { headers: authHeader };
    return this.http.post(
      this.url + "assigns/insurancepanelstats",
      payload,
      options
    );
  }
  getTripDetail(tripId) {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("token"),
    });
    // console.log(tripId);
    // console.log("JWT " + localStorage.getItem("token"))
    let options = { headers: authHeader };
    return this.http.get(this.url + "assigns/singleTrip/" + tripId, options);
    // return this.http.get(this.url + "dispatches/" + tripId, options );
  }
  getEditTripDetail(tripId): Observable<TripSchema> {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("token"),
    });
    // console.log(tripId);
    // console.log("JWT " + localStorage.getItem("token"))
    let options = { headers: authHeader };
    return this.http.get(this.url + "assigns/singleTrip/" + tripId, options);
    // return this.http.get(this.url + "dispatches/" + tripId, options );
  }
  getAllTrips(search) {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("token"),
    });
    let options = { headers: authHeader, params: search };
    // console.log(search)
    return this.http.get(this.url + "assigns/trips", options);
  }

  getDashboardTrips(payload) {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("token"),
    });
    let options = { headers: authHeader };
    // console.log(search)
    return this.http.post(this.url + "assigns/trips", payload, options);
  }

  sendSms(params) {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("token"),
    });
    let options = { headers: authHeader };
    return this.http.post(this.url + "trackingsms", params, options);
  }
  createTrip(assigns) {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("token"),
    });
    let options = { headers: authHeader };
    console.log(assigns);
    let obj = assigns;
    obj.priorityClient = {
      displayName: assigns.displayName,
      clientId: assigns.clientId,
      cellPhone: assigns.cellPhone,
      contactNumber: assigns.contactNumber,
    };
    console.log(obj.priorityClient);
    console.log(assigns.displayName);
    console.log(assigns.contactNumber);
    delete obj.displayName;
    delete obj.clientId;
    delete obj.cellPhone;
    delete obj.contactNumber;
    // console.log(obj);
    return this.http.post(
      this.url + "quarterpanels/trip/" + obj.company,
      obj,
      options
    );
  }
  nearByCompany(lat, lng, jobCarType?) {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("token"),
    });
    let params: any = {
      longitude: lng,
      latitude: lat,
    };

    if (jobCarType) {
      if (jobCarType === 'LIV' || jobCarType === 'AMB') {
        jobCarType = 'Standard';
      }
      params = {
        ...params,
        driverCar: jobCarType,
      };
    }

    let options = {
      headers: authHeader,
      params,
    };
    return this.http.get(this.url + "marketplace/companies/nearby", options);
  }
  nearByCompanyDefault() {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("token"),
    });
    let options = { headers: authHeader };
    return this.http.get(
      this.url + "marketplace/companies/nearbydefault",
      options
    );
  }
  getCompanies() {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("token"),
    });
    let options = { headers: authHeader };
    return this.http.get(this.url + "quarter/companies/active", options);
  }
  postEditTrip(assignId, assigns) {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("token"),
    });
    let options = { headers: authHeader };
    console.log(assigns);
    let obj = assigns;
    obj.priorityClient = {
      displayName: assigns.displayName,
      clientId: assigns.clientId,
      cellPhone: assigns.cellPhone,
      contactNumber: assigns.contactNumber,
    };
    delete obj.displayName;
    delete obj.clientId;
    delete obj.cellPhone;
    delete obj.contactNumber;
    console.log(obj);
    return this.http.post(
      this.url + "quarterpanels/edit/trip/" + assignId,
      obj,
      options
    );
  }
  updateMarketPlaceCompany(
    assignId,
    company,
    rate,
    companyNote,
    previousCompany,
    isAccepted
  ) {
    let obj: any = {
      id: assignId,
      company: company,
      isAccepted: isAccepted,
    };
    if (rate) {
      obj.preSpecialRate = rate;
    }
    if (companyNote) {
      obj.companyNote = companyNote;
    }
    if (previousCompany) {
      obj.previousCompany = previousCompany;
    }
    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("token"),
    });
    let options = { headers: authHeader };
    console.log(obj);
    return this.http.post(
      this.url + "marketplace/update/company",
      obj,
      options
    );
  }
  removeMarketPlaceCompany(assignId, previousCompany, isAccepted) {
    console.log(previousCompany);
    let obj: any = {
      id: assignId,
      previousCompany: previousCompany._id,
      isAccepted: isAccepted,
      company: null,
    };

    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("token"),
    });
    let options = { headers: authHeader };
    return this.http.post(
      this.url + "marketplace/update/company",
      obj,
      options
    );
  }
  getCompletedTrips(scheduleTime = this.scheduleTime, company) {
    var query =
      "assigns?status=completed&isShowAllTrips=false&&scheduleTime=" +
      scheduleTime;
    if (company) {
      query =
        "assigns?status=completed&isShowAllTrips=false&&scheduleTime=" +
        scheduleTime +
        "&companyType=" +
        company;
    }
    console.log("Query is ::::", this.url, query);
    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("companyToken"),
    });
    let options = { headers: authHeader };
    return this.http.get(this.url + query, options);
  }
  getAdjustedTrips(scheduleTime = this.scheduleTime, company) {
    console.log(scheduleTime);
    var query =
      "assigns?status=adjustedtriplogs&isShowAllTrips=false&jobType=assigned&scheduleTime=" +
      scheduleTime;
    if (company) {
      query =
        "assigns?status=adjustedtriplogs&isShowAllTrips=false&jobType=assigned&scheduleTime=" +
        scheduleTime +
        "&companyType=" +
        company;
    }
    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("companyToken"),
    });
    let options = { headers: authHeader };
    return this.http.get(this.url + query, options);
  }
  // getTripDetail(tripId) {
  //   let authHeader = new HttpHeaders({
  //     authorization: "JWT " + localStorage.getItem("token")
  //   });
  //   console.log("JWT " + localStorage.getItem("token"))
  //   let options = { headers: authHeader };
  //   return this.http.get(this.url + "dispatches/" + tripId);
  //   // return this.http.get(this.url + "dispatches/" + tripId, options );
  // }
  getCompanyToken(company) {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("token"),
    });
    let options = { headers: authHeader };
    return this.http.get(this.url + "getCompanyToken/" + company, options);
  }
  AdjustTrips(jobId, job) {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("token"),
    });
    let options = { headers: authHeader };
    return this.http.put(this.url + "jobs/adjustment/" + jobId, job, options);
  }
  getBankingReport(obj) {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("companyToken"),
    });
    let options = { headers: authHeader };
    return this.http.post(this.url + "assigns/bankingreport", obj, options);
  }
  getDrivers(companyId) {
    console.log("here");
    companyId = localStorage.getItem("companyID");
    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("token"),
    });
    let options = { headers: authHeader };
    return this.http.get(this.url + "drivers/companies/" + companyId, options);
  }
  getClaimReport(obj) {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("companyToken"),
    });
    let options = { headers: authHeader };
    return this.http.post(this.url + "assigns/claimfile", obj, options);
  }
  getTripByJob(jobId) {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("token"),
    });
    let options = { headers: authHeader };
    return this.http.get(this.url + "jobs/" + jobId, options);
  }
  getAmbPostPDF(assignId) {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("companyToken"),
    });
    let options = { headers: authHeader };
    return this.http.post(this.url + "assigns/ambpdf/" + assignId, {}, options);
  }
  updateSignature(obj) {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("companyToken"),
    });
    let options = { headers: authHeader };
    console.log(options);
    return this.http.post(this.url + "assigns/autosignature/", obj, options);
  }
  getTripsLog(obj) {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("companyToken"),
    });
    let options = { headers: authHeader };
    return this.http.post(this.url + "assigns/tripslog", obj, options);
  }
  logistiCareTriplogs(driverId, obj) {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("companyToken"),
    });
    let options = { headers: authHeader };
    return this.http.put(
      this.url + "assigns/manifest/" + driverId,
      obj,
      options
    );
  }
  manifest(driverId, obj) {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("companyToken"),
    });
    let options = { headers: authHeader };
    return this.http.post(
      this.url + "assigns/manifest/" + driverId,
      obj,
      options
    );
  }
  manifestLcp(obj) {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("companyToken"),
    });
    let options = { headers: authHeader };
    return this.http.post(this.url + "assigns/lcpmanifest/", obj, options);
  }
  manifestLogistic(obj) {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("companyToken"),
    });
    let options = { headers: authHeader };
    return this.http.post(this.url + "assigns/logisticmanifest/", obj, options);
  }
  manifestAmera(obj) {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("companyToken"),
    });
    let options = { headers: authHeader };
    return this.http.post(this.url + "assigns/ameramanifest/", obj, options);
  }
  mtmTRF(driverId, obj) {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("companyToken"),
    });
    let options = { headers: authHeader };
    return this.http.post(
      this.url + "assigns/mtmtrf/" + driverId,
      obj,
      options
    );
  }
  mtmClaim(obj) {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("companyToken"),
    });
    let options = { headers: authHeader };
    return this.http.put(this.url + "assigns/claimfile", obj, options);
  }
  mtmDriverLog(driverId, obj) {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("companyToken"),
    });
    let options = { headers: authHeader };
    return this.http.put(this.url + "assigns/mtmtrf/" + driverId, obj, options);
  }

  updateManifestTrip(manifestId, obj) {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("token"),
    });
    let options = { headers: authHeader };
    return this.http.put(this.url + `marketplaces/${manifestId}`, obj, options);
  }

  manifestActionAdd(obj) {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("token"),
    });
    let options = { headers: authHeader };
    return this.http.post(this.url + `marketplaceaction`, obj, options);
  }

  getNotification(id) {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("token"),
    });
    let options = { headers: authHeader };
    return this.http.get(this.url + "notifications?company=" + id, options);
  }
}

import { Injectable } from '@angular/core';
import io from 'socket.io-client';
import { SharedDataService } from '../shared/shared-data.service';
import { Subject, BehaviorSubject } from 'rxjs';
import { urls } from "src/app/utils/url-utils";
import { ToastrService } from 'ngx-toastr';

declare var $: any;
@Injectable({
  providedIn: 'root'
})
export class SocketService {
  event: any;
  user: any;
  socket: any;
  url = urls.SERVER_URL;
  loggedIn = false;
  private notification = new BehaviorSubject(null);

  constructor(private sharedData: SharedDataService, private toastr: ToastrService) {

    this.sharedData.getLoginStatus().subscribe(status => {
      // console.log('getLoginStatus -----------------------  >', status)
      this.loggedIn = status;
      if (this.loggedIn && !this.socket) {
        this.Socket();
      }

      if (!this.loggedIn) {
        // console.log('disconnectSocket');
        this.disconnectSocket();
      }

    });
  }
  Socket() {

    // console.log("socket connecting to server...");
    this.socket = io(this.url, { 'forceNew': true, query: 'auth_token=' + localStorage.getItem("token")  });
    this.socket.connect();

    this.socket.on('connect', data => {
      this.mainListeners();
      console.log("socket connected successfully",data);
    });

    this.socket.on('disconnect', data => {
      console.log("socket disconnected",data);
    });
  }

  mainListeners() {
    if (this.socket) {

      this.socket.on('getDriversByCompany', data => {
        // console.log('reciveDriversByCompany',data);
        this.sharedData.updateDrivers(data.drivers);
      });

      this.socket.on('broadcastDriverLocation', data => {
        if (data.success) {
          this.sharedData.updateDriverLocation(data.driver);
        }
      });
      this.socket.on('broadcastMarReadyNotification', data => {
        this.sharedData.updateMarkReadyStatus(data);
      });

      this.socket.on('broadcastFacilityTripQuarterPanel', data => {
        // console.log('broadcastFacilityTripQuarterPanel', data);
        this.notification.next(data.message);
        // $.Notification.autoHideNotify('success', 'bottom left', 'Notification', data.message);
        this.toastr.success(data.message,'Notification');
      });

      this.socket.on('broadcastMarketplaceNotification', data => {
        // console.log('assign marketplace', data)
        // $.Notification.autoHideNotify(data.status === 'accepted' ? 'success' : 'error', 'bottom left', data.status === 'accepted' ? 'Trip Accepted' : 'Trip Rejected', data.text);
        if (data) {
          if(data.status === 'accepted') {
            this.toastr.success(data.text,'Trip Accepted');
          } else {
            this.toastr.error(data.text,'Trip Rejected');
          }
        }
      });
      this.socket.on('RejectMarketPlaceTrip', data => {
        // console.log('assign marketplace', data)
      });
      this.socket.on('getMarkedTrips', data => {
        this.sharedData.addMarkedTripsReadyTrips(data);
      });

      this.socket.on('getDispatchApproachingTrips', data => {
        this.sharedData.addDispatchApproachingTrips(data);
      });

      this.socket.on('soonExpiringTrips', data => {
        this.sharedData.addExpireTrips(data);
      });

      this.socket.on('broadcastSignuprequestQuarterPanel', data => {
        // console.log('broadcastSignuprequestQuarterPanel', data);
        this.sharedData.addSignupRequests(data);
      });

      this.socket.on('updateAssignedJob', data => {
        this.sharedData.updateAssignedJob(data);
      });
    }
  }

  disconnectSocket() {
    if (this.socket && this.socket.connected) {
      this.socket.disconnect();
      this.socket = undefined;
    }
  }

  getDrivers() {


    // console.log('getDriversByCompany', this.socket);
    // console.log('getDriversByCompany', this.socket.connected);
    this.socket.emit('getDriversByCompany');
  }
  toggleMarkReady(id, status) {
    this.socket.emit('updateAssignedJob', { assignId: id, isReadyForPickup: status });
  }

  updateJob(assign, assignKey) {
    let params = {
      assignId: assign._id
    };
    
    params[assignKey] = !assign[assignKey];
    this.socket.emit('updateAssignedJob', params);
  }

  getNotification() {
    return this.notification;
  }


  getMarkReadyTripsEmit(data) {
    // console.log(data)
      this.socket.emit('getMarkedTrips', data);

  }

  getApproachingTripsEmit(data) {
    // console.log(data);
    this.socket.emit('getDispatchApproachingTrips', data);
  }

  getExpireTripsEmit(data) {
    // console.log(data)
    this.socket.emit('soonExpiringTrips', data);
  }


}

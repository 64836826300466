import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ContractorsService } from 'src/app/services/contractors/contractors.service';
import { appAlert, getKeys } from 'src/app/utils/utils.common';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.css']
})
export class FileUploadComponent implements OnInit, OnChanges {
  @Input() payload: any;
  @Input() driverId: string;
  @Output() updateBriefcase: EventEmitter<any> = new EventEmitter();
  @ViewChild('file', {static: true}) el: ElementRef;

  fileToUpload
  payloadKeys = [];
  selectedDate = '';
  preExpiryDate = '';
  documentImage = '';
  reportData;
  briefcase;
  isSubmitted = false;
  isValid = true;
  errorMessage;
  initialState_ExpiryDate;
  initialState_DocumentImage;

  constructor(private contractorsService: ContractorsService) { }

  ngOnInit() {
  }

  ngOnChanges(data) {
    this.selectedDate = '';
    this.preExpiryDate = '';
    this.documentImage = '';

    if (data && data.payload) {
      this.payloadKeys = getKeys(this.payload);
      this.initialState_ExpiryDate = this.payload[this.payloadKeys[2]];
      this.initialState_DocumentImage = this.payload[this.payloadKeys[3]];
      this.selectedDate = this.payload[this.payloadKeys[2]];
      this.preExpiryDate = this.payload[this.payloadKeys[2]];
      this.documentImage = this.payload[this.payloadKeys[3]];
    }
  }

  onChangeDate(event) {
    console.log(event);

    if (event) {
      this.selectedDate = moment.utc(event).local().format();
    }
  }

  fileUpload(event) {
    //this.url = this.urlImage;
    this.fileToUpload = this.el.nativeElement.files[0];
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]); // read file as data url
      reader.onload = (event: any) => { // called once readAsDataURL is completed
        //this.url = event.target.result;
        this.documentImage = event.target.result;
      }
    }
  }

  saveDriverBriefcase() {
    this.validate();
    if (!this.isValid) {
      return false;
    }
    
    appAlert({
      title: "Alert!",
      text: "Are you sure you want to proceed?",
      icon: "warning",
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      showCancelButton: true
    }).then((result) => {
      if (result.isConfirmed) {
        const formData: FormData = new FormData();
        formData.append(this.payloadKeys[2], this.selectedDate);
        if (this.fileToUpload) {
          formData.append(this.payloadKeys[3], this.fileToUpload, this.fileToUpload.name);
        }
        else {
          formData.append(this.payloadKeys[3], this.documentImage);
        }

        this.contractorsService.saveDriverBriefcase(this.driverId, formData)
          .subscribe(data => {
            if(data) {
              this.successAlert('Document has been updated successfully.');
              this.updateBriefcase.emit(data);
            }
          });
      }
    });
  }

  deleteDriverBriefcase() {
    appAlert({
      title: "Alert!",
      text: "Are you sure you want to proceed?",
      icon: "warning",
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      showCancelButton: true
    }).then((result) => {
      if (result.isConfirmed) {
        const formData: FormData = new FormData();
        formData.append(this.payloadKeys[2], '');
        formData.append(this.payloadKeys[3], '');
        this.contractorsService.saveDriverBriefcase(this.driverId, formData)
          .subscribe(data => {
            if (data) {
              this.successAlert('Document has been deleted successfully.');
              this.updateBriefcase.emit(data);
              this.documentImage = '';
              this.preExpiryDate = ''
            }
          });
      }
    });
  }

  successAlert(message) {
    appAlert({
      title: 'Success',
      text: message,
      icon: 'success'
    }).then(function () {
    });
  }

  validate() {
    if (!this.preExpiryDate && !this.documentImage) {
      this.errorMessage = "Image and Date are required"
      this.isValid = false;
    }
    else if (!this.preExpiryDate) {
      this.errorMessage = "Expiry Date is required"
      this.isValid = false;
    }
    else if (!this.documentImage) {
      this.errorMessage = "Image is required"
      this.isValid = false;
    }
    else {
      this.isValid = true;
    }
  }
}

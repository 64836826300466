import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { CompanyService } from 'src/app/services/company/company.service';
import { ContractorsService } from 'src/app/services/contractors/contractors.service';
import * as moment from 'moment-timezone';
import { SharedDataService } from 'src/app/services/shared/shared-data.service';
import { appAlert } from 'src/app/utils/utils.common';

@Component({
  selector: 'app-contractors',
  templateUrl: './contractors.component.html',
  styleUrls: ['./contractors.component.css']
})
export class ContractorsComponent implements OnInit {
  @ViewChild('profileFile', {static: true}) elProfile: ElementRef;
  @ViewChild('licenseFile', {static: true}) elLicense: ElementRef;
  @ViewChild('address', {static: true}) address: any;

  drivers = [];
  documents = [];
  briefcaseDocuments: any;

  searchForm;
  driverForm;
  companies;
  showHeading;
  submitted = false;
  companyId;
  
  driverSearch = '';
  selectedDriverIndex = -1;
  selectedDriverId;
  selectedCompany;
  selectedDriver;
  companyObj;
  profileImageURL;
  profileUploadFile;
  licenseUploadFile;

  profileImage;
  driverLicenseImageUrl;
  userPlatform;
  reportData;
  company;
  driver;
  rawBriefcaseData;
  
  url;
  fileName;
  payload;
  preExpiryDate;
  documentImage;

  constructor(private fb: FormBuilder,
    private companyService: CompanyService,
    private contractorsService: ContractorsService,
    private sharedData: SharedDataService) { }

  ngOnInit() {
    this.sharedData.getUser().subscribe(data => {
      this.userPlatform = data.platform;
    });

    this.companyService.getAllCompanies().subscribe(data => {
      this.companies = data;
      console.log('Companies : ', this.companies);
    })

    this.driverForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      legalName: ['', Validators.required],
      driverLicenseNumber: ['', Validators.required],
      contactNumber: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      address: ['', Validators.required],
      latitude: [''],
      longitude: [''],
      driverLicenseImage: [''],
      licenseRegistrationExpiry: ['', Validators.required],
      preLiceseExpiryDate: [''],
      profileImageURL:['']
    });

    this.searchForm = this.fb.group({
      search_companyId: ['', Validators.required],
      search_date: ['', Validators.required]
    });
  }

  onCompanyChange() {
    if (this.searchForm.value.search_companyId) {
      this.selectedCompany = this.companies.find(company => company._id === this.searchForm.value.search_companyId);
      this.companyObj = {
        platform: this.userPlatform,
        _id: this.selectedCompany._id,
        displayName: this.selectedCompany.displayName
      }
      console.log('SELECTED COMPNANY: ', this.selectedCompany);
      this.contractorsService.getDrivers(this.searchForm.value.search_companyId).subscribe(data => {
        console.log('CONTRACTORS: ', data);
        if (data && data.length > 0) {
          this.drivers = data;
        }
        else {
          this.clearData();
          this.clearDriverForm();
        }
      });
    }  
    else {
      this.clearData();
      this.clearDriverForm();
    }
  }

  clearData() {
    this.selectedDriverId = '';
    this.selectedDriverIndex = -1;
    
    this.drivers = [];
    this.briefcaseDocuments = {};
  }

  clearDriverForm() {
    this.driverForm.patchValue({
      firstName: '',
      lastName: '',
      legalName: '',
      driverLicenseNumber: '',
      contactNumber: '',
      email: '',
      address: '',
      latitude: '',
      longitude: '',
      profileImageURL: '',
      driverLicenseImage: '',
      licenseRegistrationExpiry: ''
    });

    this.address.addresstext.nativeElement.value = '';
    this.profileImageURL = '';
    this.driverLicenseImageUrl = '';
  }

  getDriverPersonalInformation(driver, index) {
    this.documents = [];
    this.profileImageURL = '';
    this.driverLicenseImageUrl = '';
    this.profileUploadFile = null;
    this.licenseUploadFile = null;

    this.selectedDriverId = driver._id;
    this.selectedDriverIndex = index;
    this.selectedDriver = driver;
    console.log(driver);
    this.address.addresstext.nativeElement.value = driver.address;
    this.driverForm.patchValue({preLiceseExpiryDate: driver.licenseRegistrationExpiry, ...driver});
    console.log(this.driverForm.value.preLiceseExpiryDate)
    this.profileImageURL = driver.profileImageURL;
    this.driverLicenseImageUrl = driver.driverLicenseImage;
   
    this.contractorsService.getDriverBriefcases(driver._id).subscribe(data => {
      console.log('Briefcase ', data);
      this.rawBriefcaseData = data;
      if (data && data.pdfDocs) {
        this.documents = data.pdfDocs;
      }
      this.fillDocuments(data);
      console.log('DRIVER Briefcase: ', this.briefcaseDocuments, this.documents);
    });
  }

  getDatebyMoment(date) {
    return date ? moment(date).toISOString() : '';
  }

  fillDocuments(data) {
    this.briefcaseDocuments = {};
    this.briefcaseDocuments = {
      countyHackLicense: {
        title: 'County Hack License',
        description: '(If Applicable)',
        countyHackLicenseImageExpiry: data ? this.getDatebyMoment(data.countyHackLicenseImageExpiry) : '',
        countyHackLicenseImage: data ? data.countyHackLicenseImage : ''
      },
      drugTest: {
        title: 'Drug Test (10 Substance Panel in FL -Yearly)',
        description: '(5 Substance Panel in all Other States - Yearly)',
        drugTestImageExpiry: data ? this.getDatebyMoment(data.drugTestImageExpiry) : '',
        drugTestImage: data ? data.drugTestImage : ''
      },
      physicalOrMedicalExamCertificate: {
        title: 'Physical/Medical Examiners Certificate',
        description: '(Every 2 Years)',
        physicalOrMedicalExamCertificateExpiry: data ? this.getDatebyMoment(data.physicalOrMedicalExamCertificateExpiry) : '',
        physicalOrMedicalExamCertificate: data ? data.physicalOrMedicalExamCertificate : ''
      },
      firstAidCertificate: {
        title: 'First Aid Certificate',
        description: '(Every 2 Years)',
        firstAidCertificateExpiry: data ? this.getDatebyMoment(data.firstAidCertificateExpiry) : '',
        firstAidCertificate: data ? data.firstAidCertificate : ''
      },
      cprCertificate: {
        title: 'CPR Certificate',
        description: '(Every 2 Years)',
        cprCertificateExpiry: data ? this.getDatebyMoment(data.cprCertificateExpiry) : '',
        cprCertificate: data ? data.cprCertificate : ''
      },
      patPassCertificate: {
        title: 'PAT/PASS Certificate',
        description: '(Every 3 Years)',
        patPassCertificateExpiry: data ? this.getDatebyMoment(data.patPassCertificateExpiry) : '',
        patPassCertificate: data ? data.patPassCertificate : ''
      },
      defensiveDriverCertificate: {
        title: 'Defensive Driver Certificate',
        description: '(Every 3 Years)',
        defensiveDriverCertificateExpiry: data ? this.getDatebyMoment(data.defensiveDriverCertificateExpiry) : '',
        defensiveDriverCertificate: data ? data.defensiveDriverCertificate : ''
      },
      fraudWasteAbuseCertificate: {
        title: 'Fraud, Waste & Abuse Certificate',
        description: '(Yearly)',
        fraudWasteAbuseCertificateExpiry: data ? this.getDatebyMoment(data.fraudWasteAbuseCertificateExpiry) : '',
        fraudWasteAbuseCertificate: data ? data.fraudWasteAbuseCertificate : ''
      },
      hipaaCertificate: {
        title: 'HIPPA Certificate',
        description: '(Every 2 Years)',
        hipaaCertificateExpiry: data ? this.getDatebyMoment(data.hipaaCertificateExpiry) : '',
        hipaaCertificate: data ? data.hipaaCertificate : ''
      } 
    };
  }

  getAddress(place) {
    if (place.formatted_address) {
      this.driverForm.patchValue({
        latitude: place.geometry.location.lat(),
        longitude: place.geometry.location.lng(),
        address: place.formatted_address
      });
    }
  }

  onChangeDriverSearch(evt) {
    this.selectedDriverIndex = -1;
    this.clearDriverForm();
  }

  clearDriverSearch() {
    this.selectedDriverIndex = -1;
    this.clearDriverForm();
    this.driverSearch = '';
  }

  fileUpload(event, forImage) {

    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]); // read file as data url
      reader.onload = (event: any) => { // called once readAsDataURL is completed
        //this.url = event.target.result;
        if (forImage === 'profile') {
          this.profileUploadFile = this.elProfile.nativeElement.files[0];
          this.profileImageURL = event.target.result;}
        if (forImage === 'license') {
          this.licenseUploadFile = this.elLicense.nativeElement.files[0];
          this.driverLicenseImageUrl = event.target.result;

        
      }
      }
    }
  }

  onChangeLicenseExpiryDate(event) {
    console.log(event);
    if (event) {
      this.driverForm.patchValue({
        licenseRegistrationExpiry : moment.utc(event).local().format(),
      })
    }
  }

  onSubmitDriver() {
    this.submitted = true;
    if (this.driverForm.invalid) {
      console.log(this.driverForm.value);
      return;
    }
    // swal({
    //   title: "Alert!",
    //   text: "Are you sure you want to proceed?",
    //   icon: "warning",
    //   buttons: [
    //     'No',
    //     'Yes'
    //   ],
    //   dangerMode: false,
    // }).then((isConfirm) => {
    //   if (isConfirm) {
    //     this.submitted = true;
    //     this.updateDriver();
    //   }
    // });

    appAlert({
      title: "Alert!",
      text: "Are you sure you want to proceed?",
      icon: "warning",
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      showCancelButton: true
    }).then((result) => {
      if (result.isConfirmed) {
        this.submitted = true;
        this.updateDriver();
      }
    });
  }

  updateDriver() {
    this.contractorsService.saveDriver(this.selectedDriverId, this.driverForm.value, this.profileUploadFile, this.licenseUploadFile)
    .subscribe(data => {
      if (data) {
        this.selectedDriver = data;
        this.driverUpdateMessage();
        this.submitted = false;
        console.log('Driver Updated ', data);
        console.log('Briefcase Driver ', this.briefcaseDocuments)
        this.reportData = {
          data: { company: this.companyObj, briefcase: this.briefcaseDocuments, driver: data }
        }
        console.log('Report Data: ', JSON.stringify(this.reportData));
        this.generateReport(this.reportData);
      }
    });
  }

  driverUpdateMessage() {
    appAlert({
      title: 'Success',
      text: 'Driver information has been updated successfully.',
      icon: 'success'
    }).then(function () {
    });
  }

  approveDriver() {
    this.contractorsService.approveDriver(this.selectedDriverId)
    .subscribe(data => {
      this.reportData = {
        data : { company: this.companyObj, briefcase: this.briefcaseDocuments, driver: data}
      }
      this.generateReport(this.reportData);
    }); 
  }

  generateReport(data) {
    console.log('REPORT DATA: ', JSON.stringify(this.reportData));
    this.contractorsService.generateReport(data).subscribe((data: any) => {
      if(data) {
        //debugger
        console.log('Report Generated ', data);
        console.log('PDFLINK', data.pdfFile);
        let pdfFile = ''
        if (data && data.pdfFile) {
          pdfFile = data.pdfFile;
        }

        this.documents.push(pdfFile);
        this.rawBriefcaseData.pdfDocs = this.documents;
        // this.rawBriefcaseData.pdfDocs = 'http://pdfserver.qalbs.com/reports/onboard-driver-medex-6010065273d5d20f02ecab80.pdf, http://pdfserver.qalbs.com/reports/onboard-driver-medex-6010065273d5d20f02ecab90.pdf';

        const formData: FormData = new FormData();
        Object.keys(this.rawBriefcaseData).forEach(key => {
          formData.append(`${key}`, this.rawBriefcaseData[key]);
        }
        );

        this.contractorsService.saveDriverBriefcase(this.selectedDriverId, formData)
          .subscribe((data: any) => {
            if (data && data.pdfDocs) {
              this.documents = [];
              this.documents = data.pdfDocs;
            }
            console.log('Briefcase Updated After Report Generation: ', data);
          });
      }
    });
  }

  updateDriverBriefcase(data) {
    console.log('EVENT DATA: ', data);
    this.rawBriefcaseData = data;
    this.fillDocuments(data); 
    this.reportData = {
      data : { company: this.companyObj, briefcase: this.briefcaseDocuments, driver: this.selectedDriver }
    }
    this.generateReport(this.reportData);
  }

  download(url, fileName): void {
    this.contractorsService
      .download('http://pdfserver.qalbs.com/reports/onboard-driver-medex-6010065273d5d20f02ecab80.pdf')
      .subscribe(blob => {
        const a = document.createElement('a')
        const objectUrl = URL.createObjectURL(blob)
        a.href = objectUrl
        a.download = 'sample.pdf';
        a.click();
        URL.revokeObjectURL(objectUrl);
      });
  }

  onChangeDate(evt) {
  }

  get form() { return this.driverForm.controls; }

}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable } from 'rxjs';
import { urls } from "src/app/utils/url-utils";

@Injectable({
  providedIn: 'root'
})
export class FareService {
  url = urls.BASE_URL;

  constructor(private http: HttpClient) { }

  getAllFares() {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("token")
    });
    let options = { headers: authHeader };
    // console.log(search)
    return this.http.get(this.url + "quarterpanels", options);
  }

  getFareToEdit(staffId) {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("token")
    });
    let options = { headers: authHeader };
    return this.http.get(this.url + "quarterpanels/" + staffId, options);
  }

  createNewFare(fare) {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("token")
    });
    let options = { headers: authHeader };
    const formData: FormData = new FormData();
    Object.keys(fare).forEach(key =>
      formData.append(`quarterpanel[${key}]`, fare[key])
    );
    return this.http.post(this.url + 'quarterpanels', formData, options);
  }

  editFare(staffId, staff, file) {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("token")
    });
    let options = { headers: authHeader };
    console.log(staff, file)
    delete staff.cpassword;
    const formData: FormData = new FormData();
    if (file) {
      formData.append('newProfilePicture', file, file.name);
    }
    Object.keys(staff).forEach(key =>
      formData.append(`quarterpanel[${key}]`, staff[key])
    );
    return this.http.put(this.url + 'quarterpanels/' + staffId, formData, options);
  }

  getSignupRequestFareList(signuprequestId): Observable<any> {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("token")
    });
    let options = { headers: authHeader };
    return this.http.get<any>(this.url + "signuprequests/" + signuprequestId, options);
  }

  createSignupRequestFare(signuprequestId, fare) {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("token")
    });
    let options = { headers: authHeader };
    // const formData: FormData = new FormData();
    // Object.keys(fare).forEach(key =>
    //   formData.append(`quarterpanel[${key}]`, fare[key])
    // );
    return this.http.post(this.url + 'signuprequests/fares/' + signuprequestId, fare, options);
  }

  approveFares(): Observable<any> {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("token")
    });
    let options = { headers: authHeader };
    return this.http.get<any>(this.url + "signuprequests/", options);
  }

}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search'
})
export class SearchPipe implements PipeTransform {

  transform(items: any, filter?: any, defaultFilter?: boolean): any {
    if (!filter){
      return items;
    }

    if (!Array.isArray(items)){
      return items;
    }

    if (filter && Array.isArray(items)) {
      // console.log(filter);
      let filterKeys = Object.keys(filter);
      // console.log(filterKeys);

      if (defaultFilter) {
        return items.filter(item =>
            filterKeys.reduce((x, keyName) =>
                (x && new RegExp(filter[keyName], 'gi').test(item[keyName])) || filter[keyName] == "", true));
      } else {
        return items.filter(item => {
          return filterKeys.some((keyName) => {
            if(item[keyName].displayName){
              return new RegExp(filter[keyName], 'gi').test(item[keyName].displayName) || filter[keyName] == "";
            }else {
              return new RegExp(filter[keyName], 'gi').test(item[keyName]) || filter[keyName] == "";
            }
          });
        });
      }
    }
  }

}

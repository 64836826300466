import { Component, OnInit } from '@angular/core';
import { CompanyService } from 'src/app/services/company/company.service';

@Component({
  selector: 'app-provider-dashboard',
  templateUrl: './provider-dashboard.component.html',
  styleUrls: ['./provider-dashboard.component.css']
})
export class ProviderDashboardComponent implements OnInit {
  companies;
  trips = [1,1,1,1,1]

  tripType = {
    outstandingTrips: {
      name: "Outstanding",
      link: "/facility-trips/outstanding",
      class: "fa fa-list-ol remaining-icon",
    },
    offeredTrips: {
      name: "Offered",
      link: "/facility-trips/offered",
      class: "fa fa-list-ol total-icon",
    },
    acceptedTrips: {
      name: "Accepted",
      link: "/facility-trips/accepted",
      class: "fa fa-check-circle completed-icon",
    },
    rejectedTrips: {
      name: "Rejected",
      link: "/facility-trips/rejected",
      class: "fa fa-times-circle-o cancelled-icon",
    },
    inprogressTrips: {
      name: "In Progress",
      link: "/facility-trips/inprogress",
      class: "fa fa-car inprogress-icon",
    },
    finishedTrips: {
      name: "Finished",
      link: "/facility-trips/finished",
      class: "fa fa-check-circle completed-icon",
    },
    cancelledTrips: {
      name: "Cancelled",
      link: "/facility-trips/cancelled",
      class: "fa fa-times-circle-o cancelled-icon",
    },
  };

  constructor(private companyService: CompanyService) { }

  ngOnInit() {
    this.companyService.getAllCompanies()
      .subscribe((data) => {
        this.companies = data;
      });
  }

  getTripType(key) {
    return this.tripType[key].name;
  }

  getTripClass(key) {
    return this.tripType[key].class;
  }

}

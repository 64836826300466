import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedDataService {
  private isLoggedIn = new BehaviorSubject(false);
  private user = new BehaviorSubject(null);
  private drivers = new BehaviorSubject(null);
  private modal = new BehaviorSubject(false);
  private driver = new BehaviorSubject(null);
  private assign = new BehaviorSubject(null);
  private assignedJob = new BehaviorSubject(null);
  private markedTripsReady = new BehaviorSubject(null);
  private dispatchApproachingTrips = new BehaviorSubject(null);
  private expireTrips = new BehaviorSubject(null);
  private signupRequests = new BehaviorSubject(null);
  private providerCompanyId = new BehaviorSubject(null);

  constructor() {
   }
  getLoginStatus() {
    return this.isLoggedIn;
  }

  updateLoginStatus(status: boolean) {
    this.isLoggedIn.next(status);
  }
  getUser() {
    return this.user;
  }

  updateUser(user: any) {
    this.user.next(user);
  }
  mapBrokers = function (companyType) {
    var companyTypes = {
      '': 'All Brokers',
      'lcp': 'LCP',
      'default': 'Default',
      'first': 'MTM',
      'logisticscare': 'Logisticare',
      'medtrans': 'NMN',
      'united': 'SouthEastrans',
      'medicaid': 'Medicaid',
      'amera': 'Amera',
      'veyo': 'Veyo',
      'vapremier': 'Va Premier',
      'mas': 'MAS',
      'epic': 'EPIC',
      'onecall': 'OneCall',
      'americanLogistics': 'American Logistics',
      'access2Ride': 'Access2Care',
      'arn': 'Angel Ride Network',
      'medex': 'Medex',
      'callthecar': 'CallTheCar',
      'abcinsurance': 'Abc Insurance'





    };
    return companyTypes[companyType];
  };

  getDrivers() {
    return this.drivers;
  }
  getDriverLocation() {
    return this.drivers;
  }
  updateDrivers(drivers: any) {
    this.drivers.next(drivers);
  }
  updateDriverLocation(driver: any){
    this.driver.next(driver);
  }
  updateMarkReadyStatus(data: any){
    // console.log("mark ready status update")
    this.assign.next(data.assign);
  }
  geteMarkReadyStatus(){
    return this.assign;
  }
  getModal() {
    return this.modal;
  }

  updateModal(status: boolean) {
    this.modal.next(status);
  }


  getMarkReadyTripsListener(){
    return  this.markedTripsReady;
  }

  getDispatchApproachingTripsListener(){
    return this.dispatchApproachingTrips;
  }

  addExpireTripsListener(){
    return this.expireTrips;
  }

  signupRequestsListener(){
    return this.signupRequests;
  }

  addMarkedTripsReadyTrips(data){
    this.markedTripsReady.next(data);
  }

  addDispatchApproachingTrips(data){
    this.dispatchApproachingTrips.next(data);
  }

  addExpireTrips(data){
    this.expireTrips.next(data);
  }

  addSignupRequests(data) {
    this.signupRequests.next(data);
  }

  updateProviderCompanyId(companyId) {
    this.providerCompanyId.next(companyId);
  }

  getProviderCompanyId() {
    return this.providerCompanyId;
  }

  updateAssignedJob(data: any){
    let assign = data && data.assign ? data.assign : undefined;
    this.assignedJob.next(assign);
  }
  getAssignedJob(){
    return this.assignedJob;
  }
}

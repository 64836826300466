import { Component, OnInit } from "@angular/core";
import { showHeading } from "src/app/utils/global-constants";
import * as moment from "moment-timezone";
import { AssignService } from "src/app/services/assign/assign.service";
import { CompanyService } from "src/app/services/company/company.service";
declare var _: any;

@Component({
  selector: "app-nemt-statistics",
  templateUrl: "./nemt-statistics.component.html",
  styleUrls: ["./nemt-statistics.component.css"],
})
export class NEMTStatisticsComponent implements OnInit {
  vm: this;
  showHeading = showHeading;
  statistics;
  selectedCompany;
  startDate = moment().startOf("day").toISOString();
  endDate = moment().endOf("day").toISOString();
  companies: any = [];
  pretimePickerDate = [this.startDate, this.endDate];

  constructor(
    private companyService: CompanyService,
    private assignService: AssignService
  ) {}

  ngOnInit() {
    this.companyService.getAllCompanies().subscribe((data) => {
      this.companies = _.sortBy(data, "displayName");
    });
  }
  selectCompany(id) {
    this.statistics = null;
    this.selectedCompany = this.companies.find((x) => x._id === id);
  }
  selectRange(event) {
    console.log(moment(event[0]).startOf("day").toISOString());
    console.log(moment(event[1]).endOf("day").toISOString());

    this.startDate = moment(event[0]).startOf("day").toISOString();
    this.endDate = moment(event[1]).endOf("day").toISOString();
  }

  getCompanyToken() {
    if (!this.selectedCompany) return;
    this.companyService
      .getCompanyToken(this.selectedCompany._id)
      .subscribe((data: any) => {
        const newStartDate = moment(this.startDate)
          .tz(this.selectedCompany.timeZone, true)
          .toISOString();
        const newEndDate = moment(this.endDate)
          .tz(this.selectedCompany.timeZone, true)
          .toISOString();

        this.getNEMTStats(newStartDate, newEndDate, data.companyToken);
      });
  }

  getNEMTStats(startDate, endDate, companyToken) {
    this.statistics = null;
    this.assignService
      .getTripNEMTStats(startDate, endDate, companyToken)
      .subscribe((data: any) => {
        console.log(data);
        if (data && data.length) {
          this.statistics = data[0];
        }
      });
  }
}

import { Component, OnInit } from '@angular/core';
import { MarketplaceServiceService } from 'src/app/services/marketplace/marketplace-service.service';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { AssignService } from 'src/app/services/assign/assign.service';
import { INgxSelectOption } from 'ngx-select-ex';
import { ToastrService } from 'ngx-toastr';
declare var $: any;
declare var _: any;
@Component({
  selector: 'app-marketplace',
  templateUrl: './marketplace.component.html',
  styleUrls: ['./marketplace.component.css']
})
export class MarketplaceComponent implements OnInit {
  trips: any;
  companies: any;
  selectedCompany: any;
  toAssign: any;
  filterMile = 0;
  index = -1;
  companiesLoad: any;
  selectedCompanyTitle = '';
  searchText = '';
  assignTrips: any;
  specialRate: any;
  comapanyNote: any;
  companyFilter: any;
  tripFilter: any;
  history: any;

  constructor(
    private marketPlaceService: MarketplaceServiceService,
    public ngxSmartModalService: NgxSmartModalService,
    private assignService: AssignService,
    private toastr: ToastrService
  ) { }
  assignId: any;
  ngOnInit() {
    this.getTrips();

    this.assignService.nearByCompanyDefault().subscribe((data: any) => {
      if (data.success) {
        this.companiesLoad = data.companies;
        this.companiesLoad = _.sortBy( this.companiesLoad, 'text' );
          this.companiesLoad.unshift({
            id: '',
            text: 'All Companies'
          });
        console.log(this.companiesLoad);
      }
    });
  }
  getTrips() {
    this.marketPlaceService.getMarketPlaceAssigned().subscribe((data: any) => {
      console.log(data);
      this.trips = data;
      this.assignTrips = data;
    });
  }
  saveCompany(rate, comapanyNote, company) {

    this.assignService.updateMarketPlaceCompany(this.assignId, this.selectedCompany, rate, comapanyNote, company, true)
      .subscribe((data: any) => {
        this.assignId = '';
        console.log('updateMarketPlaceCompany', data);

        // $.Notification.autoHideNotify('success', 'bottom left', 'Company Updated', 'company updated successfully. ');
        this.toastr.success('Company updated successfully.','Company Updated');

        console.log(this.trips[this.index]['company'])
        this.trips[this.index].company['displayName'] = this.selectedCompanyTitle;
        this.selectedCompanyTitle = ''
        this.selectedCompany = '';
        this.comapanyNote = undefined;
        this.index = -1
        this.specialRate = undefined;
        console.log(data);
        this.getTrips();
      });
    this.ngxSmartModalService.get('myModal').close();
  }
  removeCompany(index, assignId, company) {
    this.assignId = assignId;
    console.log(index, this.assignId)
    this.assignService.removeMarketPlaceCompany(this.assignId, company, true)
      .subscribe((data: any) => {
        this.assignId = '';
        // this.trips[this.index]['company'].displayName = this.selectedCompanyTitle;
        this.selectedCompanyTitle = ''
        this.selectedCompany = '';
        this.index = -1
        this.trips.splice(index, 1);
        console.log(data);
        this.getTrips();
      });
    this.ngxSmartModalService.get('myModal').close();
  }
  openModal(index, assign) {
    this.assignId = assign._id;
    console.log(this.assignId)
    this.toAssign = assign;
    this.selectedCompany = assign.company._id;
    this.selectedCompanyTitle = assign.company.displayName;
    this.index = index;
    this.assignService.nearByCompany(assign.jobOriginLatitude, assign.jobOriginLongitude).subscribe((data: any) => {
      if (data.success) {
        this.companies = data.companies;
        this.companies = _.sortBy( this.companies, 'text' );
        console.log(this.companies);
      }
    });
    // this.ngxSmartModalService.getModal('historyModal').close();
    this.ngxSmartModalService.getModal('myModal').open();
  }
  doSelectionChanges(event: INgxSelectOption) {
    console.log(event[0])
    if (event[0]) {
      this.selectedCompanyTitle = event[0].text;
    }
  }
  openHistroyModal(marketplaceID){
    this.ngxSmartModalService.getModal('historyModal').open();
    this.marketPlaceService.getMarketPlaceHistory(marketplaceID).subscribe(data =>{
      console.log(data);
      this.history = data;
    })
  }
  closeHistroyModal(){
    this.ngxSmartModalService.getModal('historyModal').close();
  }
  selectCompany(companyId) {
    this.selectedCompany = companyId;
    this.companies.map((element, index) => {
      if (element.id === companyId) {
        this.selectedCompanyTitle = element.text;
        return;
      }
    });
    console.log(this.selectedCompany)
  }
  milesFilter() {
    console.log(this.filterMile)
    if (this.filterMile > 0) {
      if (this.filterMile <= 15) {
        this.trips = this.assignTrips.filter(element => {
          return (element.milage < this.filterMile && element.milage >= this.filterMile - 5)
        });
      } else {
        this.trips = this.assignTrips.filter(element => {
          return element.milage >= this.filterMile
        });
      }

    } else {
      this.trips = this.assignTrips;
    }
  }
}

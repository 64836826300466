import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Subscription } from "rxjs";
import { SharedService } from "../shared-service/shared.service";
import { showHeading } from "src/app/utils/global-constants";
import { TripService } from "src/app/services/trip-service/trip.service";
import { Router } from "@angular/router";
import { FacilityService } from "src/app/services/facility/facility.service";
import { appAlert } from "src/app/utils/utils.common";
import { CompanyService } from "src/app/services/company/company.service";
declare var $: any;
@Component({
  selector: "app-facility-trips-preview",
  templateUrl: "./facility-trips-preview.component.html",
  styleUrls: ["./facility-trips-preview.component.css"],
})
export class FacilityTripsPreviewComponent implements OnInit, OnDestroy {
  facilities: any = [];
  companies: any = [];
  facility:any ={}
  preferredprovider:any ={}
  subscription: Subscription;
  data;
  map;
  timeZone = "Asia/Karachi";
  origin: any;
  destination: any;
  dirs : Array<any> = [];
  legId = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
  ];

  constructor(
    private tripSharedService: SharedService,
    private router: Router,
    private tripService: TripService,
    private facilityService: FacilityService,
    private companyService: CompanyService
  ) {}

  ngOnInit() {
    // Call Shared Service

    this.facilityService.getAllFacilities().subscribe((response) => {
      console.log(response);
      this.facilities = response;
      this.facilities = this.facilities.filter(
        (f) => f.accountStatus === "active"
      );
      this.fetchCompanies();
    });





  }

fetchCompanies(){

  this.companyService.getAllCompanies('active', true).subscribe(arg => {
    this.companies = arg;
    console.log(arg)
  this.getDataFromShareService();
  });
}

  getDataFromShareService() {
    this.subscription = this.tripSharedService.currentData.subscribe((data) => {
      console.log("Reivew Trip Details ", data);
      if (JSON.stringify(data) !== "{}") {
        this.data = data;

        this.dirs.push({
          origin: { lat: this.data.jobOriginLatitude, lng: this.data.jobOriginLongitude },
          destination: { lat: this.data.jobDestinationLatitude, lng: this.data.jobDestinationLongitude },
          renderOptions: { polylineOptions: { strokeColor: '#0088FF' } }
        });

        data.tripLegs.forEach((element,index) => {
          this.dirs.push({
            origin: { lat: element.jobOriginLatitude, lng:element.jobOriginLongitude },
            destination: { lat: element.jobDestinationLatitude, lng:element.jobDestinationLongitude },
            renderOptions: { polylineOptions: { strokeColor: this.getColor(index) } }
          });


        });
         this.facility = this.facilities.find(
          (i) => i._id.toString() === this.data.facility
          );

          this.preferredprovider  = this.companies.find(
          (i) => i._id.toString() === this.data.suggestedprovider
          );
          console.log( this.facility,this.facilities );
          if ( this.facility) {
          console.log( this.facility);
          this.timeZone =  this.facility.timeZone;
        }

      }
    });
  }

  getColor(index) {
    if (index === 0) {
      return '#008000'
    } else {
      return '#FFFF00'
    }
  ;
  }

  showHeading() {
    return showHeading;
  }

  backClicked() {
    this.router.navigateByUrl("facility-trips/update");
  }

  submitForm() {
    appAlert({
      title: "Alert!",
      text: "Are you sure you want to proceed?",
      icon: "warning",
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      showCancelButton: true
    }).then((result) => {
      if (result.isConfirmed) {
        this.tripService.createNewTrip(this.data).subscribe((data) => {
          console.log(data);
          this.router.navigateByUrl("facility-trips/outstanding");
        });
      }
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) this.subscription.unsubscribe();
  }

  }


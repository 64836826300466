import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable } from 'rxjs';
import { urls } from "src/app/utils/url-utils";

@Injectable({
  providedIn: 'root'
})
export class OfferedTripsService {
  url = urls.BASE_URL;

  constructor(private http: HttpClient) { }

  getOfferedTrips(): Observable<any> {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjoiNWZjMDk0YWIyN2Q1NmUyODYxNWY2NzRkIiwiYWNjb3VudFN0YXR1cyI6ImFjdGl2ZSIsInJvbGVzIjoiY29tcGFueSIsImlhdCI6MTYxNzI1NDU1Mn0.8jnQPjveHFqM7GCXrRopTOdxo-keq-n0JLNTuZUG0Ds",
    });
    let options = { headers: authHeader };
    return this.http.get<any>(this.url + "facilitytrips?status=offered" , options);
  }

  updateTrip(trip): Observable<any> {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjoiNWZjMDk0YWIyN2Q1NmUyODYxNWY2NzRkIiwiYWNjb3VudFN0YXR1cyI6ImFjdGl2ZSIsInJvbGVzIjoiY29tcGFueSIsImlhdCI6MTYxNzI1NDU1Mn0.8jnQPjveHFqM7GCXrRopTOdxo-keq-n0JLNTuZUG0Ds",

    });
    let options = { headers: authHeader };
    return this.http.put<any>(this.url + "facilitytrips/"+ trip._id,trip,options);
  }

}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { errorHandl } from 'src/app/utils/network-utils';
import { urls } from 'src/app/utils/url-utils';

@Injectable({
  providedIn: 'root'
})
export class ContractorsService {
  url = urls.BASE_URL;


  constructor(private http: HttpClient) { }

  getDrivers(company): Observable<any> {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("token")
    });
    let options = { headers: authHeader };
    return this.http.get<any>(this.url + "drivers/onboard?company=" + company, options);
  }

  getDriverBriefcases(driverId) {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("token")
    });
    let options = { headers: authHeader };
    return this.http.get<any>(this.url + "briefcases/documents/" + driverId, options);
  }

  saveDriverBriefcase(driverId, data) {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("token")
    });
    let options = { headers: authHeader };

    return this.http.post(this.url + "briefcases/documents/" + driverId, data, options)
      .pipe(catchError(errorHandl));
  }

  saveDriver(driverId, driver,file1,file2 ) {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("token")
    });
    let options = { headers: authHeader };



    const formData: FormData = new FormData();
    Object.keys(driver).forEach(key => {
      formData.append(key, driver[key]);
    })

    console.log('file1',file1);
    if(file1){

      formData.set('profileImageURL',file1, file1.name);
    }
    if(file2){
      formData.set('driverLicenseImage',file1, file1.name);
    } 

    // formData.forEach(data => {
    //   console.log(data);
    // })
    return this.http.put(this.url + "drivers/" + driverId, formData, options)
      .pipe(catchError(errorHandl));
  }

  approveDriver(driverId) { 
    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("token")
    });
    let options = { headers: authHeader };
    return this.http.get<any>(this.url + "drivers/approve/" + driverId, options);
  }

  generateReport(data) {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("token")
    });
    let options = { headers: authHeader };

    return this.http.post(urls.PDF_REPORT_URL + "report/onboarding/driver", data, options)
      .pipe(catchError(errorHandl));
  }

  download(url: string): Observable<Blob> {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/pdf');
    return this.http.get(url, {
      headers: headers,
      responseType: 'blob'
    })
  }
}

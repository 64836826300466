import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { AssignService } from 'src/app/services/assign/assign.service';
import * as moment from 'moment-timezone';
import { Router, ActivatedRoute } from '@angular/router';
import { appAlert } from 'src/app/utils/utils.common';

@Component({
  selector: 'app-new-dispatch-trip',
  templateUrl: './new-dispatch-trip.component.html',
  styleUrls: ['./new-dispatch-trip.component.css']
})
export class NewDispatchTripComponent implements OnInit, AfterViewInit {
  tripForm: FormGroup;
  submitted = false;
  pickup: any;
  dropoff: any;
  companies: any;
  assignId: any;
  trip: any;
  @ViewChild('pickupElem', {static: true}) pickupElem: any;
  @ViewChild('dropoffElem', {static: true}) dropoffElem: any;
  @ViewChild('pickupElemReturn', {static: true}) pickupElemReturn: any;
  @ViewChild('dropoffElemReturn', {static: true}) dropoffElemReturn: any;
  constructor(private formBuilder: FormBuilder, private assignService: AssignService, private router: Router, private activatedRoute: ActivatedRoute) {
  }
  ngAfterViewInit() {
    if (this.assignId === '' || this.assignId === null) {
      return;
    }
    this.assignService.getEditTripDetail(this.assignId).subscribe((data: any) => {
      this.trip = data;
      console.log(this.trip);
      delete this.trip.__v;
      let assignType = 'normal';
      let { displayName, contactNumber, cellPhone, clientId } = this.trip.priorityClient;

      if (this.trip.companyType !== '') {
        assignType = 'medical';
      }
      this.tripForm.patchValue({
        ...this.trip, assignType: assignType,
        displayName: displayName,
        cellPhone: cellPhone,
        contactNumber: contactNumber,
        clientId: clientId,
        company: this.trip.company._id
      });
      this.pickupElem.addresstext.nativeElement.value = this.trip.jobOriginAddress;
    this.dropoffElem.addresstext.nativeElement.value = this.trip.jobDestinationAddress;
    });

  }
  ngOnInit() {
    this.assignId = this.activatedRoute.snapshot.paramMap.get('assignId');

    this.tripForm = this.formBuilder.group({
      assignType: ['normal'],
      appointmentTime: [],
      displayName: ['', Validators.required],
      contactNumber: ['', Validators.required],
      isMedical: [false],
      cellPhone: [],
      jobOriginAddress: ['',Validators.required],
      jobOriginLatitude: [''],
      jobOriginLongitude: [''],
      jobDestinationAddress: ['',Validators.required],
      jobDestinationLatitude: [''],
      jobDestinationLongitude: [''],
      jobDestinationAddressReturn: [''],
      jobOriginAddressReturn: [''],
      returnScheduleTime: [''],
      jobOriginLatitudeReturn: [''],
      jobDestinationLatitudeReturn: [''],
      jobOriginLongitudeReturn: [''],
      jobDestinationLongitudeReturn: [''],
      companyNote: [],
      jobCarType: ['Standard'],
      jobPassengerNumber: [1],
      company: ['', Validators.required],
      returnPickUp: [false],
      companyType: [''],
      memberId: [''],
      clientId: [''],
      tripId: [''],
      isUrgentCare: [false],
      isWheelChair: [false],
      isLongDistance: [false],
      isCarSeat: [false],
      iSschUrgentCare: [false],
      returnTripId: [''],
      tripRequirement: ['AMB', Validators.required],
      scheduleTime: ['', Validators.required],
    });
    this.formControlConditionalValidation();
    this.assignService.getCompanies().subscribe((res: any) => {
      this.companies = res.companies;
      //console.log(this.companies);
    });

  }
  totalPassangers = Array.from(Array(4).keys());
  getPassangersNumber(passngers) {
    this.totalPassangers = Array.from(Array(passngers).keys());
  }
  get form() { return this.tripForm.controls };

  formControlConditionalValidation() {
    let isMedical = this.tripForm.get('isMedical');
    this.tripForm.get('assignType').valueChanges.subscribe((type: string) => {
      // //console.log(type);
      if (type === 'normal') {
        isMedical.setValue(false);
        this.tripForm.get('companyType').clearValidators();
        this.tripForm.get('memberId').clearValidators();
        // this.tripForm.get('clientId').clearValidators();
        // this.tripForm.get('tripId').clearValidators();

      } else if (type === 'medical') {
        isMedical.setValue(true);
        this.tripForm.get('companyType').setValidators(Validators.required);
        this.tripForm.get('memberId').setValidators(Validators.required);
        // this.tripForm.get('clientId').setValidators(Validators.required);
        // this.tripForm.get('tripId').setValidators(Validators.required);
      }
    });
    this.tripForm.get('returnPickUp').valueChanges.subscribe((returnPickUp: Boolean) => {
      const returnTripId = this.tripForm.get('returnTripId');
      const returnScheduleTime = this.tripForm.get('returnScheduleTime');
      let jobOriginAddressReturn = this.tripForm.get('jobOriginAddressReturn');
      let jobDestinationAddressReturn = this.tripForm.get('jobDestinationAddressReturn');
      let jobOriginLatitudeReturn = this.tripForm.get('jobOriginLatitudeReturn');
      let jobDestinationLatitudeReturn = this.tripForm.get('jobDestinationLatitudeReturn');
      let jobOriginLongitudeReturn = this.tripForm.get('jobOriginLongitudeReturn');
      let jobDestinationLongitudeReturn = this.tripForm.get('jobDestinationLongitudeReturn');

      if (returnPickUp === true) {
        const jobOriginAddress = this.tripForm.get('jobOriginAddress');
        const jobDestinationAddress = this.tripForm.get('jobDestinationAddress');
        const jobOriginLatitude = this.tripForm.get('jobOriginLatitude');
        const jobDestinationLatitude = this.tripForm.get('jobDestinationLatitude');
        const jobOriginLongitude = this.tripForm.get('jobOriginLongitude');
        const jobDestinationLongitude = this.tripForm.get('jobDestinationLongitude');

        jobOriginAddressReturn.setValue(jobDestinationAddress.value);
        jobDestinationAddressReturn.setValue(jobOriginAddress.value);
        jobOriginLatitudeReturn.setValue(jobDestinationLatitude.value);
        jobOriginLongitudeReturn.setValue(jobDestinationLongitude.value);
        jobDestinationLatitudeReturn.setValue(jobOriginLatitude.value);
        jobDestinationLongitudeReturn.setValue(jobOriginLongitude.value);
        jobOriginAddressReturn.setValidators(Validators.required);
        jobDestinationAddressReturn.setValidators(Validators.required);
        returnScheduleTime.setValidators(Validators.required);
        this.pickupElemReturn.addresstext.nativeElement.value = jobOriginAddress.value;
        this.dropoffElemReturn.addresstext.nativeElement.value = jobDestinationAddress.value;
        if(isMedical.value === true){
          returnTripId.setValidators(Validators.required);
        } else {
          returnTripId.clearValidators();
        }
      } else if (returnPickUp === false) {
        jobOriginAddressReturn.clearValidators()
        jobDestinationAddressReturn.clearValidators();
        returnTripId.clearValidators();
        returnScheduleTime.clearValidators();
      }
      jobOriginAddressReturn.updateValueAndValidity()
      jobDestinationAddressReturn.updateValueAndValidity();
      returnTripId.updateValueAndValidity();
      returnScheduleTime.updateValueAndValidity();
    });
  }
  onSubmit() {
    //console.log(this.tripForm.controls);
    this.submitted = true;
    this.tripForm.value.scheduleTime = moment(this.tripForm.value.scheduleTime).toISOString();
    this.tripForm.value.appointmentTime = moment(this.tripForm.value.appointmentTime).toISOString();

    if (this.tripForm.value.returnPickUp === true) {
      this.tripForm.value.returnScheduleTime = moment(this.tripForm.value.returnScheduleTime).toISOString();
    }
    console.log(this.form)
    if (this.tripForm.valid) {
      if(this.assignId === '' || this.assignId === null){
        this.assignService.createTrip(this.tripForm.value).subscribe((data: any) => {
          console.log(data);
          if (data.success === true) {
            appAlert({
              icon: 'success',
              title: 'Trip Created',
              text: 'Trip Created Successfully'
            });
            this.router.navigate(['']);
          }
        });
      }
      if(this.assignId){
        this.assignService.postEditTrip(this.trip._id, this.tripForm.value).subscribe((data: any) => {
          console.log(data);
          if (data.success === true) {
            appAlert({
              icon: 'success',
              title: 'Trip Edited',
              text: 'Trip edited Successfully'
            });
            this.router.navigate([data.assign._id +'/details']);
          }
        });
      }
    }
    return;
  }
  getAddress(input, $event) {
    if (input === 'pickup') {
      this.tripForm.get('jobOriginAddress').setValue($event.formatted_address);
      this.tripForm.get('jobOriginLatitude').setValue($event.geometry.location.lat());
      this.tripForm.get('jobOriginLongitude').setValue($event.geometry.location.lng());
    }
    if (input === 'dropoff') {
      this.tripForm.get('jobDestinationAddress').setValue($event.formatted_address);
      this.tripForm.get('jobDestinationLatitude').setValue($event.geometry.location.lat());
      this.tripForm.get('jobDestinationLongitude').setValue($event.geometry.location.lng());
    }
    if (input === 'returnPickUp') {
      this.tripForm.get('jobOriginAddressReturn').setValue($event.formatted_address);
      this.tripForm.get('jobOriginLatitudeReturn').setValue($event.geometry.location.lat());
      this.tripForm.get('jobOriginLongitudeReturn').setValue($event.geometry.location.lng());
    }
    if (input === 'returnDropoff') {
      this.tripForm.get('jobDestinationAddressReturn').setValue($event.formatted_address);
      this.tripForm.get('jobDestinationLatitudeReturn').setValue($event.geometry.location.lat());
      this.tripForm.get('jobDestinationLongitudeReturn').setValue($event.geometry.location.lng());
    }
  }
  clearForm() {
    this.tripForm.reset();
    // console.log(this.pickupElem.addresstext.nativeElement);
    // console.log(this.dropoffElem.addresstext);
    this.pickupElem.addresstext.nativeElement.value = "";
    this.dropoffElem.addresstext.nativeElement.value = "";
    this.tripForm.get('assignType').setValue('normal');
    this.tripForm.get('jobCarType').setValue('Standard');
    this.tripForm.get('returnPickUp').setValue(false);
    this.tripForm.get('tripRequirement').setValue('AMB');
    this.tripForm.get('jobPassengerNumber').setValue(1);
    this.totalPassangers = Array.from(Array(4).keys());
  }

}

import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SharedDataService } from 'src/app/services/shared/shared-data.service';
import { showHeading } from 'src/app/utils/global-constants';
import { vehicleFieldsList, fillingStatus, getFileNameFromUrl, appAlert } from 'src/app/utils/utils.common';
import { CredentialsService } from '../credentials-service/credentials.service';
declare var $: any;
import * as moment from "moment-timezone";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { StaffService } from 'src/app/services/staff/staff.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-credentials-vehicles',
  templateUrl: './credentials-vehicles.component.html',
  styleUrls: ['./credentials-vehicles.component.css']
})
export class CredentialsVehiclesComponent implements OnInit {
  @Input() company: string;
  status;
  search;
  vehicles;
  selectedVehicle;
  showHeading = showHeading;
  staffList;
  approveForm: FormGroup;

  constructor(
    private activatedRoute: ActivatedRoute,
    private credentialsService: CredentialsService,
    private staffService: StaffService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService
  ) { }

  ngOnInit() {

    this.staffService.getAllStaff().subscribe(arg => {
      this.staffList = arg;
    });

    this.approveForm = this.formBuilder.group({
      preApprovedDate: [''],
      approvedDate: ['', Validators.required],
      approvedBy: ['', Validators.required],
      isApproved: [true],

    });

    this.credentialsService.getVehicles(this.company).subscribe((data: any) => {
      if (data) {
        console.log('GET Vehicles', data)
        this.vehicles = data;
        if (this.vehicles.length > 0) {
          this.currentSelectedItem(this.vehicles[0]);
        }
      }
    });

  }
  get form() {
    return this.approveForm.controls;
  }

  approveDateChange(event) {
    console.log(event);
    const date = moment.utc(event).local().format();
    console.log(date);
    this.approveForm.patchValue({
      approvedDate: date
    })
  }
  isFormValidate() {
    if (this.approveForm.invalid) {
      return false;
    } else {
      return true;
    }
  }
  onSubmit() {

    console.log(this.approveForm.value)
    if (!this.approveForm.invalid) {
      // swal({
      //   title: "Alert!",
      //   text: "Are you sure you want to proceed?",
      //   icon: "warning",
      //   buttons: [
      //     'No',
      //     'Yes'
      //   ],
      //   dangerMode: false,
      // }).then((isConfirm) => {
      //   if (isConfirm) {
      //     this.sendApprovalEmail();
      //   }
      // });
      
      appAlert({
        title: "Alert!",
        text: "Are you sure you want to proceed?",
        icon: "warning",
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        showCancelButton: true
      }).then((result) => {
        if (result.isConfirmed) {
          this.sendApprovalEmail();
        }
      });
    } else {
      console.log('Form Invalid');
    }
  }
  currentSelectedItem(item) {
    this.credentialsService.getVehicleById(item._id).subscribe((data: any) => {
      if (data) {
          this.selectedVehicle = data;
          this.approveForm.patchValue({
            preApprovedDate: this.selectedVehicle.approvedDate,
            approvedDate: this.selectedVehicle.approvedDate,
            approvedBy: this.selectedVehicle.approvedBy ? this.selectedVehicle.approvedBy : '',
            isApproved: this.selectedVehicle.isApproved
          });
      }
    });
  }
  getFileName(url) {
    return getFileNameFromUrl(url);
  }
  updateVehicle(name, value) {
    let object = JSON.parse(JSON.stringify(this.selectedVehicle));
    object[name] = value;
    const errorList = [];
    for (let item of vehicleFieldsList()) {
      for (let sub in item) {
        if (!object[sub]) {
          errorList.push(item[sub]);
        }
      }
    }

    // swal({
    //   title: "Alert!",
    //   text: "Are you sure you want to proceed?",
    //   icon: "warning",
    //   buttons: [
    //     'No',
    //     'Yes'
    //   ],
    //   dangerMode: false,
    // }).then((isConfirm) => {
    //   if (isConfirm) {
        const object1 = {
          [name]: value,
          fillingStatus: errorList.length > 0 ? true : false
        }
        this.sendServerRequest(object1, this.selectedVehicle._id);
    //   }
    // });

  }
  fillingStatus(object) {
    return fillingStatus(object);
  }
  sendServerRequest(object, id) {
    this.credentialsService.updateVehicle(object, id).subscribe((data) => {
      if (data) {
        const objIndex = this.vehicles.findIndex((obj => obj._id === data._id));
        this.vehicles[objIndex] = data;
        this.selectedVehicle = data;
        this.approveForm.patchValue({
          preApprovedDate: this.selectedVehicle.approvedDate,
          approvedDate: this.selectedVehicle.approvedDate,
          approvedBy: this.selectedVehicle.approvedBy ? this.selectedVehicle.approvedBy : '',
          isApproved: this.selectedVehicle.isApproved
        });
        // $.Notification.autoHideNotify('success', 'top right', 'Alert', 'Update Successfully');
        this.toastr.success('Update Successfully','Alert');
      }
    });
  }
  vehicleFieldsList() {
    return vehicleFieldsList();
  }
  sendErrorEmail() {
    const errorList = [];
    for (let item of vehicleFieldsList()) {
      for (let sub in item) {
        if (!this.selectedVehicle[sub]) {
          errorList.push(item[sub]);
        }
      }
    }
    const object = {
      profileMessage: `Vehicle VIN #${this.selectedVehicle.vin} `,
      errorList
    }
    console.log(object);
    this.credentialsService.sendErrorEmail(object, this.company).subscribe((data) => {
      if (data) {
        // $.Notification.autoHideNotify('success', 'top right', 'Alert', 'Send Email Successfully');
        this.toastr.success('Send Email Successfully','Alert');
      }
    });
  }
  sendApprovalEmail() {
    const object = {
      ...this.approveForm.value,
      isApproved: true,
      _id: this.selectedVehicle._id,
      quarterUser: this.staffList.find(e => e._id === this.approveForm.value.approvedBy).displayName,
      profileMessage: `Vehicle VIN #${this.selectedVehicle.vin} `,
      method :'alivifleet' // alivicompany alividriver alivifleet
    }
    console.log(object);
    this.credentialsService.sendApprovalEmail(object, this.company).subscribe((data) => {
      if (data) {
        const objIndex = this.vehicles.findIndex((obj => obj._id === data._id));
        this.vehicles[objIndex] = data;
        this.selectedVehicle = data;
        // $.Notification.autoHideNotify('success', 'top right', 'Alert', 'Send Email Successfully');
        this.toastr.success('Send Email Successfully','Alert');
      }
    });
  }
}


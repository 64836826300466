import { Component, OnInit } from "@angular/core";
import { FormArray } from "@angular/forms";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ThresholdService } from "src/app/services/Threshold-Service/threshold.service";
import { showHeading } from "src/app/utils/global-constants";
import { ToastrService } from "ngx-toastr";
@Component({
  selector: "app-threshold",
  templateUrl: "./threshold.component.html",
  styleUrls: ["./threshold.component.css"],
})
export class ThresholdComponent implements OnInit {
  showHeading = showHeading;
  companyGroupData: FormGroup;
  thresholdCompanies = [];
  providerthresholdFormArray;
  search = "";
  constructor(
    private thresholdService: ThresholdService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.companyGroupData = this.formBuilder.group({
      providerthreshold: this.formBuilder.array([]),
    });
    this.providerthresholdFormArray = this.companyGroupData.controls
      .providerthreshold as FormArray;

    this.thresholdService.getProviderThresholds().subscribe((data: any) => {
      console.log("Responce Data: ", data);

      for (let i = 0; i < data.length; i++) {
        this.providerthresholdFormArray.push(
          this.formBuilder.group({
            _id: [""],
            company: data[i].company,
            maxMilePerTrip: [""],
            radius: [""],
            totalFare: [""],
            farePerTrip: [""],
            totalMiles: [""],
            totalTrips: [0],
          })
        );
        for (let val of data[i].providerthreshold) {
          this.providerthresholdFormArray.at(i).patchValue({
            ...val,
          });
        }
      }
      this.thresholdCompanies = data;
    });
  }

  get form() {
    return this.companyGroupData.controls;
  }

  onSubmit(index) {
    let payload = this.providerthresholdFormArray.at(index).value;

    payload = {
      ...payload,
      farePerTrip: ( payload.farePerTrip && payload.farePerTrip >0 ) ? payload.farePerTrip : "0",
      maxMilePerTrip:(  payload.maxMilePerTrip && payload.maxMilePerTrip >0 )? payload.maxMilePerTrip : "0",
      radius: ( payload.radius && payload.radius >0 )? payload.radius : "0",
      totalFare:(  payload.totalFare && payload.totalFare >0 )? payload.totalFare : "0",
      totalMiles:(  payload.totalMiles && payload.totalMiles >0 )? payload.totalMiles : "0",
      totalTrips: ( payload.totalTrips && payload.totalTrips >0 )? payload.totalTrips : "0",
    };

    if (payload._id !== "") {
      this.thresholdService.updateThresholds(payload).subscribe((data: any) => {
        this.toastr.success("Value(s) has been updated.", "Updated");
        this.patchValues(data);
      });
    } else {
      delete payload._id;
      this.thresholdService.createThresholds(payload).subscribe((data: any) => {
        this.toastr.success("Value(s) has been updated.", "Updated");
        this.patchValues(data);
      });
    }
  }

  patchValues(data) {
    this.providerthresholdFormArray.value.forEach((element, index) => {
      if (element._id === data._id) {
        this.providerthresholdFormArray.at(index).patchValue({
          ...data,
        });
      }
    });
  }
}

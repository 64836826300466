import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { errorHandl, setFormData } from 'src/app/utils/network-utils';
import { urls } from 'src/app/utils/url-utils';

@Injectable({
  providedIn: 'root'
})
export class DriverService {
  url = urls.BASE_URL;

  constructor(private http: HttpClient) { }

  getCompanyDrivers(compnayId, status) {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjoiNWZjMDk0YWIyN2Q1NmUyODYxNWY2NzRkIiwiYWNjb3VudFN0YXR1cyI6ImFjdGl2ZSIsInJvbGVzIjoiY29tcGFueSIsImlhdCI6MTYxNzI1NDU1Mn0.8jnQPjveHFqM7GCXrRopTOdxo-keq-n0JLNTuZUG0Ds",
    });

    // let authHeader = new HttpHeaders({
    //   authorization: "JWT " + localStorage.getItem("token"),
    // });

    let options = { headers: authHeader };
    return this.http.get(this.url + "drivers/companies/" + compnayId + '/' + status, options);
  }

  getDriverDetails(driverId) {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjoiNWZjMDk0YWIyN2Q1NmUyODYxNWY2NzRkIiwiYWNjb3VudFN0YXR1cyI6ImFjdGl2ZSIsInJvbGVzIjoiY29tcGFueSIsImlhdCI6MTYxNzI1NDU1Mn0.8jnQPjveHFqM7GCXrRopTOdxo-keq-n0JLNTuZUG0Ds",
    });

    // let authHeader = new HttpHeaders({
    //   authorization: "JWT " + localStorage.getItem("token"),
    // });

    let options = { headers: authHeader };
    return this.http.get(this.url + "drivers/" + driverId, options);
  }

  saveDriver(driver, driverId) {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjoiNWZjMDk0YWIyN2Q1NmUyODYxNWY2NzRkIiwiYWNjb3VudFN0YXR1cyI6ImFjdGl2ZSIsInJvbGVzIjoiY29tcGFueSIsImlhdCI6MTYxNzI1NDU1Mn0.8jnQPjveHFqM7GCXrRopTOdxo-keq-n0JLNTuZUG0Ds",
    });
    // let authHeader = new HttpHeaders({
    //   authorization: "JWT " + localStorage.getItem("token"),
    // });
    let options = { headers: authHeader };

    const formData = setFormData(driver,  'driver');

    if (driverId) {
      delete driver.password;
      return this.http
        .put(this.url + "drivers/" + driverId, formData, options)
        .pipe(catchError(errorHandl));
    } else {
      return this.http
        .post(this.url + "drivers", formData, options)
        .pipe(catchError(errorHandl));
    }
  }

  assignFleet(fleet, driverId): Observable<any> {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjoiNWZjMDk0YWIyN2Q1NmUyODYxNWY2NzRkIiwiYWNjb3VudFN0YXR1cyI6ImFjdGl2ZSIsInJvbGVzIjoiY29tcGFueSIsImlhdCI6MTYxNzI1NDU1Mn0.8jnQPjveHFqM7GCXrRopTOdxo-keq-n0JLNTuZUG0Ds",
    });

    // let authHeader = new HttpHeaders({
    //   authorization: "JWT " + localStorage.getItem("token"),
    // });

    let options = { headers: authHeader };

    return this.http
      .put(this.url + "drivers/generateqr/" + driverId, fleet, options)
      .pipe(catchError(errorHandl));
  }

  unassignFleet(driverId, fleetId): Observable<any> {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjoiNWZjMDk0YWIyN2Q1NmUyODYxNWY2NzRkIiwiYWNjb3VudFN0YXR1cyI6ImFjdGl2ZSIsInJvbGVzIjoiY29tcGFueSIsImlhdCI6MTYxNzI1NDU1Mn0.8jnQPjveHFqM7GCXrRopTOdxo-keq-n0JLNTuZUG0Ds",
    });

    // let authHeader = new HttpHeaders({
    //   authorization: "JWT " + localStorage.getItem("token"),
    // });

    let options = { headers: authHeader };

    return this.http
      .post(this.url + "drivers/generateqr/" + driverId, { fleetId: fleetId }, options)
      .pipe(catchError(errorHandl));
  }

  getDriverFleet(driverId) {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjoiNWZjMDk0YWIyN2Q1NmUyODYxNWY2NzRkIiwiYWNjb3VudFN0YXR1cyI6ImFjdGl2ZSIsInJvbGVzIjoiY29tcGFueSIsImlhdCI6MTYxNzI1NDU1Mn0.8jnQPjveHFqM7GCXrRopTOdxo-keq-n0JLNTuZUG0Ds",
    });

    // let authHeader = new HttpHeaders({
    //   authorization: "JWT " + localStorage.getItem("token"),
    // });

    let options = { headers: authHeader };
    return this.http.get(this.url + "fleets?driver=" + driverId, options);
  }
}

import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { urls } from "src/app/utils/url-utils";

@Injectable({
  providedIn: "root",
})
export class ThresholdService {
  url = urls.BASE_URL;

  constructor(private http: HttpClient) {}

  getProviderThresholds() {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("token"),
    });
    let options = { headers: authHeader };
    return this.http.get(this.url + "providerthresholds", options);
  }

  updateThresholds(payload) {
    console.log(payload);
    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("token"),
    });
    let options = { headers: authHeader };
    return this.http.put(
      this.url + "providerthresholds/" + payload._id,payload,
      options
    );
  }
  createThresholds(payload) {
    console.log(payload);
    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("token"),
    });
    let options = { headers: authHeader };
    return this.http.post(
      this.url + "providerthresholds",payload,
      options
    );
  }
}

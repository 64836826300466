export const fares = {
  for: "company",
  additionalPassengerCharges: 2,
  waitTime: {
    after: 60,
    per: 30,
    fare: 4,
  },
  perMin: 0.5,
  cancelCharges: 5,
  minimumFare: 5,
  perMile: 1,
  zones: [],
  baseFare: {
    for: 0,
    fare: 5,
  },
  pickupSurcharge: {
    after: 1000,
    per: 1000,
    fare: 0,
  }
};

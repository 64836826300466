import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  Renderer2,
  AfterViewInit,
} from "@angular/core";
import { AssignService } from "../../services/assign/assign.service";
import * as moment from "moment-timezone";
import { DatePipe } from "@angular/common";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { SharedDataService } from "src/app/services/shared/shared-data.service";
import { DownloadService } from "src/app/services/download/download.service";
import { SocketService } from "src/app/services/socket/socket.service";
declare var Custombox: any;
import { UniquePipe } from "ngx-pipes";
declare var $: any;
import { showHeading } from "src/app/utils/global-constants";
import { getBrokersList } from "src/app/utils/utils.common";
import { ToastrService } from "ngx-toastr";
import { CompanyService } from "src/app/services/company/company.service";
@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"],
  providers: [UniquePipe],
})
export class HomeComponent implements OnInit, AfterViewInit {
  @ViewChild("date", { static: true }) date_v: ElementRef;
  @ViewChild("audioOption", { static: true }) audioPlayerRef: ElementRef;
  providerTime = moment().toISOString();
  assigns: any;
  datePipeEn = new DatePipe("en-US");
  searchForm: FormGroup;
  currentUser: any;
  searchCompanyType = "";
  submitted = false;
  companyType: any;
  filteredAssigns: any;
  emptySearch: boolean;
  searchCompany = '';
  searchText: string = '';
  companies: any;
  companyTypes = getBrokersList;
  intervalTimeID;
  userPlatform: any;
  filterOpen = false;
  view = "list";
  showHeading = showHeading;
  assignKey;
  companiesByKey;
  constructor(
    private assignService: AssignService,
    private uniquePipe: UniquePipe,
    private renderer: Renderer2,
    private formBuilder: FormBuilder,
    private sharedData: SharedDataService,
    private socketService: SocketService,
    private toastr: ToastrService,
    private companyService: CompanyService
  ) {
    //this.socketService.Socket();
  }
  ngAfterViewInit(): void {
    this.initJQuery();
  }
  ngOnInit() {
    this.intervalTimeID = setInterval(() => {
      this.providerTime = moment().toISOString();
    }, 1000);

    this.sharedData.geteMarkReadyStatus().subscribe((data) => {
      if (this.assigns !== undefined && data !== null) {
        let index = this.assigns.findIndex((assign) => assign._id == data._id);
        if (index && this.assigns[index])
          this.assigns[index].isReadyForPickup = data.isReadyForPickup;
      }
    });
    this.searchForm = this.formBuilder.group({
      search_date: ["", Validators.required],
      search_tripId: ["", Validators.required],
      search_name: ["", Validators.required],
      search_phone: ["", Validators.required],
    });
    this.sharedData.getUser().subscribe((data) => {
      if (data && data.platform) {
        this.userPlatform = data.platform;
      }
      this.currentUser = data;
      //  this.fetchDetails();
    });

    this.getAssignedJob();
    this.getCompanies();
  }

  getCompanies() {
    this.companyService
      .getAllCompanies('active', true)
      .subscribe((data: any) => {
        if (data && data.length) {
          this.companies = data;
          this.companiesByKey = data.reduce((obj, item) => (obj[item._id] = item, obj), {});
        }
      });
  }

  getAssignedJob() {
    this.sharedData
      .getAssignedJob()
      .subscribe(trip => {
        if (trip) {
          if (this.assigns && this.assigns.length && trip && this.assignKey) {
            const idx = this.assigns.findIndex((assign) => assign._id == trip._id);
            this.assigns[idx][this.assignKey] = trip[this.assignKey];
            if (this.assignKey === 'isConfirmedTrip') {
              this.assigns[idx].isVoiceMail = trip.isVoiceMail;
            } else if (this.assignKey === 'isVoiceMail') {
              this.assigns[idx].isConfirmedTrip = trip.isConfirmedTrip;
            }
            // console.log('updateAssignedJob after', this.assigns[idx]);
          }
        }
      });
  }

  fetchDetails() {
    const todayDate = moment().startOf("day").toISOString();
    this.searchForm.patchValue({
      search_date: moment(todayDate)
        .tz(this.currentUser.timeZone, true)
        .toISOString(),
    });
    if (this.searchForm.value.search_date) {
      setTimeout(() => {
        this.getData();
      }, 0);
    }
    console.log(this.searchForm.value);
  }

  get form() {
    return this.searchForm.controls;
  }
  onSubmit() {
    this.emptySearch = false;
    this.submitted = true;
    if (
      !this.searchForm.value.search_date &&
      !this.searchForm.value.search_tripId &&
      !this.searchForm.value.search_name &&
      !this.searchForm.value.search_phone
    ) {
      // $.Notification.autoHideNotify('error', 'bottom left', 'Empty Search', 'please fill atleast one field to continue search. ')
      this.toastr.error(
        "Please fill atleast one field to continue search.",
        "Empty Search"
      );
      this.onAudioPlay();
      this.emptySearch = true;
      return;
    }
    this.getData();
  }
  clearSearch() {
    this.searchForm.patchValue({
      search_date: "",
      search_tripId: "",
      search_name: "",
      search_phone: "",
    });
    this.searchCompanyType = '';
  }
  getData() {
    this.searchForm.value.search_date = this.datePipeEn.transform(
      this.date_v.nativeElement.value,
      "yyyy-MM-dd"
    );
    // let scheduleTime = moment(this.searchForm.value.search_date).startOf("day").toISOString();
    // const scheduleTime = moment(this.searchForm.value.search_date)
    //   .tz(this.currentUser.timeZone, true)
    //   .toISOString();

    const scheduleTime = moment(this.searchForm.value.search_date)
      .tz(this.searchCompanyType ? this.companiesByKey[this.searchCompanyType].timeZone : this.currentUser.timeZone, true)
      .toISOString();

    let search = {};
    if (this.searchForm.value.search_name) {
      search["displayName"] = this.searchForm.value.search_name;
    }
    if (this.searchForm.value.search_tripId) {
      search["tripId"] = this.searchForm.value.search_tripId;
    }
    if (this.searchForm.value.search_date) {
      search["scheduleTime"] = scheduleTime;
    }
    if (this.searchForm.value.search_phone) {
      search["contactNumber"] = this.searchForm.value.search_phone;
    }

    if (this.searchCompanyType) {
      // Single
      search["companies"] = [this.searchCompanyType];
      search["timeZone"] = this.companiesByKey[this.searchCompanyType].timeZone;
    } else {
      // All
      search["companies"] = this.companies.map(c => c._id);
      search["timeZone"] = this.currentUser.timeZone;
    }

    this.assignService.getDashboardTrips(search).subscribe((data) => {
      this.assigns = data;
      this.searchText = "";
      this.searchCompany = "";
      this.filteredAssigns = data;
      // this.assigns.forEach((element) => {
      //   element.companyType = this.sharedData.mapBrokers(element.companyType);
      // });
      // if (this.filteredAssigns && this.filteredAssigns.length) {
      //   this.filteredAssigns.sort((a, b) => {
      //     return a.company.displayName.localeCompare(b.company.displayName) || <any>new Date(a.scheduleTime) - <any>new Date(b.scheduleTime)
      //   }); // ASC
      // }
      if (this.filteredAssigns && this.filteredAssigns.length) {
        this.filteredAssigns.sort((a, b) => {
          return (this.companiesByKey
            && this.companiesByKey[a.company]
            && this.companiesByKey[b.company]
            && this.companiesByKey[a.company].displayName.localeCompare(this.companiesByKey[b.company].displayName))
            || <any>new Date(b.scheduleTime) - <any>new Date(a.scheduleTime)
        }); // ASC
      }

      // let companies = this.uniquePipe.transform(this.assigns, "company");
      // this.companies = companies.map((elem) => {
      //   return elem.company;
      // });
    });
    this.submitted = false;
  }
  update(value) { }
  initJQuery() {
    // this.addJsToElement("../assets/plugins/bootstrap-datepicker/js/bootstrap-datepicker.min.js").onload = () => { };
    // this.addJsToElement("../assets/pages/jquery.form-pickers.init.js").onload = () => { };

    this.addJsToElement("assets/js/table.js").onload = () => {
      console.log("table loaded in home");
    };
  }

  addJsToElement(src: string): HTMLScriptElement {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = src;
    this.renderer.appendChild(document.body, script);
    return script;
  }

  toggleMarkReady(index) {
    this.socketService.toggleMarkReady(
      this.assigns[index]._id,
      !this.assigns[index].isReadyForPickup
    );
  }

  updateJob(assign, assignKey) {
    this.assignKey = assignKey;
    this.socketService.updateJob(assign, assignKey);
  }

  searchCompanyFilter(companyType) {
    this.searchText = "";
    if (companyType !== "") {
      this.filteredAssigns = this.assigns.filter(
        (x) => x.companyType === this.searchCompany
      );
    } else {
      this.filteredAssigns = this.assigns;
    }
  }
  search(status) {
    this.searchCompany = "";
    this.searchText = status;
    if (this.searchText !== "") {
      this.filteredAssigns = this.assigns.filter((x) => x.status === status);
    } else {
      this.filteredAssigns = this.assigns;
    }
  }
  filterResult() {
    if (this.searchCompany === "" && this.searchText !== "") {
      if (this.searchText === "onway") {
        return (this.filteredAssigns = this.assigns.filter(
          (x) => x.jobStatus === this.searchText
        ));
      }
      this.filteredAssigns = this.assigns.filter(
        (x) => x.status === this.searchText
      );
    }
    if (this.searchText === "" && this.searchCompany !== "") {
      this.filteredAssigns = this.assigns.filter(
        (x) => x.companyType === this.searchCompany
      );
    }
    if (this.searchText !== "" && this.searchCompany !== "") {
      this.filteredAssigns = this.assigns.filter(
        (x) =>
          x.companyType === this.searchCompany &&
          x.status === this.searchText
      );
    }
    if (
      this.searchText === "" &&
      this.searchCompany === ""
    ) {
      this.filteredAssigns = this.assigns;
    }
    // if (this.searchCompanyType !== "") {
    //   this.filteredAssigns = this.filteredAssigns.filter(
    //     (x) => x.company._id === this.searchCompanyType
    //   );
    // }
    if (
      this.searchText === "" &&
      this.searchCompany === ""
    ) {
      this.filteredAssigns = this.assigns;
    }
    // if(this.searchText !== ''){
    //   this.filteredAssigns = this.filteredAssigns.filter( x => (x.status === this.searchText));
    // }
    // if(this.searchCompany !== ''){
    //   this.filteredAssigns = this.filteredAssigns.filter( x => x.companyType === this.companyTypes[this.searchCompany] ));
    // }
  }

  onAudioPlay() {
    this.audioPlayerRef.nativeElement.play();
  }

  toggleView() {
    if (this.view === "list") {
      this.view = "grid";
    } else {
      this.view = "list";
    }
    console.log("View", this.view);
  }

  toggleFilter() {
    this.filterOpen = !this.filterOpen;
  }

  ngOnDestroy() {
    if (this.intervalTimeID) {
      clearInterval(this.intervalTimeID);
    }
  }

  fetchDriver(assign) {
    console.log(assign.tripId)

    this.assignService.getTripDetail(assign._id).subscribe((data: any) => {
      assign.driver = data.driver;
    });

  }
}

import { Component, OnInit } from '@angular/core';
import { SharedDataService } from 'src/app/services/shared/shared-data.service';
import { showHeading } from 'src/app/utils/global-constants';
import {FacilityService} from '../../../services/facility/facility.service';
@Component({
  selector: 'app-failities',
  templateUrl: './failities.component.html',
  styleUrls: ['./failities.component.css']
})
export class FailitiesComponent implements OnInit {
  selectedTab = 'facility';
  facilities: any;
  searchFacility: any
  userPlatform = 'nemt';
  showHeading = showHeading;
  constructor(private facilityService: FacilityService, private sharedData: SharedDataService) { }

  ngOnInit() {
    this.sharedData.getUser().subscribe(data => {
      this.userPlatform = data.platform;
    });
    this.getEnterpriseList('facility');
  }

  getEnterpriseList(profileRole) {
    this.facilityService.getAllFacilities(profileRole)
    .subscribe(arg => {
      this.facilities = arg;
      console.log(arg);
    });
  }

  onTabClick(tab) {
    if (tab === 'facility') {
      this.selectedTab = tab;
      this.getEnterpriseList(tab);
    } else if (tab === 'member') {
      this.selectedTab = tab;
      this.getEnterpriseList(tab);
    }
  }

}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable } from 'rxjs';
import { urls } from "src/app/utils/url-utils";

@Injectable({
  providedIn: 'root'
})
export class SignupRequestsService {

  url = urls.BASE_URL;
  constructor(private http: HttpClient) { }

  getSignupRequestsCount() {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("token")
    });
    
    let options = { headers: authHeader };
    return this.http.get(this.url + "signuprequests/dashboard", options);
  }

  getAllSignupRequests(search) {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("token")
    });
    // , params: search
    let options = { headers: authHeader };
    return this.http.get(this.url + "signuprequests", options);
  }

  approveSignupRequest(id): Observable<any> {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("token")
    });
    let obj = {}
    let options = { headers: authHeader };
    // console.log(search)
    return this.http.post<any>(this.url + "signuprequests/" + id, obj, options);
  }
}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { urls } from 'src/app/utils/url-utils';

@Injectable({
  providedIn: 'root'
})
export class AssignedTripsService {
  url = urls.BASE_URL;

  constructor(private http: HttpClient) { }

  getTrips(companyId) {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjoiNWZjMDk0YWIyN2Q1NmUyODYxNWY2NzRkIiwiYWNjb3VudFN0YXR1cyI6ImFjdGl2ZSIsInJvbGVzIjoiY29tcGFueSIsImlhdCI6MTYxNzI1NDU1Mn0.8jnQPjveHFqM7GCXrRopTOdxo-keq-n0JLNTuZUG0Ds",
    });

    // let authHeader = new HttpHeaders({
    //   authorization: "JWT " + localStorage.getItem("token")
    // });

    let options = { headers: authHeader };

    return this.http.get(this.url + "assigns?isShowAllTrips=false&skip=0&status=unassigned", options);
  }
}

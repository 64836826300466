import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
} from "@angular/core";
import { Observable } from "rxjs";
import { ActivatedRoute } from "@angular/router";
import { TripAssignModalService } from "./trip-assign-modal-service/trip-assign-modal-service.service";
import { SocketService } from "src/app/services/socket/socket.service";
import { SharedDataService } from "src/app/services/shared/shared-data.service";
import { CompanyService } from "src/app/services/company/company.service";
import { appAlert } from "src/app/utils/utils.common";

declare var $;
@Component({
  selector: "app-trip-assign-modal",
  templateUrl: "./trip-assign-modal.component.html",
  styleUrls: ["./trip-assign-modal.component.css"],
})
export class TripAssignModalComponent implements OnInit, OnDestroy {
  @Input() assignDriverModalObj: any;
  @Input() selectedCompany: any;
  @Output() responce = new EventEmitter<string>();
  getDriverListObserver: Observable<any>;
  nearbyDrivers = [];
  getListObserver: Observable<any>;
  selectedDriver;
  searchDriver;
  driverIndex = -1;
  multiAssignTripNote = null;
  constructor(
    private activatedRoute: ActivatedRoute,
    private tripAssignModalService: TripAssignModalService,
    private socketService: SocketService,
    private sharedData: SharedDataService,
    private companyService: CompanyService
  ) {}

  ngOnInit() {
    // console.log('Assign Modal',this.assignDriverModalObj);
    // this.getDriverListObserver
    // .pipe(takeUntil(componentDestroyed(this)))
    // .subscribe((state) => {
    //   if (state.success) {
    //     if (state.type === DriversActionTypes.GET_NEABY_DRIVERS) {
    //       this.nearbyDrivers = state.response.drivers;
    //     } else if (state.type === DriversActionTypes.DRIVER_LIST) {
    //       this.activeDrivers = state.response;
    //     }
    //   } else if (state.errorMessage) {
    //     sweetAlert('Error', state.errorMessage, 'warning', 'OK');
    //   }
    // });

    // this.getListObserver
    // .pipe(takeUntil(componentDestroyed(this)))
    // .subscribe((state) => {
    //   if (state.success) {
    //     if (state.type === AssignTripsActionTypes.ASSIGN_TRIP) {
    //       if (this.requestType === 'Un Assign') {
    //         this.responce.emit('Trip Un Assigned');
    //       } else if (this.requestType === 'Assign') {
    //         this.responce.emit('Trip Assigned');
    //       }
    //     } else if (state.type === AssignTripsActionTypes.ASSIGN_MULTIPLE_TRIPS) {
    //       if (this.requestType === 'Multiple Assign') {
    //         this.responce.emit('All Trips Assigned');
    //       }
    //     }
    //   } else if (state.errorMessage) {
    //     this.responce.emit(state.errorMessage);

    //   }

    // });
    // if (this.componentType !== 'Multiple Assign') {
    //     this.getNearbyDriver(this.assignDriverModalObj.jobOriginLatitude, this.assignDriverModalObj.jobOriginLongitude);
    //   } else {
    //     this.getDriverList();
    //   }
    this.getNearbyDriver(
      this.assignDriverModalObj.jobOriginLatitude,
      this.assignDriverModalObj.jobOriginLongitude
    );
    this.sharedData.geteMarkReadyStatus().subscribe((data) => {
      if (this.assignDriverModalObj && data) {
        if (
          this.assignDriverModalObj.isReadyForPickup !== null &&
          data.isReadyForPickup !== null
        ) {
          if (this.assignDriverModalObj._id === data._id) {
            this.assignDriverModalObj.isReadyForPickup = data.isReadyForPickup;
          }
        }
      }
    });
  }

  getNearbyDriver(lat, lng) {
    const data = {
      company: this.selectedCompany,
      isReassigned: false,
      isNearBy: true,
      jobOriginLatitude: lat,
      jobOriginLongitude: lng,
    };
    this.tripAssignModalService
      .getNearbyDrivers(data)
      .subscribe((response: any) => {
        this.nearbyDrivers = response.drivers;
      });
  }

  selectDriver(driver) {
    this.selectedDriver = driver;
  }

  updateTrip(type, event?) {
    let data: any = {
      company: this.selectedCompany,
      selectedTrips: [this.assignDriverModalObj._id],
      status: type, // unassigned marketplaced archived cancelled offered
    };
    if (type === "offered") {
      if (this.selectedDriver) {
        data = {
          ...data,
          driver: this.selectedDriver, // send in offered status
          note: this.assignDriverModalObj.note, // optional in offered case
        };
      } else {
        // sweetAlert("Error", "Select Driver", "error", "OK");
        appAlert({
          icon: "error",
          title: "Error",
          text: "Select Driver",
        });
      }
    } else if (type === "cancelled") {
      data = {
        ...data,
        changeReason: event,
      };
    }

    if (type === "offered" && !this.selectedDriver) return;
    this.tripAssignModalService
      .assiginDriver(data)
      .subscribe((response: any) => {
        console.log(response);

        // sweetAlert("Success", "Trip Updated Succesfully", "success").then(
        //   () => {
        //     this.modalClose();
        //   }
        // );

        appAlert({
          title: "Success",
          text: "Trip Updated Succesfully",
          icon: "success",
        }).then((result) => {
          this.modalClose();
        });
      });
  }

  unAssignTrip(status) {
    const data = {
      company: this.selectedCompany,
      selectedTrips: [this.assignDriverModalObj._id],
      status: status, // unassigned marketplaced archived cancelled offered
      // changeReason: ''
    };
    this.tripAssignModalService
      .assiginDriver(data)
      .subscribe((response: any) => {
        console.log(response);
        // sweetAlert("Success", "Trip Updated Succesfully", "success").then(
        //   () => {
        //     this.modalClose();
        //   }
        // );

        appAlert({
          title: "Success",
          text: "Trip Updated Succesfully",
          icon: "success",
        }).then((result) => {
          this.modalClose();
        });
      });
  }

  cancelAssignTrip(event) {
    const data = {
      company: this.selectedCompany,
      selectedTrips: [this.assignDriverModalObj._id],
      status: "cancelled",
      changeReason: event,
    };
    this.tripAssignModalService
      .assiginDriver(data)
      .subscribe((response: any) => {
        console.log(response);
        // sweetAlert("Success", "Trip Updated Succesfully", "success").then(
        //   () => {
        //     this.modalClose();
        //   }
        // );

        appAlert({
          title: "Success",
          text: "Trip Updated Succesfully",
          icon: "success",
        }).then((result) => {
          this.modalClose();
        });
      });
  }

  modalClose() {
    // this.nearbyDrivers = [];
    $("#assignDriverModal .close").click();
    this.responce.emit(null);
  }

  getMiles(driverIndex, driver) {
    this.driverIndex = -1;
    // tslint:disable-next-line: max-line-length
    if (
      this.assignDriverModalObj.jobOriginLatitude &&
      this.assignDriverModalObj.jobOriginLongitude &&
      this.assignDriverModalObj.jobDestinationLatitude &&
      this.assignDriverModalObj.jobDestinationLongitude
    ) {
      const locationParams = {
        origin:
          this.assignDriverModalObj.jobOriginLatitude +
          " , " +
          this.assignDriverModalObj.jobOriginLongitude,
        destination: driver.coords[1] + " , " + driver.coords[0],
        // destination: vm.assign.jobDestinationLatitude + ' , ' + vm.assign.jobDestinationLongitude
      };
      locationParams["dbMiles"] = driver.miles || 0;
      locationParams["isLog"] = true;
      console.log(locationParams);
      // this.socketService.emit(Events.GET_DRIVER_MILES_FOR_ASSIGN_MODAL, locationParams);
      // Socket.emit(‘assignTripManualFormETA’, locationParams);
      this.driverIndex = driverIndex;
    } else {
      // sweetAlert(
      //   "Error",
      //   "Please Select Pickup and DropOff Address",
      //   "warning",
      //   "OK"
      // );

      appAlert({
        icon: "warning",
        title: "Error",
        text: "Please Select Pickup and DropOff Address",
      });
    }
  }

  toggleMarkReady() {
    this.socketService.toggleMarkReady(
      this.assignDriverModalObj._id,
      !this.assignDriverModalObj.isReadyForPickup
    );
  }
  fetchEta(trip) {
    this.companyService.getFetchETA(trip._id).subscribe((data: any) => {
      console.log(data);
      this.assignDriverModalObj.etaMessage = data.etaMessage;
    });
  }
  ngOnDestroy(): void {}
}

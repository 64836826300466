import { Component, OnInit, ViewChild, ElementRef, Input, OnChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AssignService } from 'src/app/services/assign/assign.service';
import { SharedDataService } from 'src/app/services/shared/shared-data.service';
import { SocketService } from 'src/app/services/socket/socket.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { showHeading } from 'src/app/utils/global-constants';
import { ToastrService } from 'ngx-toastr';
declare var $: any;
@Component({
  selector: 'app-trip-details',
  templateUrl: './trip-details.component.html',
  styleUrls: ['./trip-details.component.css']
})
export class TripDetailsComponent implements OnInit, OnChanges {
  @Input() assignModalObj: any;
  tripId: any;
  trip: any;
  origin: any;
  destination: any;
  sendSMSForm: FormGroup;
  submitted: true;
  currentUser: any;
  @ViewChild('audioOption', {static: true}) audioPlayerRef: ElementRef;
  showHeading = showHeading;
  constructor(private activatedRoute: ActivatedRoute, private formBuilder: FormBuilder, private assignService: AssignService, private sharedData: SharedDataService, private socketService: SocketService,private toastr: ToastrService) {

  }

  ngOnChanges(data) {
    if (data && data.assignModalObj) {
      // Modal Case
      this.trip = null;
      this.tripId = data.assignModalObj.currentValue._id;
      this.getServerData();
    }
  }

  ngOnInit() {

    if (this.assignModalObj) {
      // Modal Case
      this.tripId = this.assignModalObj._id;
      console.log('Modal Values', this.assignModalObj);
    } else {
      this.tripId = this.activatedRoute.snapshot.paramMap.get('tripId');
    }

    this.getServerData();

    this.sendSMSForm = this.formBuilder.group({
      contactNumber: ['', Validators.required]
    })
    this.sharedData.getUser().subscribe((data: any) => {
      this.currentUser = data;
    });
  }
  getServerData() {

    //this.socketService.Socket();
    this.sharedData.geteMarkReadyStatus().subscribe(data => {
      if (this.trip !== undefined && data !== null) {
        this.trip.isReadyForPickup = data.isReadyForPickup;
      }
    });
    this.assignService.getTripDetail(this.tripId).subscribe((data: any) => {
      this.trip = data;
      console.log(this.trip)
      this.trip.companyType = this.sharedData.mapBrokers(this.trip.companyType);
      this.getDirection();
      this.initForm();
    });
  }
  initForm() {
    this.sendSMSForm = this.formBuilder.group({
      contactNumber: ['', Validators.required]
    })
  }

  getDirection() {
    this.origin = { lat: this.trip.jobOriginLatitude, lng: this.trip.jobOriginLongitude };
    this.destination = { lat: this.trip.jobDestinationLatitude, lng: this.trip.jobDestinationLongitude };
  }
  toggleMarkReady() {
    this.socketService.toggleMarkReady(this.trip._id, !this.trip.isReadyForPickup)
  }
  get form() { return this.sendSMSForm.controls; }

  validateSMS() {
    this.submitted = true;
    if (this.sendSMSForm.invalid) {
      return;
    }
    let params = {
      contactNumber: this.sendSMSForm.value.contactNumber,
      // trackId: this.trip.trackId,
      trackId: this.trip._id,
      latitude: this.trip.driver.latitude,
      longitude: this.trip.driver.longitude,
      jobOriginLatitude: this.trip.jobOriginLatitude,
      jobOriginLongitude: this.trip.jobOriginLongitude,
      jobDestinationLatitude: this.trip.jobDestinationLatitude,
      jobDestinationLongitude: this.trip.jobDestinationLongitude,
      jobStatus: this.trip.jobStatus,
      status: this.trip.status
    };
    this.assignService.sendSms(params).subscribe((data: any) => {
      if (data && data.success) {
        console.log('send sms', data);
        // $.Notification.autoHideNotify('success', 'bottom left', 'SMS Sent','SMS sent Successfully to the member');
        this.toastr.success(data.message,'Alert');
      }else{
        this.toastr.error(data.message,'Alert');

      }
        this.onAudioPlay();

    });

  }

  googleImageURL(latitude, longitude) {
    return `https://maps.googleapis.com/maps/api/staticmap?markers=icon:https://nemtpanel.com/modules/companies/client/img/buttons/normal.png|${latitude},${longitude}&size=160x170&key=AIzaSyC60nn_VZXJTxtmWuIB9SnHGU6USDb1LGo`;
  }

  onAudioPlay() {
    this.audioPlayerRef.nativeElement.play();
  }

}

import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { AssignService } from 'src/app/services/assign/assign.service';
import * as moment from "moment-timezone";
import { DatePipe } from '@angular/common';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { showHeading } from 'src/app/utils/global-constants';
declare var $: any;
@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.css']
})

export class StatisticsComponent implements OnInit {
  to;
  from;
  statistics: any;
  companies: any;
  datePipeEn = new DatePipe("en-US");
  trips: any;
  @ViewChild('date', {static: true}) range: ElementRef;
  interval = null;
  isStartTimer: Boolean = false;
  showHeading = showHeading;
  searchCompany;
  constructor(
    private assignService: AssignService,
    public ngxSmartModalService: NgxSmartModalService
  ) { }

  selectRange() {
    let range = this.range.nativeElement.value;
    let i = range.indexOf("~");
    console.log(range);
    this.to = range.substring(0, i).trim();
    this.from = range.substring(i + 1).trim();
    // this.startTime = moment.tz(to, 'America/New_York').toISOString();
    // this.endTime = moment.tz(from, 'America/New_York').toISOString();
    // console.log(this.startTime, this.endTime);
    // this.to = to;
    // this.from = from;
    // this.to = moment(this.to).format('YYYY-MM-DD HH:mm');
    // this.from = moment(this.from).format('YYYY-MM-DD HH:mm');

    this.assignService.getTripStats(this.to, this.from).subscribe(data => {
      this.statistics = data[0] ? data[0] : {};
      this.companies = this.statistics.companies;
    });
    // if (!this.isStartTimer) {
    //   this.stopTimer();
    //   this.startTimer();
    //   this.isStartTimer = false;
    // }
  }
  ngOnInit() {
    // this.endTime = moment();
    // this.startTime = moment(this.endTime).add(-1, 'week');
    // this.endTime = this.endTime.toISOString();
    // this.startTime = this.startTime.toISOString();
    this.from = moment().format('MM/DD/YYYY');
    this.to = moment(this.from, ['MM/DD/YYYY']).subtract(1, 'week').format('MM/DD/YYYY');
    this.assignService.getTripStats(this.to, this.from).subscribe(data => {
      this.statistics = data[0] ? data[0] : {};
      this.companies = this.statistics.companies;
    });
  }
  filterCompanies(key) {
    this.companies = [];
    if (key) {
      for (let i = 0; i < this.statistics.companies.length; i++) {
        if (this.statistics.companies[i].company !== null) {
          let index = -1;
          index = this.statistics.companies[i].company.displayName.toLowerCase().includes(key.toLowerCase());
          if (index) {
            index = i;
            this.companies.push(this.statistics.companies[index]);
          }
        }
      }
    } else {
      this.companies = this.statistics.companies;
    }
  }

  startTimer() {
    this.interval = setInterval(() => {
      this.selectRange();
    }, 20000)
  }

  stopTimer() {
    if (this.interval) {
      clearInterval(this.interval);
    }
    this.isStartTimer = true;
  }

  ngOnDestroy() {
    this.stopTimer();
    console.log('Destroy Statistics', this.interval);
  }

  openModal(params) {
    console.log('Open Modal', params);
    const payload = {
      timeZone: params.company.timeZone || undefined,
      companyId: params.company._id || undefined,
      start: this.to,
      end: this.from
    };
    this.trips = [];
    this.assignService.getTripStatDetails(payload).subscribe(data => {
      console.log(data);
      this.trips = data;
      this.ngxSmartModalService.getModal('jobModal').open();
    });
  }

  closeModal() {
    this.ngxSmartModalService.getModal('jobModal').close();
  }

}

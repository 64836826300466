import { urls } from "./url-utils";
import { environment } from "../../environments/environment";
import Swal from "sweetalert2";
export function getCompanyLogo(driverCar): string {

  switch (driverCar) {
    case 'Standard':
      return '../assets/images/vehicles/standardcab.png';

    case 'Premium':
      return '../assets/images/vehicles/premiumcab.png';

    case 'SUV':
      return '../assets/images/vehicles/suv.png';
    case 'WAV':
      return '../assets/images/vehicles/wav.png';
    case 'Stretcher':
      return '../assets/images/vehicles/stretcher.png';
    default:
      return '../assets/images/vehicles/fleet-img.png';
  }
}

export function getBrokerImage(brokerName): string {
  switch (brokerName) {
    case 'united':
      return '../assets/images/brokers/SouthETrans.png';

    case 'lcp':
      return '../assets/images/brokers/lcp-logo.png';

    case 'first':
      return '../assets/images/brokers/mtm-logo.jpg';
    case 'logisticscare':
      return '../assets/images/brokers/logisticare.jpg';
    case 'medtrans':
      return '../assets/images/brokers/medtrans-logo.png';
    case 'medicaid':
      return '../assets/images/brokers/medicaid.png';
    case 'amera':
      return '../assets/images/brokers/amera.png';
    case 'veyo':
      return '../assets/images/brokers/veyo-logo.png';
    case 'vapremier':
      return '../assets/images/brokers/vapremier.png';
    case 'mas':
      return '../assets/images/brokers/mas.png';
    case 'epic':
      return '../assets/images/brokers/epic.png';
    case 'onecall':
      return '../assets/images/brokers/onecall.png';
    default:
      return '../assets/images/brokers/MixandMatch.png';
  }
}

export const getBrokersList = {
  'default': 'Default',
  'lcp': 'LCP',
  'first': 'MTM',
  'logisticscare': 'Logisticare',
  'medtrans': 'NMN',
  'united': 'Southeastrans',
  'medicaid': 'Medicaid',
  'amera': 'Amera',
  'veyo': 'Veyo',
  'vapremier': 'Va Premier',
  'mas': 'MAS',
  'epic': 'EPIC',
  'onecall': 'One Call',
  'americanLogistics': 'American Logistics',
  'access2Ride': 'Access2Care',
  'arn': 'Angel Ride Network',
  'medex': 'Medex',
  'callthecar': 'Call The Car',
  'abcinsurance': 'Abc Insurance',
  'welltrans': 'Welltrans',
  'modivcare': 'Modivcare',
  'tncarrier': 'Tennessee Carriers',
  'caloptima': 'CalOptima',
  'iehp': 'IEHP',
  'providearide': 'Provide A Ride',
  'ride2md': 'Ride2MD',
  'saferide': 'Safe Ride',
  'wellmed': 'WellMed'
};

export function loadStaticMap(lat, lng) {
  const mapKey = 'AIzaSyC60nn_VZXJTxtmWuIB9SnHGU6USDb1LGo';
  let url = 'https://maps.googleapis.com/maps/api/staticmap?center='
    + lat + ',' + lng + '&zoom=14&size=130x150&markers=color:red|' + lat
    + ',' + lng + '&key=' + mapKey;
  return url;
}

export function getOnboardUrl(platform): string {
  if (!environment.production) {
    return urls.DEV_ONBOARD_URL;
  }
  switch (platform) {
    case 'nemt':
      return urls.NEMT_ONBOARD_URL;
    case 'medex':
      return urls.MEDEX_ONBOARD_URL;
    case 'arn':
      return urls.ARN_ONBOARD_URL;
    default:
      return '';
  }
}


export function getKeys(data): any {
  return Object.keys(data);
}


export function getFileNameFromUrl(url) {
  if (url && url !== '') {
    const urlSegments = url.split('/');
    if (urlSegments)
      return decodeURI(urlSegments[urlSegments.length - 1]);
    else
      return '';
  } else {
    return '';
  }
}
export function fillingStatus(object) {
  let status = true;
  for (const key in object) {
    // Skip the keys which do not start with 'is'
    // Skip the key 'isApproved'
    if (!key.startsWith('is') || key === 'isApproved' || key === 'isAddress_2' || key === 'isDev') continue;

    // Decision based on false value of keys which starts with 'is'
    if (!object[key]) {
      status = false;
      break;
    }
  }
  return status;
}

export function driverFieldsList () {
  return [
    { isFirstName: "First Name" },
    { isLastName: "Last Name" },
    { isCity: "City" },
    { isAddress_1: "Address 1" },
    { isPhoneNumber: "Cell Phone Number" },
    { isEmail: "Email Address" },
    { isDriverLicenseImage: "Front of Driver's License" },
    { isDriverLicenseNumber: "License Number" },
    { isDriverLicenseIssueDate: "License Issue Date" },
    { isDriverLicenseExpireDate: "License Expiration Date" },
    { isDriverLicenseStateIssue: "License State Issued" },
    { isDob: "Date of Birth" },
    { isLanguageSpoken: "Languages Spoken" },
    { isLatestPhyExamImage: "Latest Physical Exam" },
    { isLatestPhyExamDate: "Latest Physical Exam Completion Date" },
    { isDrugTestImage: "Drug Test" },
    { isDrugTestDate: "Drug Test Date" },
    { isAbuse: "Have you been convicted for child abuse or any felonies in the past 5 years?" },
    { isViolations: "Have you had any motor vehicle moving violations in the last 3 years?" },
    { isCountiesServed: "Counties Served" }
  ]
}
export function vehicleFieldsList () {
  return [
    { isDescription: "Description" },
    { isVin: "VIN" },
    { isLicensePlateNumber: "License Plate Number" },
    { isYear: "Year" },
    { isMake: "Make" },
    { isModel: "Model" },
    { isColor: "Color" },
    { isVehicleType: "Vehicle Type" },
    { isAmbulatoryCapacity: "Ambulatory Passenger Capacity" },
    { isWheelchairCapacity: "Wheelchair Passenger Capacity" },
    { isStretcherCapacity: "Stretcher Passenger Capacity" },
    { isGaragedStreetAddress: "Garaged Street Address" },
    { isCity: "City" },
    { isState: "State" },
    { isCountiesServed: "Counties Served" },
    { isRegistrationImage: "Registration" },
    { isRegistrationDate: "Registration Expiration Date" },
    { isInspectionReportImage: "Inspection Report" },
    { isInspectionReportDate: "Inspection Report Completion Date" },
    { isVehiclePicture: "Vehicle Picture" },
]
}

export function companyFieldsList () {
  return [
    { isCompanyName: "Company Name" },
    { isCity: "City" },
    { isZip: "ZIP" },
    { isAddress_1: "Address 1" },
    { isOfficePhoneNumber: "Office Phone Number" },
    { isSecondaryPhoneNumber: "Secondary Phone Number" },
    { isOfficeEmailAddress: "Office Email Address" },
    { isHoursofOperation: "Hours of Operation" },
    { isFirstName: "First Name" },
    { isLastName: "Last Name" },
    { isPhoneNumber: "Phone Number" },
    { isEmailAddress: "Email Address" },
    { isHIPAATrainingCertificateImage: "HIPAA Training Certificate" },
    { isHIPAATrainingDate: "HIPAA Training Completion Date" },
    { isFraudWasteAndAbuseCertificateImage: "Fraud, Waste, And Abuse Training Certificate" },
    { isFraudWasteAndAbuseCertificateDate: "Fraud, Waste and Abuse Training Completion Date" },
    { isDriverLicenseImage: "Upload Driver License" },
    { isNonDisclosureAgreementImage: "Signed Non-Disclosure Agreement" },
    { isTransportationProviderAgreementImage: "Signed Transportation Provider Agreement" },
    { isTaxIDNumber: "Tax-ID Number (EIN)" },
    { isW9Image: "W-9" },
    { isDisclosureofOwnershipImage: "Disclosure of Ownership" },
    { isEFTFormImage: "EFT Form" },
    { isVoidedCheckImage: "Voided Check" },
    { isGeneralLiabilityInsuranceImage: "Certificate of General Liability Insurance" },
    { isGeneralLiabilityInsuranceDate: "General Liability Insurance Expiration Date" },
    { isDriverScheduleImage: "Driver Schedule" },
    { isAutoLiabilityInsuranceImage: "Certificate of Auto Liability Insurance" },
    { isAutoLiabilityInsuranceDate: "Auto Liability Insurance Expiration Date" },
    { isVehicleScheduleImage: "Vehicle Schedule" },
    { isTransportationCompanyName: "Company Name" },
    { isAmbulatoryVehicles: "Do you have Ambulatory Vehicles" },
    { isWheelchairVehicles: "Do you have Wheelchair Vehicles" },
    { isStretcherVehicles: "Do you have Stretcher Vehicles" },
    { isVehicleForHireLicenseImage: "Vehicle for Hire License" },
    { isProvideService: "What Counties do you provide Service" },
    { isBankName: "Bank Name" },
    { isAccountNumber: "Account number" },
    { isRoutingNumber: "Routing number" }
  ]
}

export function appAlert(options) {
  return Swal.fire(options);
}


export function getJobStatus(status) {
  if (status === "pending") {
    return "Unassigned";
  } else  if (status === "arrived") {
    return "On Scene";
  } else if (status === "started") {
    return "Member Onboard";
  } else {
    return status;
  }
}

import { Component, OnInit, ElementRef, ViewChild, Renderer2 } from "@angular/core";
import { AssignService } from "src/app/services/assign/assign.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { SharedDataService } from "src/app/services/shared/shared-data.service";
import { DatePipe } from "@angular/common";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import * as moment from "moment-timezone";
import { DownloadService } from "src/app/services/download/download.service";
import { UniquePipe } from 'ngx-pipes';
import { urls } from "src/app/utils/url-utils";


@Component({
  selector: "app-adjusted-triplogs",
  templateUrl: "./adjusted-triplogs.component.html",
  styleUrls: ["./adjusted-triplogs.component.css"],
  providers: [UniquePipe]

})
export class AdjustedTriplogsComponent implements OnInit {
  assigns: any;
  selectedTrips: any;
  drivers: any;
  user: any;
  submitted = false;
  @ViewChild("date", {static: true}) date_v: ElementRef;
  datePipeEn = new DatePipe('en-US');
  searchForm: FormGroup;
  scheduleTime: any;
  companyType = '';
  companies: any;
  filteredTrips;
  selectedCompany = '';
  selectedDriver = '';
  token: any;
  constructor(private renderer: Renderer2,
    private assignService: AssignService,
    private http: HttpClient,
    private sharedData: SharedDataService,
    private formBuilder: FormBuilder,
    private downloadService: DownloadService,
    private uniquePipe: UniquePipe) {
    this.scheduleTime = moment().startOf("day").toISOString();
    this.sharedData.getUser().subscribe(user => {
      this.user = user;
    });
    this.assignService.getCompanies().subscribe((data: any) => {
      if (data.success === true) {
        this.companies = data.companies;
        console.log(this.companies)
      }
    });
    // this.assignService
    //   .getAdjustedTrips(this.scheduleTime, this.companyType)
    //   .subscribe(data => {
    //     this.assigns = data;
    //     this.assigns.forEach(function (assign) {
    //       assign.isSelected = false;
    //     });
    //     this.filteredTrips = data;

    //     console.log(this.filteredTrips);
    //   });
    this.selectedTrips = [];
  }
  ngOnInit() {
    this.initJQuery();
    this.searchForm = this.formBuilder.group({
      search_date: ['', Validators.required],
      company: ['All', Validators.required],
      driver :['']
    })
  }
  selectDriver(){
    console.log(this.selectedDriver)
  }
  companyFilter(selectedCompany) {
    console.log(this.selectedCompany)
    this.assignService.getCompanyToken(this.selectedCompany).subscribe((data: any) => {
      console.log(data);
      localStorage.setItem('companyToken', data.companyToken);
      localStorage.setItem('companyID', this.selectedCompany);
    });
  }
  get form() { return this.searchForm.controls; }
  onSubmit() {
    this.searchForm.value.search_date = this.datePipeEn.transform(this.date_v.nativeElement.value, 'yyyy-MM-dd');
    var scheduleTime = moment(this.searchForm.value.search_date).startOf("day").toISOString();
    this.submitted = true;
    if (!this.searchForm.value.search_date) {
      return;
    }
    this.getDataOfDate(scheduleTime, this.companyType, this.selectedCompany);
  }

  filterTrips(key, value) {
    this.filteredTrips = this.assigns.filter(function (assign) {
      console.log(assign[key])

      return assign[key] === value;
    });
  }
  selectBrokerType(companyType) {
    // console.log(this.searchForm.value.search_date);
    this.searchForm.value.search_date = this.datePipeEn.transform(this.date_v.nativeElement.value, 'yyyy-MM-dd');
    var scheduleTime = moment().startOf("day").toISOString();
    if (this.searchForm.value.search_date) {
      scheduleTime = moment(this.searchForm.value.search_date).startOf("day").toISOString();
    }
    if (this.companyType === companyType) {
      this.companyType = '';
      return false;
    }
    this.companyType = companyType;
    // this.filterTrips('companyType', this.companyType);
    this.getDataOfDate(scheduleTime, this.companyType, this.selectedCompany);
  }
  getDataOfDate(scheduleTime, companyType, company) {
    if (this.selectedCompany === '') {
      return;
    }
    this.scheduleTime = scheduleTime;
    console.log(this.scheduleTime)
    this.submitted = false;
    this.assignService.getAdjustedTrips(scheduleTime, companyType).subscribe(data => {
      this.assigns = data;
      this.assigns.forEach(function (assign) {
        assign.isSelected = false;
      });

      this.filteredTrips = this.assigns;
      let drivers = this.uniquePipe.transform(this.filteredTrips, 'driver');
      this.drivers = drivers.map(trip =>{
        return trip.driver;
      });
      console.log(this.drivers);
      console.log(data);
    });
  }
  update(date) {
    // this.searchForm.value.search_date = this.datePipeEn.transform(date, 'yyyy-MM-dd');
    var scheduleTime = moment(date).startOf("day").toISOString();
    this.getDataOfDate(scheduleTime, this.companyType, this.selectedCompany);
  }
  selectTrips(assign, index) {
    console.log(index);
    var assignIndex = this.selectedTrips.indexOf(assign);
    this.assigns[index].isSelected = !this.assigns[index].isSelected;
    if (assignIndex < 0) {
      this.selectedTrips.push(this.assigns[index]._id);
    } else {
      this.selectedTrips.splice(assignIndex, 1);
    }
    console.log(this.selectedTrips);
  }
  clearSelection() {
    this.selectedTrips = [];
    this.assigns.forEach(assign => {
      assign.isSelected = false;
    });
    console.log(this.selectedTrips)
  }
  selectAll() {
    console.log("Selected Trips ", this.selectedTrips.length);
    console.log("Total Trips ", this.assigns.length);

    if (this.selectedTrips.length === this.assigns.length) {
      this.clearSelection();
      console.log(this.selectedTrips);
    } else {
      this.selectedTrips = [];
      this.assigns.forEach(assign => {
        assign.isSelected = true;
        this.selectedTrips.push(assign._id);
      });
      console.log(this.selectedTrips);
    }
  }

  initJQuery() {
    this.addJsToElement(
      "../assets/plugins/bootstrap-datepicker/js/bootstrap-datepicker.min.js"
    ).onload = () => { };
    this.addJsToElement(
      "../assets/pages/jquery.form-pickers.init.js"
    ).onload = () => { };

  }
  addJsToElement(src: string): HTMLScriptElement {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = src;
    this.renderer.appendChild(document.body, script);
    return script;
  }


  bankingReport() {
    let obj = {
      companyImage: this.user.profileImageURL,
      scheduleTime: this.scheduleTime,
      type: "adjustedtriplogs",
      _ids: this.selectedTrips
    };
    this.assignService.getBankingReport(obj).subscribe((data: any) => {
      var baseURL = urls.SERVER_URL;
      var name = data.pdfName;
      console.log(data);
      var url = baseURL + "/pdf/" + name;
      this.downloadService.downloadURI(url, name);
    });
  }
  tripsLog() {
    let obj = {
      companyImage: this.user.profileImageURL,
      scheduleTime: this.scheduleTime,
      _ids: this.selectedTrips
    };
    this.assignService.getTripsLog(obj).subscribe((data: any) => {
      var baseURL = urls.SERVER_URL;
      var name = data.pdfName;
      console.log(data);
      var url = baseURL + "/pdf/" + name;
      this.downloadService.downloadURI(url, name);
    });
  }
  logistiCareTriplogs() {
    let obj = {
      type: 'adjustedtriplogs',
      _ids: this.selectedTrips,
      scheduleTime: this.scheduleTime,
      companyImage: ''
    };
    this.assignService.logistiCareTriplogs(this.selectedDriver, obj).subscribe((data: any) => {

      console.log('TripsLog PDF Generation Success', data);
      var baseURL = urls.SERVER_URL;
      var name = data.pdfName;
      var url = baseURL + '/pdf/' + name;
      console.log(url, name);
      this.downloadService.downloadURI(url, name);
    }, err => {
      console.log(err);
    });
  }
  claimFile(scheduleTime = this.scheduleTime) {
    let obj = { type: "adjustedtriplogs", _ids: this.selectedTrips, scheduleTime: scheduleTime, companyImage: this.user.profileImageURL };
    this.assignService.getClaimReport(obj).subscribe((data: any) => {
      console.log('Claim file PDF Generation Success', data);
      var baseURL = urls.SERVER_URL;
      var name = data.excelName;
      var url = baseURL + '/excel/' + name;
      console.log(url, name);
      this.downloadService.downloadURI(url, name);
    });
  }
  manifest() {
    let obj = { type: 'adjustedtriplogs', _ids: this.selectedTrips, scheduleTime: this.scheduleTime, companyImage: this.user.profileImageURL };
    this.assignService.manifest(this.selectedDriver, obj).subscribe((data: any) => {

      console.log('TripsLog PDF Generation Success', data);
      var baseURL = urls.SERVER_URL;
      var name = data.pdfName;
      var url = baseURL + '/pdf/' + name;
      console.log(url, name);
      this.downloadService.downloadURI(url, name);
    },err => {
      console.log(err);
    })
  }
  manifestLcp() {
    let obj = { type: 'adjustedtriplogs', _ids: this.selectedTrips, scheduleTime: this.scheduleTime, companyImage: this.user.profileImageURL };
    this.assignService.manifestLcp(obj).subscribe((data: any) => {

      console.log('TripsLog PDF Generation Success', data);
      var baseURL = urls.SERVER_URL;
      var name = data.pdfName;
      var url = baseURL + '/pdf/' + name;
      console.log(url, name);
      this.downloadService.downloadURI(url, name);
    },err => {
      console.log(err);
    })
  }
  signatureUpdate(isSignature) {
    let obj = {
      companyImage: this.user.profileImageURL,
      scheduleTime: this.scheduleTime,
      type: "adjustedtriplogs",
      _ids: this.selectedTrips,
      isSignature: isSignature
    };
    this.assignService.updateSignature(obj).subscribe((data: any) => {
      console.log(data);
    });
  }

manifestLogistic() {
  let obj = { type: 'adjustedtriplogs', _ids: this.selectedTrips, scheduleTime: this.scheduleTime, companyImage: this.user.profileImageURL };
  this.assignService.manifestAmera(obj).subscribe((data: any) => {

    console.log('TripsLog PDF Generation Success', data);
    var baseURL = urls.SERVER_URL;
    var name = data.pdfName;
    var url = baseURL + '/pdf/' + name;
    console.log(url, name);
    this.downloadService.downloadURI(url, name);
  },err => {
    console.log(err);
  })
}
manifestAmera() {
  let obj = { type: 'adjustedtriplogs', _ids: this.selectedTrips, scheduleTime: this.scheduleTime, companyImage: this.user.profileImageURL };
    this.assignService.manifestAmera(obj).subscribe((data: any) => {

      console.log('TripsLog PDF Generation Success', data);
      var baseURL = urls.SERVER_URL;
      var name = data.pdfName;
      var url = baseURL + '/pdf/' + name;
      console.log(url, name);
      this.downloadService.downloadURI(url, name);
    },err => {
      console.log(err);
    })
}
  mtmTRF() {
    let obj = { type: 'adjustedtriplogs', _ids: this.selectedTrips, scheduleTime: this.scheduleTime, companyImage: this.user.profileImageURLe };
    console.log(this.selectedDriver , obj);
    this.assignService.mtmTRF(this.selectedDriver, obj).subscribe((data: any) =>{
      console.log('TripsLog PDF Generation Success', data);  
      
      var baseURL = urls.SERVER_URL;
      var name = data.pdfName;
      var url = baseURL + '/pdf/' + name;
      console.log(url, name);
      this.downloadService.downloadURI(url, name);
  }, err =>{
    console.log(err);
  });
}
mtmClaim() {
  let obj = { type: 'adjustedtriplogs', _ids: this.selectedTrips, scheduleTime: this.scheduleTime, companyImage: this.user.profileImageURL }
  this.assignService.mtmClaim(obj).subscribe((data:any) => {
    console.log('TripsLog PDF Generation Success', data);
    var baseURL = urls.SERVER_URL;
    var name = data.excelName;
    var url = baseURL + '/excel/' + name;
    console.log(url, name);
    this.downloadService.downloadURI(url, name);

  },err =>{
    console.log(err);
  });
}
 mtmDriverLog() {
  let obj = { type: 'adjustedtriplogs', _ids: this.selectedTrips, scheduleTime: this.scheduleTime, companyImage: this.user.profileImageURL}
  this.assignService.mtmDriverLog(this.selectedDriver, obj).subscribe((data :any) =>{
    console.log('TripsLog PDF Generation Success', data);
    var baseURL = urls.SERVER_URL;
    var name = data.pdfName;
    var url = baseURL + '/pdf/' + name;
    console.log(url, name);
    this.downloadService.downloadURI(url, name);
    
  },err=>{
    console.log(err);
  })
}
  // function bankedTrips(assign, index, statusType, bankStatus) {
  //   var query = { type: vm.status, _ids: vm.selectedTrips, scheduleTime: vm.scheduleTime, companyImage: vm.companyImage, bankStatus: 'banked' };
  //   if (statusType && statusType === 'single') {
  //     query.bankStatus = bankStatus;
  //     query._ids = [assign._id];
  //     query.statusType = 'single';
  //   }
  //   if ($window.confirm('Are you sure you want to perform this action ?')) {

  //     AssignsService.bankedTrips({
  //     }, query,
  //       function (response) {

  //         console.log(response);
  //         $rootScope.isLoading = false;
  //         notification.success({
  //           message: 'Successfully banked trips',
  //           title: 'Assign trip'
  //         });
  //         // var baseURL = window.location.origin;
  //         // var name = response.pdfName;
  //         // var url = baseURL + '/pdf/' + name;
  //         // console.log(url, name);
  //         // DownloadFile.downloadURI(name, url);
  //         if (query.statusType === 'single') {
  //           vm.assigns[index].bankStatus = query.bankStatus;
  //         }

  //       }, function (err) {

  //         console.log(err);
  //         vm.error = err.data.message;
  //         $rootScope.isLoading = false;

  //       });
  //   }
  // }


}

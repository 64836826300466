import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { FacilityService } from 'src/app/services/facility/facility.service';
import { appAlert } from 'src/app/utils/utils.common';

@Component({
  selector: 'app-member-details',
  templateUrl: './member-details.component.html',
  styleUrls: ['./member-details.component.css']
})
export class MemberDetailsComponent implements OnInit {
  documents = [1,2,3,4,5]
  memberId;
  member;
  disapproveReason;
  reasonSubmitted = false;

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private activatedRoute: ActivatedRoute,
    private facilityService: FacilityService,
    private router: Router) { 
      this.memberId = this.activatedRoute.snapshot.paramMap.get('memberId');
    }

  ngOnInit() {
    this.getMemberDetails();
  }

  getMemberDetails() {
    if (this.memberId) {
      this.facilityService
      .getFacilityToEdit(this.memberId)
      .subscribe((data: any) => {
        this.member = data;
        console.log('Member Details: ', this.member);  
      });
    }
  }

  approveMember() {
    const payload = {
      step: 'completed',
      disapprovedReason: ''
    }
    this.facilityService.editCompanyNew(this.memberId, payload, null)
      .subscribe(
        (data: any) => {
          console.log(data);
          this.router.navigate(['/facilities']);
        },
        (error) => {
          appAlert({
            icon: 'error',
            title: 'Error',
            text: error
          });
        });
  }

  disapproveMember() {
    this.ngxSmartModalService.get('memberDisapproveModal').open();
  }

  submitDisapproveReason() {
    this.reasonSubmitted = true;
    if (!this.disapproveReason) {
      return;
    }
    const payload = {
      step: 'disapproved',
      disapprovedReason: this.disapproveReason
    }
    this.facilityService.editCompanyNew(this.memberId, payload, null)
      .subscribe(
        (data: any) => {
          console.log(data);
          this.router.navigate(['/facilities']);
        },
        (error) => {
          appAlert({
            icon: 'error',
            title: 'Error',
            text: error
          });
        });

  }

  getPayorType(payorType) {
    if (payorType === 'medicare') {
      return 'Medicare';
    } else if (payorType === 'medicaid') {
      return 'Medicaid';
    } else if (payorType === 'tricare') {
      return 'Tricare';
    } else if (payorType === 'champva') {
      return 'Champva';
    } else if (payorType === 'grouphealthplan') {
      return 'Group Health Plan';
    } else if (payorType === 'fecablklung') {
      return 'Feca Blk Lung'
    } else if (payorType === 'other') {
      return 'Other';
    }
  }
}

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule,FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavComponent } from './components/partials/nav/nav.component';
import { SidebarComponent } from './components/partials/sidebar/sidebar.component';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { TimePipe } from './pipes/time.pipe';
import { ProfileComponent } from './components/profile/profile.component';
import { ResetPasswordComponent } from './components/partials/reset-password/reset-password.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { TripDetailsComponent } from './components/trip-details/trip-details.component';
import { AgmCoreModule, GoogleMapsAPIWrapper } from '@agm/core';
import { AgmDirectionModule } from 'agm-direction';
import { MapViewComponent } from './components/map-view/map-view.component';
import { StatisticsComponent } from './components/statistics/statistics.component';
import { AgmOverlays } from "agm-overlays";
import { SearchPipe } from './pipes/search/search.pipe';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { AutocompleteComponent } from './components/partials/autocomplete/autocomplete.component';
import { AgmJsMarkerClustererModule } from '@agm/js-marker-clusterer';
import {NgPipesModule} from 'ngx-pipes';
import { NgxSelectModule } from 'ngx-select-ex';
import { NewDispatchTripComponent } from './components/new-dispatch-trip/new-dispatch-trip.component';
import { ExcelParserComponent } from './components/excel-parser/excel-parser.component';
import { MarketplaceComponent } from './components/marketplace/marketplace.component';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { MarketplaceUnassignedComponent } from './components/marketplace-unassigned/marketplace-unassigned.component';
import { NewCompanyComponent } from './components/companies/new-company/new-company.component';
import { CompaniesComponent } from './components/companies/companies/companies.component';
import { ManifestComponent } from './components/manifest/manifest.component';
import { MarketplaceStatComponent } from './components/marketplace-stat/marketplace-stat.component';
import { TimezonePickerModule } from 'ng2-timezone-selector';
import { AdjustmentModalComponent } from './components/partials/adjustment-modal/adjustment-modal.component';
import { CompletedTripsComponent } from './components/completed-trips/completed-trips.component';
import { AdjustedTriplogsComponent } from './components/adjusted-triplogs/adjusted-triplogs.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { FailitiesComponent } from './components/facilities/failities/failities.component';
import { NewFacilityComponent } from './components/facilities/new-facility/new-facility.component';
import { FacilityTripsComponent } from './components/facility-trips/facility-trips/facility-trips.component';
import { NewFacilityTripsComponent } from './components/facility-trips/new-facility-trips/new-facility-trips.component';
import { FacilityTripsDetailsComponent } from './components/facility-trips/facility-trips-details/facility-trips-details.component';
import { FacilityTripsPreviewComponent } from './components/facility-trips/facility-trips-preview/facility-trips-preview.component';
import { FacilityTripsCountComponent } from './components/facility-trips/facility-trips-count/facility-trips-count.component';
import { FacilityTripsLogsComponent } from './components/facility-trips/facility-trips-logs/facility-trips-logs.component';
import { SignupRequestsComponent } from './components/signup-requests/signup-requests/signup-requests.component';
import { FacilityReportsComponent } from './components/reports/facility-reports/facility-reports.component';
import { CompanyReportsComponent } from './components/reports/company-reports/company-reports.component';
import { MemberReportsComponent } from './components/reports/member-reports/member-reports.component';
import { FacilityTimePipe } from './pipes/facility-time-pipe/facility-time-pipe';
import { GooglePlaceDirective } from './directives/google-places-directive/google-place.directive';
import { NewStaffComponent } from './components/staff/new-staff/new-staff.component';
import { StaffComponent } from './components/staff/staff/staff.component';
import { FareComponent } from './components/signup-requests/fare/fare/fare.component';
import { NewFareComponent } from './components/signup-requests/fare/new-fare/new-fare.component';
import { OfferedFaresComponent } from './components/companies/offered-fares/offered-fares/offered-fares.component';
import { NewOfferedFareComponent } from './components/companies/offered-fares/new-offered-fare/new-offered-fare.component';
import { InviteCompaniesComponent } from './components/invite-companies/invite-companies/invite-companies.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import {SortByPipe}  from './pipes/order-by/order-by.pipe';
import { OperationsComponent } from './components/operations/operations.component';
import { ContractorsComponent } from './components/contractors/contractors.component';
import { FileUploadComponent } from './components/partials/file-upload/file-upload.component';
import { CredentialsRequestComponent } from './components/credentials/credentials-request/credentials-request.component';
import { CredentialsDetailsComponent } from './components/credentials/credentials-details/credentials-details.component';
import { CredentialsDriversComponent } from './components/credentials/credentials-drivers/credentials-drivers.component';
import { CredentialsVehiclesComponent } from './components/credentials/credentials-vehicles/credentials-vehicles.component';
import { WrapCredentialsComponent } from './components/credentials/wrap-credentials/wrap-credentials.component';
import { CopyToClipboardDirective } from './directives/copy-to-clipboard/copy-to-clipboard.directive';
import { CompanyNoteModalComponent } from './components/partials/company-note-modal/company-note-modal.component';
import { ToastrModule } from 'ngx-toastr';
import { MomentModule } from 'ngx-moment';
import { TripAssignModalComponent } from './components/partials/trip-assign-modal/trip-assign-modal.component';
import { TripDetailModalComponent } from './components/partials/trip-detail-modal/trip-detail-modal.component';
import { CancelTripModalComponent } from './components/partials/cancel-trip-modal/cancel-trip-modal.component';
import { ProviderDashboardComponent } from './components/provider-section/provider-dashboard/provider-dashboard.component';
import { DriversComponent } from './components/provider-section/drivers/driver-list/drivers.component';
import { FleetsComponent } from './components/provider-section/fleets/fleet-list/fleets.component';
import { AddFleetComponent } from './components/provider-section/fleets/add-fleet/add-fleet.component';
import { AddDriverComponent } from './components/provider-section/drivers/add-driver/add-driver.component';
import { AssignedTripsComponent } from './components/provider-section/assigned-trips/assigned-trips/assigned-trips.component';
import { OfferedTripsComponent } from './components/provider-section/offered-trips/offered-trips/offered-trips.component';
import { AssignVehicleModalComponent } from './components/partials/assign-vehicle-modal/assign-vehicle-modal.component';
import { ThresholdComponent } from './components/threshold/threshold/threshold.component';
import { StandingOrderComponent } from './components/standing-order/standing-order/standing-order.component';
import { NewStandingOrderComponent } from './components/standing-order/new-standing-order/new-standing-order.component';
import { AssignDriverModalComponent } from './components/partials/assign-driver-modal/assign-driver-modal.component';
import { NEMTStatisticsComponent } from './components/nemt-statistics/nemt-statistics.component';
import { AlarmingTripsComponent } from './components/alarming-trips/alarming-trips.component';
import { ApprovedMembersComponent } from './components/members/approved-members/approved-members.component';
import { MemberDetailsComponent } from './components/members/member-details/member-details.component';
import { MemberTripsComponent } from './components/members/member-trips/member-trips.component';
import { NemtCodesListComponent } from './components/nemt-codes/nemt-codes-list/nemt-codes-list.component';
import { AddNemtCodeComponent } from './components/nemt-codes/add-nemt-code/add-nemt-code.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { SortByDatePipe } from './pipes/sort-by-date/sort-by-date.pipe';
import { NumberDirective } from './directives/number-only-directive/number-only-directive';

@NgModule({
  declarations: [
    AppComponent,
    NavComponent,
    SidebarComponent,
    HomeComponent,
    LoginComponent,
    TimePipe,
    FacilityTimePipe,
    GooglePlaceDirective,
    ProfileComponent,
    ResetPasswordComponent,
    TripDetailsComponent,
    MapViewComponent,
    StatisticsComponent,
    SearchPipe,
    AutocompleteComponent,
    ExcelParserComponent,
    NewDispatchTripComponent,
    MarketplaceComponent,
    MarketplaceUnassignedComponent,
    NewCompanyComponent,
    CompaniesComponent,
    ManifestComponent,
    MarketplaceStatComponent,
    AdjustmentModalComponent,
    CompletedTripsComponent,
    AdjustedTriplogsComponent,
    FailitiesComponent,
    NewFacilityComponent,
    FacilityTripsComponent,
    NewFacilityTripsComponent,
    FacilityTripsDetailsComponent,
    FacilityTripsPreviewComponent,
    FacilityTripsCountComponent,
    FacilityTripsLogsComponent,
    SignupRequestsComponent,
    FacilityReportsComponent,
    CompanyReportsComponent,
    MemberReportsComponent,
    NewStaffComponent,
    StaffComponent,
    FareComponent,
    NewFareComponent,
    OfferedFaresComponent,
    NewOfferedFareComponent,
    InviteCompaniesComponent,
    DashboardComponent,
    SortByPipe,
    OperationsComponent,
    ContractorsComponent,
    FileUploadComponent,
    CredentialsRequestComponent,
    CredentialsDetailsComponent,
    CredentialsDriversComponent,
    CredentialsVehiclesComponent,
    WrapCredentialsComponent,
    CopyToClipboardDirective,
    CompanyNoteModalComponent,
    TripAssignModalComponent,
    TripDetailModalComponent,
    CancelTripModalComponent,
    ProviderDashboardComponent,
    DriversComponent,
    FleetsComponent,
    AddFleetComponent,
    AddDriverComponent,
    AssignedTripsComponent,
    OfferedTripsComponent,
    AssignVehicleModalComponent,
    ThresholdComponent,
    StandingOrderComponent,
    NewStandingOrderComponent,
    AssignDriverModalComponent,
    NEMTStatisticsComponent,
    AlarmingTripsComponent,
    ApprovedMembersComponent,
    MemberDetailsComponent,
    MemberTripsComponent,
    NemtCodesListComponent,
    AddNemtCodeComponent,
    SortByDatePipe,
    NumberDirective
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    AgmOverlays,
    MomentModule,
    NgPipesModule,
    ReactiveFormsModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    AppRoutingModule,
    NgHttpLoaderModule.forRoot(),
    AgmJsMarkerClustererModule,
    NgxSelectModule,
    NgxSmartModalModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAhQKWoOEKCQWCuExvFPTGsnql9Y3-NFBA',
      libraries: ["places","geometry"]
    }),
    AgmDirectionModule,
    TimezonePickerModule,

    Ng2SearchPipeModule,
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-left'
    }),
    Ng2SearchPipeModule,
    NgMultiSelectDropDownModule.forRoot()

  ],
  providers: [GoogleMapsAPIWrapper],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { HomeComponent } from "./components/home/home.component";
import { LoginComponent } from "./components/login/login.component";
import { ProfileComponent } from "./components/profile/profile.component";
import { ResetPasswordComponent } from "./components/partials/reset-password/reset-password.component";
import { TripDetailsComponent } from "./components/trip-details/trip-details.component";
import { MapViewComponent } from "./components/map-view/map-view.component";
import { StatisticsComponent } from "./components/statistics/statistics.component";
import { NewDispatchTripComponent } from "./components/new-dispatch-trip/new-dispatch-trip.component";
import { ExcelParserComponent } from "./components/excel-parser/excel-parser.component";
import { MarketplaceComponent } from "./components/marketplace/marketplace.component";
import { MarketplaceUnassignedComponent } from "./components/marketplace-unassigned/marketplace-unassigned.component";
import { CompaniesComponent } from "./components/companies/companies/companies.component";
import { NewCompanyComponent } from "./components/companies/new-company/new-company.component";
import { ManifestComponent } from "./components/manifest/manifest.component";
import { MarketplaceStatComponent } from "./components/marketplace-stat/marketplace-stat.component";
import { AdjustedTriplogsComponent } from "./components/adjusted-triplogs/adjusted-triplogs.component";
import { CompletedTripsComponent } from "./components/completed-trips/completed-trips.component";
import { FailitiesComponent } from "./components/facilities/failities/failities.component";
import { NewFacilityComponent } from "./components/facilities/new-facility/new-facility.component";
import { FacilityTripsComponent } from "./components/facility-trips/facility-trips/facility-trips.component";
import { NewFacilityTripsComponent } from "./components/facility-trips/new-facility-trips/new-facility-trips.component";
import { FacilityTripsDetailsComponent } from "./components/facility-trips/facility-trips-details/facility-trips-details.component";
import { FacilityTripsCountComponent } from "./components/facility-trips/facility-trips-count/facility-trips-count.component";
import { FacilityTripsLogsComponent } from "./components/facility-trips/facility-trips-logs/facility-trips-logs.component";
import { SignupRequestsComponent } from "./components/signup-requests/signup-requests/signup-requests.component";
import { FacilityReportsComponent } from "./components/reports/facility-reports/facility-reports.component";
import { CompanyReportsComponent } from "./components/reports/company-reports/company-reports.component";
import { MemberReportsComponent } from "./components/reports/member-reports/member-reports.component";
import { from } from "rxjs";
import { AuthorizationGuard } from "./services/auth-guard/authorization-guard.service";
import { StaffComponent } from "./components/staff/staff/staff.component";
import { NewStaffComponent } from "./components/staff/new-staff/new-staff.component";
import { FareComponent } from "./components/signup-requests/fare/fare/fare.component";
import { NewFareComponent } from "./components/signup-requests/fare/new-fare/new-fare.component";
import { OfferedFaresComponent } from "./components/companies/offered-fares/offered-fares/offered-fares.component";
import { NewOfferedFareComponent } from "./components/companies/offered-fares/new-offered-fare/new-offered-fare.component";
import { InviteCompaniesComponent } from "./components/invite-companies/invite-companies/invite-companies.component";
import { DashboardComponent } from "./components/dashboard/dashboard.component";
import { OperationsComponent } from "./components/operations/operations.component";
import { ContractorsComponent } from "./components/contractors/contractors.component";
import { FacilityTripsPreviewComponent } from "./components/facility-trips/facility-trips-preview/facility-trips-preview.component";
import { CredentialsRequestComponent } from "./components/credentials/credentials-request/credentials-request.component";
import { CredentialsDetailsComponent } from "./components/credentials/credentials-details/credentials-details.component";
import { CredentialsDriversComponent } from "./components/credentials/credentials-drivers/credentials-drivers.component";
import { CredentialsVehiclesComponent } from "./components/credentials/credentials-vehicles/credentials-vehicles.component";
import { WrapCredentialsComponent } from "./components/credentials/wrap-credentials/wrap-credentials.component";
import { ProviderDashboardComponent } from "./components/provider-section/provider-dashboard/provider-dashboard.component";
import { DriversComponent } from "./components/provider-section/drivers/driver-list/drivers.component";
import { FleetsComponent } from "./components/provider-section/fleets/fleet-list/fleets.component";
import { AddFleetComponent } from "./components/provider-section/fleets/add-fleet/add-fleet.component";
import { AddDriverComponent } from "./components/provider-section/drivers/add-driver/add-driver.component";
import { AssignedTripsComponent } from "./components/provider-section/assigned-trips/assigned-trips/assigned-trips.component";
import { OfferedTripsComponent } from "./components/provider-section/offered-trips/offered-trips/offered-trips.component";
import { ThresholdComponent } from "./components/threshold/threshold/threshold.component";
import { StandingOrderComponent } from "./components/standing-order/standing-order/standing-order.component";
import { NEMTStatisticsComponent } from "./components/nemt-statistics/nemt-statistics.component";
import { AlarmingTripsComponent } from "./components/alarming-trips/alarming-trips.component";
import { MemberDetailsComponent } from "./components/members/member-details/member-details.component";
import { MemberTripsComponent } from "./components/members/member-trips/member-trips.component";
import { NemtCodesListComponent } from "./components/nemt-codes/nemt-codes-list/nemt-codes-list.component";
import { AddNemtCodeComponent } from "./components/nemt-codes/add-nemt-code/add-nemt-code.component";

const routes: Routes = [
  {
    path: "",
    component: HomeComponent,
    canActivate: [AuthorizationGuard],
    data: {
      role: ["admin", "authorizer", "dispatcher"],
    },
  },
  {
    path: "login",
    component: LoginComponent,
  },
  {
    path: "profile",
    component: ProfileComponent,
    canActivate: [AuthorizationGuard],
    data: {
      role: ["admin", "authorizer", "dispatcher"],
    },
  },
  {
    path: "reset/password",
    component: ResetPasswordComponent,
  },
  {
    path: ":tripId/details",
    component: TripDetailsComponent,
    canActivate: [AuthorizationGuard],
    data: {
      role: ["admin", "authorizer", "dispatcher"],
    },
  },
  {
    path: "map-view",
    component: MapViewComponent,
    canActivate: [AuthorizationGuard],
    data: {
      role: ["admin", "authorizer", "dispatcher"],
    },
  },
  {
    path: "statistics",
    component: StatisticsComponent,
    canActivate: [AuthorizationGuard],
    data: {
      role: ["admin", "authorizer"],
    },
  },
  {
    path: "nemt-statistics",
    component: NEMTStatisticsComponent,
    canActivate: [AuthorizationGuard],
    data: {
      role: ["admin", "authorizer","dispatcher"],
    },
  },
  {
    path: "dispatch/create",
    component: NewDispatchTripComponent,
    canActivate: [AuthorizationGuard],
    data: {
      role: ["admin", "authorizer"],
    },
  },
  {
    path: "dispatch/:assignId/edit",
    component: NewDispatchTripComponent,
    canActivate: [AuthorizationGuard],
    data: {
      role: ["admin", "authorizer"],
    },
  },
  {
    path: "parse/marketplace",
    component: ExcelParserComponent,
    canActivate: [AuthorizationGuard],
    data: {
      role: ["admin", "authorizer"],
    },
  },
  {
    path: "assign/marketplace",
    component: MarketplaceComponent,
    canActivate: [AuthorizationGuard],
    data: {
      role: ["admin", "authorizer"],
    },
  },
  {
    path: "marketplace/statistics",
    component: MarketplaceStatComponent,
    canActivate: [AuthorizationGuard],
    data: {
      role: ["admin", "authorizer"],
    },
  },
  {
    path: "marketplace/:manifest",
    component: ManifestComponent,
    canActivate: [AuthorizationGuard],
    data: {
      role: ["admin", "authorizer"],
    },
  },
  {
    path: "unassigned/marketplace",
    component: MarketplaceUnassignedComponent,
    canActivate: [AuthorizationGuard],
    data: {
      role: ["admin", "authorizer"],
    },
  },
  {
    path: "companies",
    component: CompaniesComponent,
    canActivate: [AuthorizationGuard],
    data: {
      role: ["admin", "authorizer"],
    },
  },
  {
    path: "companies/add",
    component: NewCompanyComponent,
    canActivate: [AuthorizationGuard],
    data: {
      role: ["admin", "authorizer"],
    },
  },
  {
    path: "companies/:status",
    component: CompaniesComponent,
    canActivate: [AuthorizationGuard],
    data: {
      role: ["admin", "authorizer"],
    },
  },
  {
    path: "companies/add/:companyId",
    component: NewCompanyComponent,
    canActivate: [AuthorizationGuard],
    data: {
      role: ["admin", "authorizer"],
    },
  },
  {
    path: "threshold",
    component: ThresholdComponent,
    canActivate: [AuthorizationGuard],
    data: {
      role: ["admin", "authorizer"],
    },
  },
  {
    path: "standing-order",
    component: StandingOrderComponent,
    canActivate: [AuthorizationGuard],
    data: {
      role: ["admin", "authorizer"],
    },
  },
  {
    path: "staff",
    component: StaffComponent,
    canActivate: [AuthorizationGuard],
    data: {
      role: ["admin", "authorizer"],
    },
  },
  {
    path: "staff/add",
    component: NewStaffComponent,
    canActivate: [AuthorizationGuard],
    data: {
      role: ["admin", "authorizer"],
    },
  },
  {
    path: "staff/add/:staffId",
    component: NewStaffComponent,
    canActivate: [AuthorizationGuard],
    data: {
      role: ["admin", "authorizer"],
    },
  },
  {
    path: "adjusted-trips",
    component: AdjustedTriplogsComponent,
    canActivate: [AuthorizationGuard],
    data: {
      role: ["admin", "authorizer"],
    },
  },
  {
    path: "completed-trips",
    component: CompletedTripsComponent,
    canActivate: [AuthorizationGuard],
    data: {
      role: ["admin", "authorizer"],
    },
  },
  {
    path: "members/:memberId/docs",
    component: MemberDetailsComponent,
    canActivate: [AuthorizationGuard],
    data: {
      role: ["admin", "authorizer"],
    },
  },
  {
    path: "members/:memberId/trips",
    component: MemberTripsComponent,
    canActivate: [AuthorizationGuard],
    data: {
      role: ["admin", "authorizer"],
    },
  },
  {
    path: "facilities",
    component: FailitiesComponent,
    canActivate: [AuthorizationGuard],
    data: {
      role: ["admin", "authorizer"],
    },
  },
  {
    path: "facilities/add",
    component: NewFacilityComponent,
    canActivate: [AuthorizationGuard],
    data: {
      role: ["admin", "authorizer"],
    },
  },
  {
    path: "facilities/add/:facilityId",
    component: NewFacilityComponent,
    canActivate: [AuthorizationGuard],
    data: {
      role: ["admin", "authorizer"],
    },
  },
  {
    path: "facility-trips",
    component: FacilityTripsComponent,
    canActivate: [AuthorizationGuard],
    data: {
      role: ["admin", "authorizer", "dispatcher"],
    },
  },
  {
    path: "facility-trips/outstanding",
    component: FacilityTripsComponent,
    canActivate: [AuthorizationGuard],
    data: {
      role: ["admin", "authorizer", "dispatcher"],
    },
  },
  {
    path: "facility-trips/authorization",
    component: FacilityTripsComponent,
    canActivate: [AuthorizationGuard],
    data: {
      role: ["admin", "authorizer"],
    },
  },
  {
    path: "facility-trips/accepted",
    component: FacilityTripsComponent,
    canActivate: [AuthorizationGuard],
    data: {
      role: ["admin", "authorizer", "dispatcher"],
    },
  },
  {
    path: "facility-trips/offered",
    component: FacilityTripsComponent,
    canActivate: [AuthorizationGuard],
    data: {
      role: ["admin", "authorizer", "dispatcher"],
    },
  },
  {
    path: "facility-trips/rejected",
    component: FacilityTripsComponent,
    canActivate: [AuthorizationGuard],
    data: {
      role: ["admin", "authorizer", "dispatcher"],
    },
  },
  {
    path: "facility-trips/inprogress",
    component: FacilityTripsComponent,
    canActivate: [AuthorizationGuard],
    data: {
      role: ["admin", "authorizer", "dispatcher"],
    },
  },
  {
    path: "facility-trips/finished",
    component: FacilityTripsComponent,
    canActivate: [AuthorizationGuard],
    data: {
      role: ["admin", "authorizer", "dispatcher"],
    },
  },
  {
    path: "facility-trips/cancelled",
    component: FacilityTripsComponent,
    canActivate: [AuthorizationGuard],
    data: {
      role: ["admin", "authorizer", "dispatcher"],
    },
  },
  {
    path: "facility-trips/add",
    component: NewFacilityTripsComponent,
    canActivate: [AuthorizationGuard],
    data: {
      role: ["admin", "authorizer", "dispatcher"],
    },
  },
  {
    path: "facility-trips/update",
    component: NewFacilityTripsComponent,
    canActivate: [AuthorizationGuard],
    data: {
      role: ["admin", "authorizer", "dispatcher"],
    },
  },
  {
    path: "facility-trips/:tripId/edit",
    component: NewFacilityTripsComponent,
    canActivate: [AuthorizationGuard],
    data: {
      role: ["admin", "authorizer", "dispatcher"],
    },
  },

  {
    path: "facility-trips/details/:tripId",
    component: FacilityTripsDetailsComponent,
    canActivate: [AuthorizationGuard],
    data: {
      role: ["admin", "authorizer", "dispatcher"],
    },
  },
  {
    path: "facility-trips/preview",
    component: FacilityTripsPreviewComponent,
    canActivate: [AuthorizationGuard],
    data: {
      role: ["admin", "authorizer", "dispatcher"],
    },
  },
  {
    path: "facility-trips/dashboard",
    component: FacilityTripsCountComponent,
    canActivate: [AuthorizationGuard],
    data: {
      role: ["admin", "authorizer", "dispatcher"],
    },
  },
  {
    path: "facility-trips/logs",
    component: FacilityTripsLogsComponent,
    canActivate: [AuthorizationGuard],
    data: {
      role: ["admin", "authorizer", "dispatcher"],
    },
  },
  {
    path: "provider/dashboard",
    component: ProviderDashboardComponent,
    canActivate: [AuthorizationGuard],
    data: {
      role: ["admin", "authorizer", "dispatcher"],
    },
  },
  {
    path: "provider/assigned-trips",
    component: AssignedTripsComponent,
    canActivate: [AuthorizationGuard],
    data: {
      role: ["admin", "authorizer", "dispatcher"],
    },
  },
  {
    path: "provider/offered-trips",
    component: OfferedTripsComponent,
    canActivate: [AuthorizationGuard],
    data: {
      role: ["admin", "authorizer", "dispatcher"],
    },
  },
  {
    path: "provider/drivers",
    component: DriversComponent,
    canActivate: [AuthorizationGuard],
    data: {
      role: ["admin", "authorizer", "dispatcher"],
    },
  },
  {
    path: "provider/add-driver",
    component: AddDriverComponent,
    canActivate: [AuthorizationGuard],
    data: {
      role: ["admin", "authorizer", "dispatcher"],
    }
  },
  {
    path: "provider/driver/edit/:driverId",
    component: AddDriverComponent,
    canActivate: [AuthorizationGuard],
    data: {
      role: ["admin", "authorizer", "dispatcher"],
    }
  },
  {
    path: "provider/fleets",
    component: FleetsComponent,
    canActivate: [AuthorizationGuard],
    data: {
      role: ["admin", "authorizer", "dispatcher"],
    },
  },
  {
    path: "provider/add-fleet",
    component: AddFleetComponent,
    canActivate: [AuthorizationGuard],
    data: {
      role: ["admin", "authorizer", "dispatcher"],
    },
  },
  {
    path: "provider/fleet/edit/:fleetId",
    component: AddFleetComponent,
    canActivate: [AuthorizationGuard],
    data: {
      role: ["admin", "authorizer", "dispatcher"],
    }
  },
  {
    path: "provider/fares",
    component: OfferedFaresComponent,
    canActivate: [AuthorizationGuard],
    data: {
      role: ["admin", "authorizer", "dispatcher"],
    },
  },
  {
    path: "provider/fares/:companyId/add",
    component: AddFleetComponent,
    canActivate: [AuthorizationGuard],
    data: {
      role: ["admin", "authorizer", "dispatcher"],
    },
  },
  {
    path: "provider/fares/:companyId/edit",
    component: AddFleetComponent,
    canActivate: [AuthorizationGuard],
    data: {
      role: ["admin", "authorizer", "dispatcher"],
    },
  },
  {
    path: "signup-requests",
    component: SignupRequestsComponent,
    canActivate: [AuthorizationGuard],
    data: {
      role: ["admin", "authorizer"],
    },
  },
  {
    path: "SignupRequets/active",
    component: SignupRequestsComponent,
    canActivate: [AuthorizationGuard],
    data: {
      role: ["admin", "authorizer"],
    },
  },
  {
    path: "SignupRequets/approved",
    component: SignupRequestsComponent,
    canActivate: [AuthorizationGuard],
    data: {
      role: ["admin", "authorizer"],
    },
  },
  {
    path: "reports/facility",
    component: FacilityReportsComponent,
    canActivate: [AuthorizationGuard],
    data: {
      role: ["admin", "authorizer"],
    },
  },
  {
    path: "reports/company",
    component: CompanyReportsComponent,
    canActivate: [AuthorizationGuard],
    data: {
      role: ["admin", "authorizer"],
    },
  },
  {
    path: "reports/member",
    component: MemberReportsComponent,
    canActivate: [AuthorizationGuard],
    data: {
      role: ["admin", "authorizer"],
    },
  },
  {
    path: "fare",
    component: FareComponent,
    canActivate: [AuthorizationGuard],
    data: {
      role: ["admin", "authorizer"],
    },
  },
  {
    path: "fare/add",
    component: NewFareComponent,
    canActivate: [AuthorizationGuard],
    data: {
      role: ["admin", "authorizer"],
    },
  },
  {
    path: "SignupRequests/:signuprequestId/fare",
    component: FareComponent,
    canActivate: [AuthorizationGuard],
    data: {
      role: ["admin", "authorizer"],
    },
  },
  {
    path: "SignupRequests/:signuprequestId/addfare",
    component: NewFareComponent,
    canActivate: [AuthorizationGuard],
    data: {
      role: ["admin", "authorizer"],
    },
  },
  {
    path: "SignupRequests/:signuprequestId/editfare/:index",
    component: NewFareComponent,
    canActivate: [AuthorizationGuard],
    data: {
      role: ["admin", "authorizer"],
    },
  },
  {
    path: "offeredfares/:signuprequestId/list",
    component: OfferedFaresComponent,
    canActivate: [AuthorizationGuard],
    data: {
      role: ["admin", "authorizer"],
    },
  },
  {
    path: "offeredfares/:signuprequestId/add",
    component: NewOfferedFareComponent,
    canActivate: [AuthorizationGuard],
    data: {
      role: ["admin", "authorizer"],
    },
  },
  {
    path: "offeredfares/:signuprequestId/edit",
    component: NewOfferedFareComponent,
    canActivate: [AuthorizationGuard],
    data: {
      role: ["admin", "authorizer"],
    },
  },
  {
    path: "invites",
    component: InviteCompaniesComponent,
    canActivate: [AuthorizationGuard],
    data: {
      role: ["admin", "authorizer"],
    },
  },
  {
    path: "dashboard",
    component: DashboardComponent,
    canActivate: [AuthorizationGuard],
    data: {
      role: ["admin", "authorizer"],
    },
  },
  {
    path: "operations",
    component: OperationsComponent,
    canActivate: [AuthorizationGuard],
    data: {
      role: ["admin", "authorizer", "dispatcher"],
    },
  },
  {
    path: "alarming-trips",
    component: AlarmingTripsComponent,
    canActivate: [AuthorizationGuard],
    data: {
      role: ["admin", "authorizer","dispatcher"],
    },
  },
  {
    path: "contractors",
    component: ContractorsComponent,
    canActivate: [AuthorizationGuard],
    data: {
      role: ["admin", "authorizer"],
    },
  },
  {
    path: "credentials/:id",
    component: WrapCredentialsComponent,
    canActivate: [AuthorizationGuard],
    data: {
      role: ["admin", "authorizer"],
    },
  },
  {
    path: "credentials",
    component: CredentialsRequestComponent,
    canActivate: [AuthorizationGuard],
    data: {
      role: ["admin", "authorizer"],
    },
  },
  {
    path: "nemt-codes",
    component: NemtCodesListComponent,
    canActivate: [AuthorizationGuard],
    data: {
      role: ["admin", "authorizer","dispatcher"],
    }
  },
  {
    path: "nemt-codes/add",
    component: AddNemtCodeComponent,
    canActivate: [AuthorizationGuard],
    data: {
      role: ["admin", "authorizer","dispatcher"],
    }
  },
  {
    path: "nemt-codes/:id/edit",
    component: AddNemtCodeComponent,
    canActivate: [AuthorizationGuard],
    data: {
      role: ["admin", "authorizer","dispatcher"],
    }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}

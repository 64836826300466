// import { Component, OnInit } from '@angular/core';
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';

import { SharedDataService } from 'src/app/services/shared/shared-data.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
//import { CompanyService } from 'src/app/services/company/company.service';
import { StaffService } from 'src/app/services/staff/staff.service';
import { ActivatedRoute, Router } from '@angular/router';
// import { copyStyles } from '@angular/animations/browser/src/util';
import { urls } from "src/app/utils/url-utils";
import { showHeading } from 'src/app/utils/global-constants';
import { appAlert } from 'src/app/utils/utils.common';

var baseURL = urls.SERVER_URL;

@Component({
  selector: 'app-new-staff',
  templateUrl: './new-staff.component.html',
  styleUrls: ['./new-staff.component.css']
})
export class NewStaffComponent implements OnInit {
  user: any;
  updateForm: FormGroup;
  submitted = false;
  url: any;
  urlImage: any = '';
  fileToUpload: any;
  mismatch: any;
  address: any;
  staffId: any;
  timezone: any;
  @ViewChild('fileInput', {static: true}) el: ElementRef;
  @ViewChild('password', {static: true}) pass: ElementRef;
  @ViewChild('cpassword', {static: true}) cpass: ElementRef;
  @ViewChild('address', {static: true}) inputAddress: any;
  showHeading = showHeading;
  selectedTimeZone = 'America/New_York';
  constructor(private formBuilder: FormBuilder,
    private sharedData: SharedDataService,
    private staffService: StaffService,
    private router: Router,
    private activatedRoute: ActivatedRoute) {
    this.staffId = this.activatedRoute.snapshot.paramMap.get('staffId');
    this.getUsers();
  }

  ngOnInit() {
    this.updateForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      address: ['', Validators.required],
      contactNumber: ['', Validators.required],
      displayName: ['', Validators.required],
      password: [''],
      latitude: [''],
      longitude: [''],
      cpassword: [''],
      timeZone: ['', Validators.required],
      platform: [''],
      roleType: ['', Validators.required],
      accountStatus: ['active', Validators.required]
    });

    if (this.staffId) {
      this.staffService.getStaffToEdit(this.staffId).subscribe((data: any) => {
        console.log(data);
        this.updateForm.patchValue(data);
        // this.timezone = data.timeZone;
        // this.updateForm.get('timeZone').setValue(data.timezone);
        this.url = data.profileImageURL;
        this.urlImage = this.url;
        this.address = data.address;
        this.fileToUpload = null;
        this.inputAddress.addresstext.nativeElement.value = data.address;
        this.selectedTimeZone = data.timeZone;
      });
    }
  }

  getUsers() {
    this.sharedData.getUser().subscribe(user => {
      this.user = user;
      // console.log('This current user logged in', this.user);
    });
  }

  createStaff(staff) {
    this.submitted = false;
    // delete this.updateForm.value.cpassword;
    if (!this.updateForm.value.password) {
      delete this.updateForm.value.password;
    }

    this.staffService.createNewStaff(JSON.parse(JSON.stringify(staff)), this.fileToUpload)
      .subscribe(
        (data: any) => {
          console.log(data);
          if (data.success === true) {
            appAlert({
              icon: 'success',
              title: 'Staff Created',
              text: 'Staff Created Successfully'
            });
            this.router.navigate(['/staff']);
          }
        },
        (error) => {
          appAlert({
            icon: 'error',
            title: 'Error',
            text: error
          });
        });
  }

  editStaff(staffId, staff) {
    this.submitted = false;
    if (!this.updateForm.value.password) {
      delete this.updateForm.value.password;
    }
    this.staffService.editStaff(staffId, staff, this.fileToUpload)
      .subscribe(
        (data: any) => {
          console.log(data);
          this.router.navigate(['/staff']);
        },
        (error) => {
          appAlert({
            icon: 'error',
            title: 'Error',
            text: error
          });
        });
  }

  fileUpload(event) {
    this.url = this.urlImage;
    this.fileToUpload = this.el.nativeElement.files[0];
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]); // read file as data url
      reader.onload = (event: any) => { // called once readAsDataURL is completed
        this.url = event.target.result;
      }
    }
  }

  get form() {
    return this.updateForm.controls;
  }

  onSubmit() {
    //debugger;
    console.log(this.updateForm.value);
    this.submitted = true;
    // this.updateForm.value.address = this.address;
    // this.updateForm.patchValue({ address: this.address});
    if (this.updateForm.invalid) {
      console.log('invalid form');
      console.log(this.updateForm.value)
      console.log(this.fileToUpload)

      return;
    }
    // ignore edit case for the moment
    if (!this.staffId && this.updateForm.value.password !== this.updateForm.value.cpassword) {
      console.log('password mismatch');
      this.mismatch = true;
      return;
    }

    // this check is for new staff
    if (!this.staffId && !this.fileToUpload) {
      return false;
    }

    this.updateForm.patchValue({ platform: this.user.platform });
    if (this.staffId) {
      this.editStaff(this.staffId, this.updateForm.value);
    } else {
      this.createStaff(this.updateForm.value);
    }
  }

  getAddress(place) {
    // this.address = place.formatted_address;
    // this.updateForm.get('latitude').setValue(place.geometry.location.lat());
    // this.updateForm.get('longitude').setValue(place.geometry.location.lng());

    if (place.formatted_address) {
      // this.updateForm.value.address = place.formatted_address;
      // this.address = place.formatted_address;
      // this.getGoogleTimeZoneByLocation();
      this.updateForm.patchValue({
        latitude: place.geometry.location.lat(),
        longitude: place.geometry.location.lng(),
        address: place.formatted_address
      });
    }
  }

  togglePassword() {
    this.pass.nativeElement.type === 'text' ? this.pass.nativeElement.type = 'password' : this.pass.nativeElement.type = 'text';
  }

  toggleCPassword() {
    this.cpass.nativeElement.type === 'text' ? this.cpass.nativeElement.type = 'password' : this.cpass.nativeElement.type = 'text';
  }

  changeTimezone(event: any) {
    this.updateForm.patchValue({ timeZone: event });
    console.log('Patched TimeZone: ', this.updateForm.value.timeZone);
    // this.timezone = event;
    // console.log(this.timezone);
    // this.updateForm.get('timezone').setValue(event);
  }

  getGoogleTimeZoneByLocation() {
    let params = {
      location: `${this.updateForm.value.latitude},${this.updateForm.value.longitude}`,
      timestamp: 1331161200,
      key: 'AIzaSyBNoxWXagcapR0tH-bxnJFPK28y_Oro2O0'
    };
    this.staffService.getGoogleTimeZone(params)
      .subscribe((data: any) => {
        console.log(data);
        if (data.status && data.status === 'OK') {
          console.log('Data --- > ', data);
          // this.updateForm.value.timezone = data.timeZoneId;
          this.updateForm.get('timezone').setValue(data.timeZoneId);
          this.timezone = data.timeZoneId;
        }
      });
  }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { showHeading } from 'src/app/utils/global-constants';
import { CredentialsService } from '../credentials-service/credentials.service';

@Component({
  selector: 'app-wrap-credentials',
  templateUrl: './wrap-credentials.component.html',
  styleUrls: ['./wrap-credentials.component.css']
})
export class WrapCredentialsComponent implements OnInit {
  selectedState = 1;
  companyId;
  data;

  constructor(
    private activatedRoute: ActivatedRoute,
    private credentialsService: CredentialsService
  ) { }

  ngOnInit() {
    if (this.activatedRoute.snapshot.url.length === 2) {
      this.companyId = this.activatedRoute.snapshot.url[1].path;
      this.getCompanyDetails(this.companyId);
    }
  }
  getCompanyDetails(id) {
    this.credentialsService.getCompaniesDetails(id).subscribe(data => {
      console.log(data);
      this.data = data;
    });
  }
  changeState(state) {
    this.selectedState = state;
  }
  showHeading() {
    return showHeading;
  }

}

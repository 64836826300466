import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CompanyService } from 'src/app/services/company/company.service';
import { OfferedFaresService } from 'src/app/services/offered-fares/offered-fares.service';
import { SignupRequestsService } from 'src/app/services/signup-requests/signup-requests.service';
import { showHeading } from 'src/app/utils/global-constants';

@Component({
  selector: 'app-offered-fares',
  templateUrl: './offered-fares.component.html',
  styleUrls: ['./offered-fares.component.css']
})
export class OfferedFaresComponent implements OnInit {
  searchVehicle: any;
  approveStatus: boolean;
  isApproveButton = false;
  signuprequestId: string;
  companyFares: any = [];
  vehicleTypes = ['Standard', 'Premium', 'SUV', 'WAV', 'Stretcher'];
  showHeading = showHeading;
  companies;
  constructor(private companyService: CompanyService,
    private offeredFaresService: OfferedFaresService,
    private signupRequestsService: SignupRequestsService, 
    private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.signuprequestId = params['signuprequestId'];
      if(this.signuprequestId) {
        this.getCompanyFares(this.signuprequestId);
      } else {
        
      }
    });

    this.companyService.getAllCompanies()
      .subscribe((data) => {
        this.companies = data;
        console.log('Companies: ', this.companies);
      });
  }

  getCompanyFares(signuprequestId) {
    this.offeredFaresService.getOfferedFaresList(signuprequestId).subscribe(data => {
      console.log('offered', data);
      this.companyFares = data;
      // this.approveStatus = data.isApproved;
      // console.log('Signup Request Fare List: ', data);
      // const currentVehicles = this.companyFares.map(c => c.vehicleType);
      // this.isApproveButton = this.checkBothArray(currentVehicles, this.vehicleTypes)
    });
  }

  approveFares() {
    this.signupRequestsService.approveSignupRequest(this.signuprequestId)
      .subscribe(data => {
        if (data.success) {
          this.router.navigate(['/SignupRequests/approved']);
        }
      })
  }

  onCompanyChange(evt) {
    // console.log('CompanyId', evt.target.value);
    this.getCompanyFares(evt.target.value);
  }

}

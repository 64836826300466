import { Component, OnInit } from '@angular/core';
import { NemtCodesService } from 'src/app/services/nemt-codes/nemt-codes.service';
import { SharedDataService } from 'src/app/services/shared/shared-data.service';
import { showHeading } from 'src/app/utils/global-constants';

@Component({
  selector: 'app-nemt-codes-list',
  templateUrl: './nemt-codes-list.component.html',
  styleUrls: ['./nemt-codes-list.component.css']
})
export class NemtCodesListComponent implements OnInit {
  list: any;
  search;
  showHeading = showHeading;
  timeZone = 'America/New_York';
  user;
  
  constructor(
    private nemtCodesService: NemtCodesService,
    private sharedData: SharedDataService
  ) { }

  ngOnInit() {
    this.getUser();
    this.nemtCodesService
      .getNemtCodes()
      .subscribe((data: any) => {
        if (data) {
          this.list = data;
        }
      });
  }

  getUser() {
    this.sharedData.getUser()
      .subscribe(user => {
        if (user && user.timeZone) {
          this.user = user;
          this.timeZone = user.timeZone;
        }
    });
  }
}

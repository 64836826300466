import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { CompanyService } from "src/app/services/company/company.service";
import { FareService } from "src/app/services/fare/fare.service";
import { SharedDataService } from "src/app/services/shared/shared-data.service";
import { SignupRequestsService } from "src/app/services/signup-requests/signup-requests.service";
import { showHeading } from "src/app/utils/global-constants";
import { appAlert } from "src/app/utils/utils.common";

@Component({
  selector: "app-fare",
  templateUrl: "./fare.component.html",
  styleUrls: ["./fare.component.css"],
})
export class FareComponent implements OnInit {
  searchVehicle: any;
  approveStatus: boolean;
  isApproveButton = false;
  signuprequestId: string;
  companyFares: any = [];
  vehicleTypes = [];
  vehicles;
  showHeading = showHeading;
  constructor(
    private companyService: CompanyService,
    private fareService: FareService,
    private signupRequestsService: SignupRequestsService,
    private route: ActivatedRoute,
    private router: Router,
    private sharedService: SharedDataService
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.signuprequestId = params["signuprequestId"];
    });

    this.fareService
      .getSignupRequestFareList(this.signuprequestId)
      .subscribe((data) => {
        this.vehicleTypes = data.fleets.map((f) => f.driverCar);
        if (this.vehicleTypes.length) {
          const set = new Set(this.vehicleTypes);
          //this.vehicles = this.vehicleTypes.join();
          this.vehicles = Array.from(set).join();
          // console.log('join',this.vehicleTypes.join());
          console.log("vehicles", this.vehicles);
        }
        this.companyFares = data.companyFares;
        this.approveStatus = data.isApproved;
        console.log("Signup Request Fare List: ", data);
        const currentVehicles = this.companyFares.map((c) => c.vehicleType);
        this.isApproveButton = this.checkBothArray(
          Array.from(new Set(currentVehicles)),
          Array.from(new Set(this.vehicleTypes))
        );
      });
  }

  checkBothArray(a, b) {
    console.log("Entered Vehicles ", a);
    console.log("Vehicle Types ", b);
    // if (a.length !== b.length) {
    //   return false;
    // }

    if (a.length < b.length) {
      return false;
    }

    console.log(
      "Filter Enter Vehicles ",
      a.filter((v) => b.includes(v))
    );
    return b.length === a.filter((v) => b.includes(v)).length;
    //return a.length === a.filter(v => b.includes(v)).length;
  }

  approveFares() {
    this.signupRequestsService
      .approveSignupRequest(this.signuprequestId)
      .subscribe((data) => {
        if (data.success) {
          // update sidebar signup request counters
          this.sharedService.addSignupRequests({ success: true });
          appAlert({
            icon: "success",
            title: "Company Approved",
            text: "Company Approved Successfully",
          });
          this.router.navigate(["/SignupRequets/approved"]);
        }
      });
  }
}

import { AfterViewInit, Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FareService } from 'src/app/services/fare/fare.service';
import { showHeading } from 'src/app/utils/global-constants';
import { appAlert } from 'src/app/utils/utils.common';
import { fares } from './prefill-data';

@Component({
  selector: 'app-new-fare',
  templateUrl: './new-fare.component.html',
  styleUrls: ['./new-fare.component.css']
})
export class NewFareComponent implements OnInit {
  signuprequestId: string;
  platform: string;
  companyFares: any;
  index: number;
  vehicleTypes = ['Standard', 'Premium', 'SUV', 'WAV', 'Stretcher'];
  isEditingFare: boolean = false;
  isEditingZone: boolean = false;
  zoneIndex: number = -1;

  fareForm: FormGroup;
  public zoneFares: FormArray;
  fareFormSubmitted: boolean = false;
  public newZoneFare = {
    fare: null,
    from: null,
    to: null
  }

  zoneFareSubmitted: boolean = false;
  showHeading = showHeading;
  constructor(private fb: FormBuilder,
    private fareService: FareService,
    private activatedRoute: ActivatedRoute,
    private router: Router) { }

  ngOnInit() {
    this.fareForm = this.fb.group({
      _id: [''],
      vehicleType: ['Standard', Validators.required],
      pickupSurcharge: this.fb.group({
        fare: [null, Validators.required],
        per: [null, Validators.required],
        after: [null, Validators.required]
      }),
      baseFare: this.fb.group({
        fare: [null, Validators.required],
        for: [null, Validators.required]
      }),
      zones: this.fb.array([]),
      perMile: [null, Validators.required],
      minimumFare: [null, Validators.required],
      cancelCharges: [null, Validators.required],
      perMin: [null, Validators.required],
      waitTime: this.fb.group({
        fare: [null, Validators.required],
        per: [null, Validators.required],
        after: [null, Validators.required]
      }),
      additionalPassengerCharges: [null, Validators.required]
    });

    this.zoneFares = this.fareForm.get("zones") as FormArray;

    this.signuprequestId = this.activatedRoute.snapshot.paramMap.get('signuprequestId');
    this.index = +this.activatedRoute.snapshot.paramMap.get('index');
    if (this.router.url.indexOf('editfare') > -1) {
      this.isEditingFare = true;
    }
    else {
      this.fareForm.patchValue(fares);
    }

    this.fareService.getSignupRequestFareList(this.signuprequestId).subscribe(data => {
      console.log(data);
      this.companyFares = data.companyFares;
      console.log(this.companyFares);
      if (this.isEditingFare) {
        if (this.index > -1) {
          this.zoneFares = this.fareForm.get("zones") as FormArray;
          this.fareForm.patchValue(data.companyFares[this.index]);
          const zones = data.companyFares[this.index].zones;
          if (zones.length > 0) {
            zones.forEach(zoneFare => {
              this.zoneFares.push(this.fb.group({ ...zoneFare }));
            });
          }
        }
      }
    });
  }

  validateNumber(event) {
    const keyCode = event.keyCode;
    const excludedKeys = [8, 37, 39, 46, 190];
    if ( !((keyCode >= 48 && keyCode <= 57) || (keyCode >= 96 && keyCode <= 105) || (excludedKeys.includes(keyCode))) ) {
      event.preventDefault();
    }
  }

  setVehicleType(type) {
    this.fareForm.patchValue({
      vehicleType: type,
    });
  }

  addVehicleType(vehicleType) {
    const getFare = this.companyFares.find(fare => {
      return fare.vehicleType === vehicleType;
    });

    console.log('getFare', getFare);
    if (getFare) {
      //  console.log(typeof this.fareForm.controls._id.value)
      if (getFare._id === this.fareForm.controls._id.value) {
        console.log('Same Vehicle exists edit');
      } else {
        console.log('Vehicle already exists');
        appAlert({
          icon: 'info',
          title: '',
          text: 'Vehicle fare already exists.'
        });
        return false;
      }
    }

    // this.fareFormSubmitted = false;
    // this.fareForm.reset();
    // if (this.zoneFares !== undefined && this.zoneFares !== null) {
    //   while (this.zoneFares.length !== 0) {
    //     this.zoneFares.removeAt(0)
    //   }
    // }
    this.fareForm.patchValue({
      vehicleType: vehicleType,
    });
    return true;
  }

  getVehicleType(type) {
    return type === this.fareForm.controls.vehicleType.value;
  }

  addZone() {
    this.zoneFareSubmitted = true;
    this.zoneFares = this.fareForm.get("zones") as FormArray;
    //const newZoneFare = this.fareForm.controls['newZone'].value;
    const t = this.newZoneFare;
    const newZoneFare = this.newZoneFare;
    let isNewZoneValid = Object.values(newZoneFare);
    if (isNewZoneValid.includes(null) || isNewZoneValid.includes("")) {
      console.log('Please provide valid value')
      return;
    }
    console.log(newZoneFare, Object.values(newZoneFare));

    this.zoneFares.push(this.fb.group({ ...newZoneFare }));
    console.log(this.zoneFares);
    this.newZoneFare.fare = null;
    this.newZoneFare.from = null;
    this.newZoneFare.to = null;
    this.zoneFareSubmitted = false;
    // let newZone = this.fb.group({
    //   fare: [newZoneFare.fare, Validators.required],
    //   from: [newZoneFare.from, Validators.required],
    //   to: [newZoneFare.to, Validators.required],
    // });
  }

  editZone(index) {
    this.zoneIndex = index;
    this.newZoneFare = { ...this.zoneFares.at(index).value };
    this.isEditingZone = true;
  }

  saveZone() {
    //debugger;
    this.zoneFareSubmitted = true;
    const newZoneFare = this.newZoneFare;
    let isNewZoneValid = Object.values(newZoneFare);
    this.isEditingZone = true;
    if (isNewZoneValid.includes(null) || isNewZoneValid.includes("")) {
      console.log('Please provide valid value')
      return;
    }
    this.zoneFares.at(this.zoneIndex).patchValue(this.newZoneFare);
    this.clearZone();
    this.zoneFareSubmitted = false;
  }

  clearZone() {
    this.zoneIndex = -1;
    this.newZoneFare = { fare: null, from: null, to: null };
    this.isEditingZone = false;
  }

  deleteZone(index) {
    this.zoneFares.removeAt(index);
  }

  get zones(): FormArray {
    return this.fareForm.get('zones') as FormArray;
  }

  saveFareForm() {
    //this.fareForm.get('newZone.fare').clearValidators();
    //this.fareForm.get('newZone.from').clearValidators();
    //this.fareForm.get('newZone.to').clearValidators();
    //this.fareForm.updateValueAndValidity();

    console.log(this.fareForm.value);
    console.log(this.fareForm.errors);
    this.fareFormSubmitted = true;
    const v = this.fareForm.invalid;
    console.log(this.fareForm);

    if (this.fareForm.invalid) {
      console.log('form invalid');
      return false;
    }

    const fare = this.fareForm.value;
    //delete fare.newZone;
    delete fare._id;

    if (this.isEditingFare) {
      fare.index = this.index;
    }
    if (!this.addVehicleType(this.fareForm.controls.vehicleType.value)) {
      return false;
    }

    console.log(fare);
    this.fareService.createSignupRequestFare(this.signuprequestId, fare)
      .subscribe((data: any) => {
        console.log('fare created: ', data);
        this.router.navigate(['/SignupRequests/' + this.signuprequestId + '/fare']);
        // if (data.success === true) {
        //   swal({
        //     icon: 'success',
        //     title: 'Staff Created',
        //     text: 'Staff Created Successfully'
        //   });
        //   this.router.navigate(['/staff']);
        // }
      });

    //this.fareForm.reset();
  }

  getFare(vehicleType) {
    this.fareService.getFareToEdit(vehicleType).subscribe((data: any) => {
      console.log(data);
    });
  }

  get f(): any {
    return this.fareForm.controls;
  }

  prefillData() {
    this.fareForm.patchValue(fares);
  }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CompanyService } from 'src/app/services/company/company.service';
import { SharedDataService } from 'src/app/services/shared/shared-data.service';
import { SignupRequestsService } from 'src/app/services/signup-requests/signup-requests.service';
import { showHeading } from 'src/app/utils/global-constants';
import { getOnboardUrl } from 'src/app/utils/utils.common';
import { environment } from 'src/environments/environment';
import { CredentialsService } from '../credentials-service/credentials.service';

@Component({
  selector: 'app-credentials-request',
  templateUrl: './credentials-request.component.html',
  styleUrls: ['./credentials-request.component.css']
})
export class CredentialsRequestComponent implements OnInit {

  status;
  search;
  showHeading = showHeading;
  data;
  canView = false;
  viewAccess = false;
  userPlatform: any = 'nemt';

  constructor(
    private companyService: CompanyService,
    private activatedRoute: ActivatedRoute,
    private sharedData: SharedDataService,
    private credentialsService: CredentialsService
    ) {
      document.addEventListener('keyup', e => {
        console.log(e);
        // e.ctrlKey &&
        if (e.altKey && (e.key.toLowerCase() === 'v' || e.key.toLowerCase() === '√') && this.viewAccess) {
          this.canView = !this.canView;
        }
      });
    }

  ngOnInit() {
    this.sharedData.getUser().subscribe(data => {
      this.userPlatform = data.platform;
      if (data.roleType === 'admin') {
        this.viewAccess = true;
      }
    });
    this.credentialsService.getAllCompanies().subscribe(data => {
      console.log(data);
      this.data = data;
    });

  }

  openOnboardPanel(companyId) {
    this.companyService.getCompanyToken(companyId)
      .subscribe((data: any) => {
        console.log(data.companyToken);
        if(environment.production) {
          window.open(`${environment.ALIVI_ONBOARD_URL}dashboard?auth_token=${data.companyToken}`, '_blank');
        }
        else {
          window.open(`${environment.ALIVI_ONBOARD_URL}dashboard?auth_token=${data.companyToken}`, '_blank');
        }

      });
  }

}



import { Component, OnInit } from '@angular/core';
import { showHeading } from 'src/app/utils/global-constants';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  signupRequests = ['A', 'B', 'C'];;
  companies = ['A', 'B', 'C'];
  showHeading = showHeading;
  constructor() { }

  ngOnInit() {
  }

}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { CompanyService } from 'src/app/services/company/company.service';
import { DriverService } from 'src/app/services/driver/driver.service';
import { SharedDataService } from 'src/app/services/shared/shared-data.service';
import { imageFileProcessing } from 'src/app/utils/image-processing-utils';
import { appAlert } from 'src/app/utils/utils.common';

@Component({
  selector: 'app-add-driver',
  templateUrl: './add-driver.component.html',
  styleUrls: ['./add-driver.component.css']
})
export class AddDriverComponent implements OnInit {
  driverForm: FormGroup;
  submitted: boolean = false;
  driverId: string = '';
  companies;
  companyId = '';
  isEdit = false;

  constructor(
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private driverService: DriverService,
    private sharedService: SharedDataService,
    private companyService: CompanyService
  ) { }

  ngOnInit() {
    this.driverId = this.activatedRoute.snapshot.paramMap.get('driverId');

    if (this.driverId) {
      this.isEdit = true;
      this.getDriverDetails();
    }

    this.companyService.getAllCompanies()
      .subscribe((data) => {
        this.companies = data;
      });

    this.driverForm = this.fb.group({
      legalName: ['', Validators.required],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      contactNumber: ['', Validators.required],
      address: ['', Validators.required],
      
      accountStatus: ['', Validators.required],
      email: ['', Validators.required],
      password: ['', Validators.required],
      driverLicenseNumber: ['', Validators.required],
      profileRole: ['', Validators.required],

      profileImageURL: ['', Validators.required],
      driverLicenseImage: ['', Validators.required],
      licenseRegistrationExpiry: ['', Validators.required],

      preLicenseRegistrationExpiry: [''],
    });
  }

  getDriverDetails() {
    this.driverService.getDriverDetails(this.driverId)
    .subscribe((data) => {
      console.log('Patching Data', data);
      if (data) {
        this.patchData(data);
      }
      console.log(this.driverForm);
    });
  }

  patchData(data) {
    this.driverForm.patchValue({
      ...data,
      preLicenseRegistrationExpiry: data.licenseRegistrationExpiry
    })
  }

  onSubmit() {
    this.submitted = true;
    console.log('FORM: ', this.driverForm);

    if (this.driverForm.invalid) {
      console.log('INVALID: ', this.driverForm);
      return;
    }

    appAlert({
      title: "Alert!",
      text: "Are you sure you want to proceed?",
      icon: "warning",
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      showCancelButton: true
    }).then((result) => {
      if (result.isConfirmed) {
        this.driverService.saveDriver(this.driverForm.value, this.driverId)
          .subscribe((data: any) => {
            if (data) {
              if (this.driverId) {
                this.toastr.success('Driver updated successfully.', 'Alert');
              } else {
                this.toastr.success('Driver added successfully.', 'Alert');
              }
              this.router.navigate(['/provider/drivers']);
            }
          });
      }
    });
  }

  onCompanyChange(evt) {
    this.companyId = evt.target.value;
  }

  fileProcessing(imageInput, form, imageFile) {
    form[imageFile].setValue(null);
    imageFileProcessing(imageInput).subscribe((result) => {
      console.log('Uploaded Image: ', result);
      form[imageFile].setValue(result);
    });
  }

  onDateChange(event, control) {
    console.log(moment(event).toISOString());
    if (event) {
      control.setValue(moment(event).toISOString());
    }
  }

  getImageUrl(formControl) {
    if (typeof formControl.value === "string") {
      // If it contains URL
      return formControl.value
    } else if (typeof formControl.value === "object") {
      // When we upload image
      if(formControl.value) {
        return formControl.value.src;
      } else {
        return formControl.value;
      }
    }
  }

  show() {
    let show = false;
    
    if(this.isEdit) {
      show = true;
    } else {
      if(this.companyId) {
        show = true;
      }
    }

    return show;
  }

  get form() {
    return this.driverForm.controls;
  }

}

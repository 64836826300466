import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { errorHandl } from 'src/app/utils/network-utils';
import { urls } from 'src/app/utils/url-utils';

@Injectable({
  providedIn: 'root'
})
export class NemtCodesService {
  url = urls.BASE_URL;
  constructor(private http: HttpClient) { }

  getNemtCodes() {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("token")
    });
    let options = { headers: authHeader };
    // console.log(search)
    return this.http.get(this.url + "nemtcodes", options);
  }

  getNemtCodeById(codeId) {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("token")
    });
    let options = { headers: authHeader };
    return this.http.get(this.url + "nemtcodes/" + codeId, options);
  }

  saveNemtCode(codeId, payload) {
    let authHeader = new HttpHeaders({
      authorization: "JWT " + localStorage.getItem("token")
    });
    let options = { headers: authHeader };
    
    if (codeId) {
      return this.http.put(this.url + 'nemtcodes/' + codeId, payload, options)
      .pipe(catchError(errorHandl));
    } else {
      return this.http.post(this.url + 'nemtcodes', payload, options)
      .pipe(catchError(errorHandl));
    } 
  }
}
